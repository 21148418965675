import styled from '@/styles';
import { DateTimePicker } from '@mui/x-date-pickers';

export const StyledDateTimePicker = styled(DateTimePicker, {
  borderColor: '$colors$neutralLight !important',
  borderWidth: '1px !important',
  borderRadius: '$lg !important',
  variants: {
    fix: {
      true: {
        width: '130px',
      },
      false: {
        width: '100%',
      },
    },
  },
  '@phoneOnly': {
    '& > div': {
      borderRadius: '10px !important',
    },
  },
  '& input': {
    padding: '4px 12px',
    fontSize: '$md !important',
    lineHeight: '32px !important',
    height: 'auto',
  },
  '.MuiPickersDay-root.Mui-selected': {
    backgroundColor: 'red',
    color: 'red',
  },

  '.Mui-selected:focus': {
    backgroundColor: 'red',
  },

  '.MuiPickersDay-root:hover': {
    backgroundColor: 'red',
  },

  '.MuiClockPointer-root': {
    backgroundColor: 'red',
  },

  '.MuiClockNumber-root.Mui-selected': {
    backgroundColor: 'red',
    color: 'red',
  },
});

export const Label = styled('label', {
  display: 'block',
  color: '$textLight',
  fontSize: '$sm',
  fontWeight: '$medium',
  marginBottom: '0.4rem',
  variants: {
    fontSize: {
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
    },
    displayType: {
      row: {
        marginRight: '10px',
        whiteSpace: 'nowrap',
        color: '#7A7A7A',
        '@phoneOnly': {
          fontSize: '14px',
          fontWeight: '400',
        },
      },
      rowSpace: {
        marginRight: '10px',
        whiteSpace: 'nowrap',
      },
      block: {
        marginRight: '0px',
        '@phoneOnly': {
          fontSize: '13px',
          color: '#000',
          fontWeight: '400',
        },
      },
      marginBottomNull: {
        marginBottom: '0',
      },
    },
  },
  defaultVariants: {
    fontSize: 'sm',
    displayType: 'block',
  },
});
