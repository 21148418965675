import {
  PickerChangeHandlerContext,
  TimeStepOptions,
  TimeValidationError,
} from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { ROUND_OFF_MINUTES } from '@/constants/general';
import styled from '@/styles';
import { theme } from '@/styles/colors';
import './index.css';
import { Label, StyledDateTimePicker, StyledTimePicker } from './styles';

type PropTypes = {
  onChange?: (
    value: Dayjs,
    context: PickerChangeHandlerContext<TimeValidationError>,
    name: string,
  ) => void;
  minutesStep?: number;
  name: string;
  defaultValue?: Dayjs;
  label?: string;
  id?: string;
  timeSteps?: TimeStepOptions;
  inputStyle?: Record<string, any>;
  hideClock?: boolean;
  fix?: boolean;
  fieldId?: string;
};

const NotificationDateTimePicker = ({
  label,
  onChange,
  minutesStep,
  name,
  defaultValue,
  id,
  timeSteps,
  fieldId,
  inputStyle = {},
  hideClock = false,
  fix = false,
}: PropTypes) => {
  if (!dayjs(defaultValue).isValid()) {
    defaultValue = undefined;
  }
  const [time, setTime] = useState<Dayjs | undefined>(defaultValue);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>(label ?? '');
  const handleTimeChange = (newValue, e) => {
    if (e.validationError !== 'invalidDate') {
      let value = newValue;
      if (e.validationError === 'minutesStep') {
        if (value.minute() % ROUND_OFF_MINUTES !== 0) {
          // Round the value to the nearest multiple of 15 minutes
          const roundedMinutes =
            Math.round(value.minute() / ROUND_OFF_MINUTES) * ROUND_OFF_MINUTES;
          value = value.set('minute', roundedMinutes);
        }
        if (value.minute() >= 60) {
          value.set('minute', 0);
        }
      }
      setTime(value);

      if (onChange) {
        onChange(value, e, name);
      }
    } else {
      console.error(e.validationError);
    }
  };

  return (
    <div>
      {label && <Label htmlFor={id}>{label}</Label>}
      <StyledDateTimePicker
        desktopModeMediaQuery="@media (min-width: 768px)"
        fix={fix}
        minutesStep={minutesStep}
        open={pickerOpen}
        id={fieldId}
        onOpen={() => setPickerOpen(true)}
        slotProps={{
          popper: { disablePortal: true },
          actionBar: { actions: [] },
          textField: {
            placeholder: `${placeholder}`,
            onClick: () => {
              setPickerOpen(true);
              setPlaceholder('hh:mm aa');
            },
            id,
          },
          mobilePaper: {
            sx: {
              '& .MuiClockPointer-root, .MuiClock-pin, .MuiClockNumber-root.Mui-selected':
                {
                  backgroundColor: '#45A735',
                },
              '& .MuiClockPointer-thumb': {
                backgroundColor: theme.themeDefault,
                borderColor: theme.themeDefault,
              },
              '& .MuiPickersToolbarText-root.Mui-selected': {
                color: '#45A735',
              },
            },
          },
        }}
        sx={{
          ...(hideClock
            ? { '& .MuiInputAdornment-positionEnd': { display: 'none' } }
            : {}),
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: theme.themeDark,
            },
            ...inputStyle,
          },
          '& .MuiFormLabel-root': {
            '&.MuiInputLabel-root': {
              top: '-6px',
            },
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiInputBase-input::-moz-placeholder': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiInputBase-input::-ms-placeholder': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiInputBase-input::-webkit-input-placeholder': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiInputBase-input': {
            color: time ? 'rgba(0, 0, 0, 1.0)' : 'rgba(0, 0, 0, 0.2)',
          },
          marginBottom: '1.25rem',
        }}
        timeSteps={timeSteps || { hours: 1, minutes: 15, seconds: 1 }}
        value={time}
        onChange={handleTimeChange}
        onClose={() => {
          setPickerOpen(false);
          setPlaceholder(label ?? '');
        }}
      />
    </div>
  );
};

export default NotificationDateTimePicker;
