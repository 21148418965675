export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date or Empty/Nil string is allowed */
  DateOrEmptyString: { input: any; output: any };
  /** A properly formatted email address */
  Email: { input: string; output: string };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  /** Represents untyped JSON */
  JSON: {
    input: object | object[] | string | number | boolean | null;
    output: object | object[] | string | number | boolean | null;
  };
  /** An object's URL key */
  Key: { input: string; output: string };
  /** Non-fractional, signed, whole numeric values representing 1 meter in the range -(2^31) and 2^31 - 1. */
  Meters: { input: any; output: any };
  /** Non-fractional, signed, whole numeric values representing 1 minute of time in the range -(2^31) and 2^31 - 1. */
  Minutes: { input: any; output: any };
  /** A Float with a value >= 0 */
  NonNegativeFloat: { input: any; output: any };
  /** An Int with a value >= 0 */
  NonNegativeInt: { input: number; output: number };
  /** An Int with a value > 0 */
  PositiveInt: { input: number; output: number };
  /** A rating between 0 and 5 */
  Rating: { input: any; output: any };
  /** A valid IANA timezone name */
  Timezone: { input: string; output: string };
  /** A nil value */
  Void: { input: void; output: void };
};

/** Account */
export type Account = {
  __typename: 'Account';
  active: Scalars['Boolean']['output'];
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  addresses: Array<Address>;
  billingWeek: Maybe<BillingWeekEnum>;
  canDisable: Maybe<Scalars['Boolean']['output']>;
  city: Maybe<Scalars['String']['output']>;
  comments: Array<Comment>;
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentPaymentMethod: Maybe<Scalars['String']['output']>;
  customer: Customer;
  cutOff: Maybe<InvoiceCutOffEnum>;
  default: Scalars['Boolean']['output'];
  defaultContact: CustomerAdmin;
  groupBy: Maybe<InvoiceGroupByEnum>;
  id: Scalars['ID']['output'];
  markupPercent: Maybe<Scalars['Float']['output']>;
  monthlyCutOff: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orders: OrderPage;
  paymentMethodInfoType: Maybe<PaymentMethodInfo>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  rateType: AccountRateTypeEnum;
  rates: Maybe<Array<Rate>>;
  region: Maybe<Region>;
  state: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip: Maybe<Scalars['String']['output']>;
};

/** Account */
export type AccountOrdersArgs = {
  filters: InputMaybe<OrderFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Autogenerated return type of AccountAttachBankAccount */
export type AccountAttachBankAccountPayload = {
  __typename: 'AccountAttachBankAccountPayload';
  attach: Scalars['Boolean']['output'];
  bankAccount: Maybe<StripeBankAccount>;
};

/** Autogenerated return type of AccountAttachCard */
export type AccountAttachCardPayload = {
  __typename: 'AccountAttachCardPayload';
  attach: Scalars['Boolean']['output'];
};

/** Autogenerated return type of AccountCreate */
export type AccountCreatePayload = {
  __typename: 'AccountCreatePayload';
  account: Account;
};

/** Autogenerated return type of AccountCutOffUpdate */
export type AccountCutOffUpdatePayload = {
  __typename: 'AccountCutOffUpdatePayload';
  account: Account;
};

/** Autogenerated return type of AccountDetachPaymentMethod */
export type AccountDetachPaymentMethodPayload = {
  __typename: 'AccountDetachPaymentMethodPayload';
  detach: Scalars['Boolean']['output'];
};

/** Available filter operations for a Account */
export type AccountFilterSetInput = {
  active?: InputMaybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  customers?: InputMaybe<IdMembershipFilterInput>;
  defaultContacts?: InputMaybe<IdMembershipFilterInput>;
  name?: InputMaybe<TextSearchFilterInput>;
  phoneNumber?: InputMaybe<TextSearchFilterInput>;
  rateType?: InputMaybe<Array<AccountRateTypeEnum>>;
};

export enum AccountRateTypeEnum {
  MARKUP = 'MARKUP',
  STATIC = 'STATIC',
}

/** Autogenerated return type of AccountSetCurrentPaymentMethod */
export type AccountSetCurrentPaymentMethodPayload = {
  __typename: 'AccountSetCurrentPaymentMethodPayload';
  transaction: Scalars['Boolean']['output'];
};

/** Autogenerated return type of AccountUpdateActive */
export type AccountUpdateActivePayload = {
  __typename: 'AccountUpdateActivePayload';
  account: Account;
};

/** Autogenerated return type of AccountUpdate */
export type AccountUpdatePayload = {
  __typename: 'AccountUpdatePayload';
  account: Account;
};

/** Active Users By Skill */
export type ActiveUsersBySkillReport = {
  __typename: 'ActiveUsersBySkillReport';
  city: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  jobsWorked: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  skill: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

/** Address */
export type Address = {
  __typename: 'Address';
  account: Maybe<Account>;
  active: Maybe<Scalars['Boolean']['output']>;
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  coords: Point;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  jobsCount: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  rates: Array<Rate>;
  region: Region;
  state: Maybe<Scalars['String']['output']>;
  timezone: Scalars['Timezone']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of AddressCreate */
export type AddressCreatePayload = {
  __typename: 'AddressCreatePayload';
  address: Address;
};

/** Autogenerated return type of AddressDelete */
export type AddressDeletePayload = {
  __typename: 'AddressDeletePayload';
  success: Scalars['Boolean']['output'];
};

/** Pagination container for Address */
export type AddressPage = {
  __typename: 'AddressPage';
  items: Array<Address>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of AddressUpdate */
export type AddressUpdatePayload = {
  __typename: 'AddressUpdatePayload';
  address: Address;
};

/** Autogenerated return type of AddressValidate */
export type AddressValidatePayload = {
  __typename: 'AddressValidatePayload';
  address: Address;
};

/** Autogenerated return type of AdminChangePaymentMethod */
export type AdminChangePaymentMethodPayload = {
  __typename: 'AdminChangePaymentMethodPayload';
  account: Account;
};

/** Autogenerated return type of AdminEditCertificatesOfJob */
export type AdminEditCertificatesOfJobPayload = {
  __typename: 'AdminEditCertificatesOfJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminEditNumberWorkersJob */
export type AdminEditNumberWorkersJobPayload = {
  __typename: 'AdminEditNumberWorkersJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminEditRateOfJob */
export type AdminEditRateOfJobPayload = {
  __typename: 'AdminEditRateOfJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminEditTimeOfJob */
export type AdminEditTimeOfJobPayload = {
  __typename: 'AdminEditTimeOfJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminLogin */
export type AdminLoginPayload = {
  __typename: 'AdminLoginPayload';
  accessToken: Scalars['String']['output'];
  customerAdmin: Maybe<CustomerAdmin>;
  disabled: Maybe<Scalars['Boolean']['output']>;
  refreshToken: Scalars['String']['output'];
  resetPassword: Scalars['Boolean']['output'];
  tenantAdmin: Maybe<TenantAdmin>;
};

/** Autogenerated return type of AdminMarkWorkerAsShowed */
export type AdminMarkWorkerAsShowedPayload = {
  __typename: 'AdminMarkWorkerAsShowedPayload';
  jobWorker: JobWorker;
};

/** Agency */
export type Agency = {
  __typename: 'Agency';
  address: Maybe<Address>;
  badgeCertificates: Array<Certificate>;
  badges: Array<Badge>;
  candidates: WorkerPage;
  certificates: Array<Certificate>;
  chatUid: Scalars['String']['output'];
  /** Length of time before a shift begins that workers are allowed to check in */
  checkinWindow: Scalars['Minutes']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerAdmins: CustomerAdminPage;
  customers: CustomerPage;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobs: JobPage;
  key: Scalars['Key']['output'];
  locationDesc: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  /** Distance to the job location a worker must be within to check in */
  maxCheckinDistance: Scalars['Meters']['output'];
  name: Scalars['String']['output'];
  orders: OrderPage;
  orientationSites: Array<OrientationSite>;
  orientations: Array<Orientation>;
  phoneNumber: Scalars['String']['output'];
  /** Length of lead time that must be given between placing a job and its first shift */
  requestLeadtime: Scalars['Minutes']['output'];
  serviceAreas: Array<ServiceArea>;
  shiftsBetweenDates: Array<Shift>;
  shiftsByDay: Array<Shift>;
  shiftsByWeek: Array<Shift>;
  skillCategories: Array<SkillCategory>;
  tags: TagPage;
  tenant: Tenant;
  uniforms: Array<Uniform>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workers: WorkerPage;
};

/** Agency */
export type AgencyCandidatesArgs = {
  filters: InputMaybe<CandidateFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Agency */
export type AgencyCustomerAdminsArgs = {
  filters: InputMaybe<CustomerAdminFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Agency */
export type AgencyCustomersArgs = {
  filters: InputMaybe<CustomerFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<CustomerSortKeyEnum>;
};

/** Agency */
export type AgencyJobsArgs = {
  filters: InputMaybe<JobFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Agency */
export type AgencyOrdersArgs = {
  filters: InputMaybe<OrderFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<OrderSortKeyEnum>;
};

/** Agency */
export type AgencyShiftsBetweenDatesArgs = {
  endDate: Scalars['ISO8601Date']['input'];
  filters: InputMaybe<JobFilterSetInput>;
  startDate: Scalars['ISO8601Date']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Agency */
export type AgencyShiftsByDayArgs = {
  filters: InputMaybe<JobFilterSetInput>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['ISO8601Date']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Agency */
export type AgencyShiftsByWeekArgs = {
  filters: InputMaybe<JobFilterSetInput>;
  startDate: Scalars['ISO8601Date']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Agency */
export type AgencyTagsArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

/** Agency */
export type AgencyWorkersArgs = {
  filters: InputMaybe<WorkerFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Autogenerated return type of AgencyCreate */
export type AgencyCreatePayload = {
  __typename: 'AgencyCreatePayload';
  agency: Agency;
};

/** Autogenerated return type of AgencyUpdate */
export type AgencyUpdatePayload = {
  __typename: 'AgencyUpdatePayload';
  agency: Agency;
};

export enum AppReviewStatusEnum {
  DISMISSED = 'DISMISSED',
  NOT_SHOW = 'NOT_SHOW',
  RATED = 'RATED',
  SHOW = 'SHOW',
}

/** Assessment */
export type Assessment = {
  __typename: 'Assessment';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  options: Scalars['String']['output'];
  optionsEs: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  questionEs: Maybe<Scalars['String']['output']>;
  time: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum BackgroundStatusEnum {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REQUESTED = 'REQUESTED',
}

/** Badge */
export type Badge = {
  __typename: 'Badge';
  category: BadgeCategoryEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  points: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum BadgeCategoryEnum {
  CERTIFICATION = 'CERTIFICATION',
  EVENT = 'EVENT',
  SKILL = 'SKILL',
}

/** Autogenerated return type of BadgeCreate */
export type BadgeCreatePayload = {
  __typename: 'BadgeCreatePayload';
  badge: Badge;
};

/** Autogenerated return type of BadgeableAddBadge */
export type BadgeableAddBadgePayload = {
  __typename: 'BadgeableAddBadgePayload';
  tags: Array<Badge>;
};

/** Autogenerated return type of BadgeableChangeBadges */
export type BadgeableChangeBadgesPayload = {
  __typename: 'BadgeableChangeBadgesPayload';
  badges: Array<Badge>;
};

export enum BadgeableEnum {
  WORKER = 'WORKER',
}

/** Autogenerated return type of BadgeableRemoveBadge */
export type BadgeableRemoveBadgePayload = {
  __typename: 'BadgeableRemoveBadgePayload';
  badges: Array<Badge>;
};

export enum BillingWeekEnum {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

/** Tests field for equality to a given value */
export type BooleanEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['Boolean']['input'];
};

export type BulkEditTimesheetsInput = {
  reportedBreakMinutes?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  reportedCheckinAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  reportedCheckoutAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  tipAmount?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
};

/** Actors that may cancel a job */
export type CancelUserUnion = CustomerAdmin | TenantAdmin;

/** Candidate */
export type Candidate = {
  __typename: 'Candidate';
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  agency: Maybe<Agency>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  backgroundStatus: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  coords: Maybe<Point>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deniedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  heardFrom: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Maybe<Scalars['Int']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  quizCompletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  references: Array<CandidateReference>;
  skill: Maybe<Skill>;
  state: Maybe<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Maybe<Worker>;
  zip: Maybe<Scalars['String']['output']>;
};

/** Available filter operations for a candidate Worker */
export type CandidateFilterSetInput = {
  active?: InputMaybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  ownsCar?: InputMaybe<BooleanEqualityFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  status?: InputMaybe<CandidateStatusEqualityFilterInput>;
  statusGroup?: InputMaybe<Array<CandidateStatusGroupingFilterInput>>;
};

/** Candidate reference */
export type CandidateReference = {
  __typename: 'CandidateReference';
  candidate: Maybe<Candidate>;
  city: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  duration: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  employerName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  summary: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum CandidateStatusEnum {
  APPLIED = 'APPLIED',
  AWAITING_HR = 'AWAITING_HR',
  COMPLETED = 'COMPLETED',
  INTERVIEWING = 'INTERVIEWING',
  ONBOARDED = 'ONBOARDED',
  REJECTED = 'REJECTED',
  START_HIRE = 'START_HIRE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

/** Tests field for equality to a given CandidateStatus */
export type CandidateStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: CandidateStatusEnum;
};

/** filter fields for grouping to some CandidateStatus */
export type CandidateStatusGroupingFilterInput = {
  value: CandidateStatusEnum;
};

/** Certificate */
export type Certificate = {
  __typename: 'Certificate';
  agency: Agency;
  certificateType: CertificateTypeEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionEn: Maybe<Scalars['String']['output']>;
  descriptionEs: Maybe<Scalars['String']['output']>;
  displayNameEn: Maybe<Scalars['String']['output']>;
  displayNameEs: Maybe<Scalars['String']['output']>;
  gravyTrained: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum CertificateTypeEnum {
  BADGE = 'BADGE',
  CERTIFICATE = 'CERTIFICATE',
}

/** Autogenerated return type of ChatGroupSupportCreate */
export type ChatGroupSupportCreatePayload = {
  __typename: 'ChatGroupSupportCreatePayload';
  created: Scalars['Boolean']['output'];
  supportChatGid: Scalars['String']['output'];
};

/** Comment */
export type Comment = {
  __typename: 'Comment';
  actor: CommentableActorUnion;
  body: Maybe<Scalars['String']['output']>;
  commentable: CommentableUnion;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Actors that may post comments */
export type CommentableActorUnion = CustomerAdmin | TenantAdmin;

/** Autogenerated return type of CommentableAddComment */
export type CommentableAddCommentPayload = {
  __typename: 'CommentableAddCommentPayload';
  comments: Array<Comment>;
};

export enum CommentableEnum {
  ACCOUNT = 'ACCOUNT',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  JOB = 'JOB',
  ORDER = 'ORDER',
  WORKER = 'WORKER',
}

/** Types that may be commented on */
export type CommentableUnion =
  | Account
  | Customer
  | CustomerAdmin
  | Job
  | Order
  | Worker;

/** Represents a operator to compare two objects of the same type */
export enum CompareOperatorEnum {
  /** Exactly equal */
  EQ = 'EQ',
  /** Greater-than */
  GT = 'GT',
  /** Greater-than or equal */
  GTEQ = 'GTEQ',
  /** Less-than */
  LT = 'LT',
  /** Less-than or equal */
  LTEQ = 'LTEQ',
  /** Not-equal */
  NOT = 'NOT',
}

/** Represents a payment to a contractor from a company. */
export type ContractorPayment = {
  __typename: 'ContractorPayment';
  checkContractorPaymentId: Scalars['String']['output'];
  contractorPaymentItems: Array<ContractorPaymentItem>;
  id: Scalars['ID']['output'];
  /** The total amount of net pay earned by the contractor. */
  netPay: Maybe<Scalars['String']['output']>;
  /** May be manual or direct_deposit if the contractor has a linked bank account. */
  paymentMethod: Scalars['String']['output'];
  payroll: Payroll;
  /** Status of the contractor payment. */
  status: PayrollItemStatusEnum;
  /** Worker associated with the contractor payment. */
  worker: Maybe<Worker>;
};

/** Represent the set of items associated with a contractor payment. */
export type ContractorPaymentItem = {
  __typename: 'ContractorPaymentItem';
  /** Address where work for this item was performed. */
  address: Maybe<Address>;
  /** Amount earned for this item. */
  amount: Scalars['String']['output'];
  contractorPayment: ContractorPayment;
  /** Readable description of the earning code that will show up on the paystub. */
  description: Maybe<Scalars['String']['output']>;
  /** Type of earning. */
  earningType: PayrollItemEarningEnum;
  /** Number of hours worked for this item */
  hours: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  timesheet: Maybe<Timesheet>;
};

/** Pagination container for ContractorPayment */
export type ContractorPaymentPage = {
  __typename: 'ContractorPaymentPage';
  items: Array<ContractorPayment>;
  pageInfo: PageInfo;
};

/** Actors that may be logged in */
export type CurrentActorUnion = CustomerAdmin | TenantAdmin | Worker;

/** Autogenerated return type of CustomRateCreateBatch */
export type CustomRateCreateBatchPayload = {
  __typename: 'CustomRateCreateBatchPayload';
  allCreated: Scalars['Boolean']['output'];
  message: Maybe<Scalars['String']['output']>;
};

/** Customer */
export type Customer = {
  __typename: 'Customer';
  accounts: Array<Account>;
  addresses: Array<Address>;
  admins: CustomerAdminPage;
  agency: Agency;
  allowedJobType: Maybe<Scalars['Int']['output']>;
  certificates: Array<Certificate>;
  checkInRadiusFeet: Scalars['Int']['output'];
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  invoiceDueDate: Scalars['String']['output'];
  invoices: InvoicePage;
  jobTaxType: Maybe<Scalars['String']['output']>;
  logoKey: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  ltaAllowed: Maybe<Scalars['Boolean']['output']>;
  markupPercent: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  onboardingStatus: CustomerOnboardingStatusEnum;
  orders: OrderPage;
  rateType: AccountRateTypeEnum;
  status: Scalars['NonNegativeInt']['output'];
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workerRelationships: Array<WorkerRelationship>;
};

/** Customer */
export type CustomerAccountsArgs = {
  filters: InputMaybe<AccountFilterSetInput>;
};

/** Customer */
export type CustomerAdminsArgs = {
  filters: InputMaybe<CustomerAdminFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Customer */
export type CustomerInvoicesArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Customer */
export type CustomerOrdersArgs = {
  filters: InputMaybe<OrderFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Customer */
export type CustomerWorkerRelationshipsArgs = {
  filters: InputMaybe<WorkerRelationshipFilterSetInput>;
};

/** Customer admin */
export type CustomerAdmin = {
  __typename: 'CustomerAdmin';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  active: Scalars['Boolean']['output'];
  avatarKey: Maybe<Scalars['String']['output']>;
  avatarUrl: Maybe<Scalars['String']['output']>;
  chatUid: Scalars['String']['output'];
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  department: Maybe<Scalars['String']['output']>;
  devices: Array<Device>;
  id: Scalars['ID']['output'];
  role: Scalars['Int']['output'];
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

/** Autogenerated return type of CustomerAdminChangeAvatar */
export type CustomerAdminChangeAvatarPayload = {
  __typename: 'CustomerAdminChangeAvatarPayload';
  customerAdmin: CustomerAdmin;
};

/** Available filter operations for a CustomerAdmin */
export type CustomerAdminFilterSetInput = {
  acceptedAt?: InputMaybe<DateTimeCompareFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  customer?: InputMaybe<IdEqualityFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  tags?: InputMaybe<IdMembershipFilterInput>;
};

/** Autogenerated return type of CustomerAdminLogin */
export type CustomerAdminLoginPayload = {
  __typename: 'CustomerAdminLoginPayload';
  accessToken: Scalars['String']['output'];
  customerAdmin: CustomerAdmin;
  refreshToken: Scalars['String']['output'];
};

/** Pagination container for CustomerAdmin */
export type CustomerAdminPage = {
  __typename: 'CustomerAdminPage';
  items: Array<CustomerAdmin>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerAdminResendInvitation */
export type CustomerAdminResendInvitationPayload = {
  __typename: 'CustomerAdminResendInvitationPayload';
  result: Scalars['Boolean']['output'];
};

/** Autogenerated return type of CustomerAdminUpdateProfile */
export type CustomerAdminUpdateProfilePayload = {
  __typename: 'CustomerAdminUpdateProfilePayload';
  customerAdmin: CustomerAdmin;
};

/** Autogenerated return type of CustomerChangeLogo */
export type CustomerChangeLogoPayload = {
  __typename: 'CustomerChangeLogoPayload';
  customer: Customer;
};

/** Autogenerated return type of CustomerCreate */
export type CustomerCreatePayload = {
  __typename: 'CustomerCreatePayload';
  customer: Customer;
};

/** Available filter operations for a Customer */
export type CustomerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<DateTimeRangeFilterInput>;
  createdAtRange?: InputMaybe<DateTimeRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobTaxType?: InputMaybe<Array<TaxTypeEnum>>;
  ltaAllowed?: InputMaybe<BooleanEqualityFilterInput>;
  onboardingStatus?: InputMaybe<Array<CustomerOnboardingStatusEnum>>;
  query?: InputMaybe<TextSearchFilterInput>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<Scalars['NonNegativeInt']['input']>>;
  tags?: InputMaybe<TaggedFilterInput>;
  updatedAt?: InputMaybe<DateTimeCompareFilterInput>;
  updatedAtRange?: InputMaybe<DateTimeRangeFilterInput>;
};

/** Autogenerated return type of CustomerInviteAdmin */
export type CustomerInviteAdminPayload = {
  __typename: 'CustomerInviteAdminPayload';
  customerAdmin: CustomerAdmin;
};

/** Customer list report */
export type CustomerListReport = {
  __typename: 'CustomerListReport';
  accountAgeDays: Scalars['Int']['output'];
  accountId: Scalars['ID']['output'];
  accountName: Scalars['String']['output'];
  accountRepEmail: Scalars['String']['output'];
  accountRepName: Scalars['String']['output'];
  accountRepPhone: Scalars['String']['output'];
  city: Scalars['String']['output'];
  clientUrl: Scalars['String']['output'];
  customerId: Scalars['ID']['output'];
  customerName: Scalars['String']['output'];
  latestOrder: Maybe<Scalars['String']['output']>;
  ordersFiveMonthsAgo: Maybe<Scalars['Int']['output']>;
  ordersFourMonthsAgo: Maybe<Scalars['Int']['output']>;
  ordersLastMonth: Maybe<Scalars['Int']['output']>;
  ordersSixMonthsAgo: Maybe<Scalars['Int']['output']>;
  ordersThisMonth: Maybe<Scalars['Int']['output']>;
  ordersThreeMonthsAgo: Maybe<Scalars['Int']['output']>;
  ordersTwoMonthsAgo: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  totalJobs: Scalars['Int']['output'];
  zip: Scalars['String']['output'];
};

export enum CustomerOnboardingStatusEnum {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

/** Pagination container for Customer */
export type CustomerPage = {
  __typename: 'CustomerPage';
  items: Array<Customer>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerRelateWorker */
export type CustomerRelateWorkerPayload = {
  __typename: 'CustomerRelateWorkerPayload';
  workerRelationship: WorkerRelationship;
};

/** Autogenerated return type of CustomerSignup */
export type CustomerSignupPayload = {
  __typename: 'CustomerSignupPayload';
  customer: Maybe<Customer>;
  success: Scalars['Boolean']['output'];
};

export enum CustomerSortKeyEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
}

/** Autogenerated return type of CustomerUpdateJobTaxType */
export type CustomerUpdateJobTaxTypePayload = {
  __typename: 'CustomerUpdateJobTaxTypePayload';
  customer: Customer;
};

/** Autogenerated return type of CustomerUpdate */
export type CustomerUpdatePayload = {
  __typename: 'CustomerUpdatePayload';
  customer: Customer;
};

/** Tests an operation against a static value */
export type DateTimeCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['ISO8601DateTime']['input'];
};

/** Tests inclusion between a given inclusive range */
export type DateTimeRangeFilterInput = {
  from: Scalars['ISO8601DateTime']['input'];
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['ISO8601DateTime']['input'];
};

export enum DefaultDueDateEnum {
  INVOICE_DUE_10_DAYS = 'INVOICE_DUE_10_DAYS',
  INVOICE_DUE_15_DAYS = 'INVOICE_DUE_15_DAYS',
  INVOICE_DUE_30_DAYS = 'INVOICE_DUE_30_DAYS',
  INVOICE_DUE_45_DAYS = 'INVOICE_DUE_45_DAYS',
  INVOICE_DUE_60_DAYS = 'INVOICE_DUE_60_DAYS',
}

/** Default Rate */
export type DefaultRate = {
  __typename: 'DefaultRate';
  city: Maybe<Scalars['String']['output']>;
  costRate: Maybe<Scalars['PositiveInt']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  payRate: Maybe<Scalars['PositiveInt']['output']>;
  region: Maybe<Region>;
  skill: Maybe<Skill>;
  state: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of DefaultRateCreate */
export type DefaultRateCreatePayload = {
  __typename: 'DefaultRateCreatePayload';
  defaultRate: DefaultRate;
};

/** Autogenerated return type of DefaultRateUpdate */
export type DefaultRateUpdatePayload = {
  __typename: 'DefaultRateUpdatePayload';
  defaultRate: DefaultRate;
};

/** Defined boundry */
export type DefinedBoundry = {
  __typename: 'DefinedBoundry';
  area: MultiPolygon;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Pagination container for DefinedBoundry */
export type DefinedBoundryPage = {
  __typename: 'DefinedBoundryPage';
  items: Array<DefinedBoundry>;
  pageInfo: PageInfo;
};

/** Device */
export type Device = {
  __typename: 'Device';
  client: Maybe<Scalars['String']['output']>;
  clientVersion: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expiresAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  ip: Maybe<Scalars['String']['output']>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastIssued: Scalars['String']['output'];
  lastIssuedAt: Scalars['ISO8601DateTime']['output'];
  notificationsStatus: Maybe<Scalars['Boolean']['output']>;
  refreshToken: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userAgent: Maybe<Scalars['String']['output']>;
};

export enum DurationEnum {
  FROM_0_TO_6_MONTHS = 'FROM_0_TO_6_MONTHS',
  FROM_1_TO_3_YEARS = 'FROM_1_TO_3_YEARS',
  FROM_6_TO_12_MONTHS = 'FROM_6_TO_12_MONTHS',
  MORE_THAN_3_YEARS = 'MORE_THAN_3_YEARS',
}

export enum EmailActionEnum {
  ACTIVE_USERS_REPORT = 'ACTIVE_USERS_REPORT',
  CHECKR_INVITATION = 'CHECKR_INVITATION',
  EMAIL_CHANGE = 'EMAIL_CHANGE',
  EMAIL_VALIDATED = 'EMAIL_VALIDATED',
  EMPLOYEE_TIMESHEETS_REPORT = 'EMPLOYEE_TIMESHEETS_REPORT',
  INVITE_CLIENT_ADMIN = 'INVITE_CLIENT_ADMIN',
  INVITE_CUSTOMER_ADMIN = 'INVITE_CUSTOMER_ADMIN',
  INVOICE_JOB_FAILED = 'INVOICE_JOB_FAILED',
  INVOICE_PAYMENT_INTENT_FAILED = 'INVOICE_PAYMENT_INTENT_FAILED',
  INVOICE_PAYMENT_INTENT_SUCCESSFUL = 'INVOICE_PAYMENT_INTENT_SUCCESSFUL',
  JOBS_DROPPED_BY_WORKER = 'JOBS_DROPPED_BY_WORKER',
  JOB_CANCELLED = 'JOB_CANCELLED',
  JOB_DROPPED_BY_WORKER = 'JOB_DROPPED_BY_WORKER',
  JOB_ORDERED = 'JOB_ORDERED',
  MONITOR_CLIENT_SIGNUP = 'MONITOR_CLIENT_SIGNUP',
  PAYROLL_JOB_FAILED = 'PAYROLL_JOB_FAILED',
  PAYROLL_JOURNAL_REPORT = 'PAYROLL_JOURNAL_REPORT',
  PERSONALIZED = 'PERSONALIZED',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  RECOVER_WEB_PASSWORD = 'RECOVER_WEB_PASSWORD',
  REFERRALS = 'REFERRALS',
  RESEND_TOKEN = 'RESEND_TOKEN',
  RESET_WORKER_PASSWORD = 'RESET_WORKER_PASSWORD',
  WORKER_ONBOARDING = 'WORKER_ONBOARDING',
  WORKER_VALIDATE_EMAIL = 'WORKER_VALIDATE_EMAIL',
  WORKER_WELCOME = 'WORKER_WELCOME',
}

/** Event */
export type Event = {
  __typename: 'Event';
  action: EventActionEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  cursor: Scalars['String']['output'];
  data: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  jti: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['Int']['output'];
  subjects: Array<EventSubject>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
  uuid: Scalars['String']['output'];
};

export enum EventActionEnum {
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_SET_CURRENT_PAYMENT_METHOD = 'ACCOUNT_SET_CURRENT_PAYMENT_METHOD',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ADDED_TO_NEW_ANNOUNCEMENT = 'ADDED_TO_NEW_ANNOUNCEMENT',
  ADDRESS_CREATE = 'ADDRESS_CREATE',
  ADDRESS_DELETE = 'ADDRESS_DELETE',
  ADDRESS_UPDATE = 'ADDRESS_UPDATE',
  ADDRESS_VALIDATE = 'ADDRESS_VALIDATE',
  ADD_CANDIDATE_SKILL = 'ADD_CANDIDATE_SKILL',
  ADMIN_RESET_WORKER_PASSWORD = 'ADMIN_RESET_WORKER_PASSWORD',
  AGENCY_CREATE = 'AGENCY_CREATE',
  AGENCY_UPDATE = 'AGENCY_UPDATE',
  COMMENTABLE_ADD_COMMENT = 'COMMENTABLE_ADD_COMMENT',
  COMPLETE_CANDIDATE_QUIZ = 'COMPLETE_CANDIDATE_QUIZ',
  CREATE_CANDIDATE_REFERENCE = 'CREATE_CANDIDATE_REFERENCE',
  CUSTOMER_ADMIN_CHANGE_AVATAR = 'CUSTOMER_ADMIN_CHANGE_AVATAR',
  CUSTOMER_ADMIN_PAYMENT_METHOD = 'CUSTOMER_ADMIN_PAYMENT_METHOD',
  CUSTOMER_ADMIN_RESEND_INVITATION = 'CUSTOMER_ADMIN_RESEND_INVITATION',
  CUSTOMER_ADMIN_UPDATE_PROFILE = 'CUSTOMER_ADMIN_UPDATE_PROFILE',
  CUSTOMER_CHANGE_LOGO = 'CUSTOMER_CHANGE_LOGO',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_INVITE_ADMIN = 'CUSTOMER_INVITE_ADMIN',
  CUSTOMER_RELATE_WORKER = 'CUSTOMER_RELATE_WORKER',
  CUSTOMER_UNRELATE_WORKER = 'CUSTOMER_UNRELATE_WORKER',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  CUSTOM_RATE_CREATE_BATCH = 'CUSTOM_RATE_CREATE_BATCH',
  DEFAULT_RATE_CREATE = 'DEFAULT_RATE_CREATE',
  DEFAULT_RATE_UPDATE = 'DEFAULT_RATE_UPDATE',
  DEVICE_UPDATE_NOTIFICATIONS = 'DEVICE_UPDATE_NOTIFICATIONS',
  INVOICE_PAYMENT_INTENT_CREATE = 'INVOICE_PAYMENT_INTENT_CREATE',
  INVOICE_PAYMENT_INTENT_UPDATE = 'INVOICE_PAYMENT_INTENT_UPDATE',
  JOB_CANCEL = 'JOB_CANCEL',
  JOB_CANCEL_BATCH = 'JOB_CANCEL_BATCH',
  JOB_DISMISS_WORKER = 'JOB_DISMISS_WORKER',
  JOB_EDIT_NUMBER_WORKERS = 'JOB_EDIT_NUMBER_WORKERS',
  JOB_EDIT_TIME = 'JOB_EDIT_TIME',
  JOB_HIRE_WORKER = 'JOB_HIRE_WORKER',
  JOB_HOLD = 'JOB_HOLD',
  JOB_MATCHING_WORKER = 'JOB_MATCHING_WORKER',
  JOB_OFFERS_REJECT = 'JOB_OFFERS_REJECT',
  JOB_OFFER_ACCEPT = 'JOB_OFFER_ACCEPT',
  JOB_OFFER_CREATE = 'JOB_OFFER_CREATE',
  JOB_OFFER_GROUP_ACCEPT = 'JOB_OFFER_GROUP_ACCEPT',
  JOB_OFFER_REJECT = 'JOB_OFFER_REJECT',
  JOB_PUBLISH = 'JOB_PUBLISH',
  JOB_REQUESTS_CREATE = 'JOB_REQUESTS_CREATE',
  JOB_REQUEST_ACCEPT = 'JOB_REQUEST_ACCEPT',
  JOB_REQUEST_CANCEL = 'JOB_REQUEST_CANCEL',
  JOB_REQUEST_CREATE = 'JOB_REQUEST_CREATE',
  JOB_REQUEST_REJECT = 'JOB_REQUEST_REJECT',
  JOB_UNPUBLISH = 'JOB_UNPUBLISH',
  JOB_UPDATE = 'JOB_UPDATE',
  ORDER_CREATE = 'ORDER_CREATE',
  RATE_CANCEL = 'RATE_CANCEL',
  RATE_CREATE = 'RATE_CREATE',
  RATE_DELETE = 'RATE_DELETE',
  RATE_UPDATE = 'RATE_UPDATE',
  REFERRAL_CREATE = 'REFERRAL_CREATE',
  SERVICE_AREA_CREATE = 'SERVICE_AREA_CREATE',
  SERVICE_AREA_UPDATE = 'SERVICE_AREA_UPDATE',
  SIGNUP_CANDIDATE = 'SIGNUP_CANDIDATE',
  SKILL_CATEGORY_CREATE = 'SKILL_CATEGORY_CREATE',
  SKILL_CATEGORY_UPDATE = 'SKILL_CATEGORY_UPDATE',
  SKILL_CREATE = 'SKILL_CREATE',
  SKILL_REQUEST_ACCEPT = 'SKILL_REQUEST_ACCEPT',
  SKILL_REQUEST_REJECT = 'SKILL_REQUEST_REJECT',
  SKILL_UPDATE = 'SKILL_UPDATE',
  STRIPE_ATTACH_PAYMENT_METHOD = 'STRIPE_ATTACH_PAYMENT_METHOD',
  STRIPE_CREATE_INVOICE_ITEM = 'STRIPE_CREATE_INVOICE_ITEM',
  STRIPE_DELETE_INVOICE = 'STRIPE_DELETE_INVOICE',
  STRIPE_DELETE_INVOICE_ITEM = 'STRIPE_DELETE_INVOICE_ITEM',
  STRIPE_FINALIZE_INVOICE = 'STRIPE_FINALIZE_INVOICE',
  STRIPE_GET_PAYMENT_METHOD = 'STRIPE_GET_PAYMENT_METHOD',
  STRIPE_UPDATE_INVOICE = 'STRIPE_UPDATE_INVOICE',
  STRIPE_UPDATE_INVOICE_ITEM = 'STRIPE_UPDATE_INVOICE_ITEM',
  STRIPE_VOID_INVOICE = 'STRIPE_VOID_INVOICE',
  TENANT_ADMIN_CHANGE_AVATAR = 'TENANT_ADMIN_CHANGE_AVATAR',
  TENANT_ADMIN_CREATE = 'TENANT_ADMIN_CREATE',
  TENANT_ADMIN_EDIT_JOB_RATES = 'TENANT_ADMIN_EDIT_JOB_RATES',
  TENANT_ADMIN_EDIT_JOB_TIMES = 'TENANT_ADMIN_EDIT_JOB_TIMES',
  TENANT_ADMIN_RESEND_INVITATION = 'TENANT_ADMIN_RESEND_INVITATION',
  TENANT_UPDATE = 'TENANT_UPDATE',
  TIMESHEET_APPROVE = 'TIMESHEET_APPROVE',
  TIMESHEET_CHECKIN = 'TIMESHEET_CHECKIN',
  TIMESHEET_CHECKOUT = 'TIMESHEET_CHECKOUT',
  TIMESHEET_CREATE = 'TIMESHEET_CREATE',
  TIMESHEET_REJECT = 'TIMESHEET_REJECT',
  TIMESHEET_UNAPPROVE = 'TIMESHEET_UNAPPROVE',
  TIMESHEET_UNREJECT = 'TIMESHEET_UNREJECT',
  TIMESHEET_UPDATE = 'TIMESHEET_UPDATE',
  UNLINK_INVOICE = 'UNLINK_INVOICE',
  UPDATE_CANDIDATE_PROFILE = 'UPDATE_CANDIDATE_PROFILE',
  USER_CHANGE_ACTIVE = 'USER_CHANGE_ACTIVE',
  USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD',
  USER_LOGOUT_ALL = 'USER_LOGOUT_ALL',
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',
  USER_UPDATE = 'USER_UPDATE',
  VERIFY_CANDIDATE = 'VERIFY_CANDIDATE',
  WILLO_WORKER_INTERVIEW_UPDATE = 'WILLO_WORKER_INTERVIEW_UPDATE',
  WORKER_ACCOUNT_DISABLE = 'WORKER_ACCOUNT_DISABLE',
  WORKER_ADD_CERTIFICATE = 'WORKER_ADD_CERTIFICATE',
  WORKER_ADD_REFERENCE = 'WORKER_ADD_REFERENCE',
  WORKER_ADD_REFERENCES = 'WORKER_ADD_REFERENCES',
  WORKER_ADD_SKILL = 'WORKER_ADD_SKILL',
  WORKER_ASSIGN_JOB_WORKPLACE = 'WORKER_ASSIGN_JOB_WORKPLACE',
  WORKER_CHANGE_ACTIVE = 'WORKER_CHANGE_ACTIVE',
  WORKER_CHANGE_ADDRESS = 'WORKER_CHANGE_ADDRESS',
  WORKER_CHANGE_AVATAR = 'WORKER_CHANGE_AVATAR',
  WORKER_CREATE = 'WORKER_CREATE',
  WORKER_CREATE_SUPPORT_CHAT = 'WORKER_CREATE_SUPPORT_CHAT',
  WORKER_DISABLE = 'WORKER_DISABLE',
  WORKER_DROP_JOB = 'WORKER_DROP_JOB',
  WORKER_INTERVIEW_CREATE = 'WORKER_INTERVIEW_CREATE',
  WORKER_INTERVIEW_UPDATE = 'WORKER_INTERVIEW_UPDATE',
  WORKER_JURISDICTION_CANCEL = 'WORKER_JURISDICTION_CANCEL',
  WORKER_JURISDICTION_REQUEST = 'WORKER_JURISDICTION_REQUEST',
  WORKER_JURISDICTION_UPDATE = 'WORKER_JURISDICTION_UPDATE',
  WORKER_ONBOARD = 'WORKER_ONBOARD',
  WORKER_REMOVE_JOB_WORKPLACEE = 'WORKER_REMOVE_JOB_WORKPLACEE',
  WORKER_REMOVE_SKILL = 'WORKER_REMOVE_SKILL',
  WORKER_REQUEST_SKILL = 'WORKER_REQUEST_SKILL',
  WORKER_SIGNUP = 'WORKER_SIGNUP',
  WORKER_SUBMIT_APPLICATION = 'WORKER_SUBMIT_APPLICATION',
  WORKER_UPDATE_APPLICANT = 'WORKER_UPDATE_APPLICANT',
  WORKER_UPDATE_BACKGROUND_CHECK = 'WORKER_UPDATE_BACKGROUND_CHECK',
  WORKER_UPDATE_CANDIDATE = 'WORKER_UPDATE_CANDIDATE',
  WORKER_UPDATE_ONBOARDING_STATUS = 'WORKER_UPDATE_ONBOARDING_STATUS',
  WORKER_UPDATE_PROFILE = 'WORKER_UPDATE_PROFILE',
  WORKER_UPDATE_TAX_TYPE = 'WORKER_UPDATE_TAX_TYPE',
  WORKER_UPDATE_WILLO_INTERVIEW = 'WORKER_UPDATE_WILLO_INTERVIEW',
  WORKER_VERIFY = 'WORKER_VERIFY',
}

/** Event subject */
export type EventSubject = {
  __typename: 'EventSubject';
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Maybe<Event>;
  id: Scalars['ID']['output'];
  object: EventSubjectObject;
  role: Maybe<SubjectRoleEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum EventSubjectEnum {
  ACCOUNT = 'ACCOUNT',
  ADDRESS = 'ADDRESS',
  AGENCY = 'AGENCY',
  CANDIDATE = 'CANDIDATE',
  CANDIDATE_REFERENCE = 'CANDIDATE_REFERENCE',
  CUSTOMER = 'CUSTOMER',
  DEVICE = 'DEVICE',
  JOB = 'JOB',
  JOB_OFFER = 'JOB_OFFER',
  JOB_WORKER = 'JOB_WORKER',
  ORDER = 'ORDER',
  ORIENTATION = 'ORIENTATION',
  ORIENTATION_SITE = 'ORIENTATION_SITE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  RATE = 'RATE',
  SERVICE_AREA = 'SERVICE_AREA',
  SKILL = 'SKILL',
  SKILL_CATEGORY = 'SKILL_CATEGORY',
  TAG = 'TAG',
  TAGGING = 'TAGGING',
  TENANT = 'TENANT',
  TIMESHEET = 'TIMESHEET',
  USER = 'USER',
  WORKER = 'WORKER',
  WORKER_SKILL = 'WORKER_SKILL',
}

/** The underlying object of an EventSubject */
export type EventSubjectObject = {
  __typename: 'EventSubjectObject';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: EventSubjectEnum;
};

/** Fetch Rates */
export type FetchRates = {
  __typename: 'FetchRates';
  createdAt: Scalars['ISO8601DateTime']['output'];
  defaultRates: Maybe<Array<DefaultRate>>;
  rates: Maybe<Array<Rate>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum FileTypeEnum {
  AVATAR = 'AVATAR',
  ICON = 'ICON',
  LOGO = 'LOGO',
}

export enum FillStatusEnum {
  ALL = 'ALL',
  FILLED = 'FILLED',
  UNFILLED = 'UNFILLED',
}

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum I9StatusEnum {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  EVERIFY_IN_PROGRESS = 'EVERIFY_IN_PROGRESS',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  PENDING = 'PENDING',
  PENDING_EVERIFY = 'PENDING_EVERIFY',
  PENDING_SECTION2 = 'PENDING_SECTION2',
  PROSPECT = 'PROSPECT',
  REJECTED = 'REJECTED',
  TENTATIVE_NC = 'TENTATIVE_NC',
}

/** I9 Form */
export type I9form = {
  __typename: 'I9form';
  b2bStatus: Maybe<Scalars['String']['output']>;
  formi9RequestId: Scalars['Int']['output'];
  formi9ResultId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  processStatus: Scalars['String']['output'];
  requestId: Scalars['Int']['output'];
  resultId: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

/** Tests field for equality to a given value */
export type IdEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['ID']['input'];
};

/** Tests field for inclusion in a list */
export type IdMembershipFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: Array<Scalars['ID']['input']>;
};

/** Tests an operation against a static value */
export type IntCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['Int']['input'];
};

export type InterviewAnswerInput = {
  answer: Scalars['String']['input'];
  interviewQuestionId: Scalars['ID']['input'];
};

/** Interview Question */
export type InterviewQuestion = {
  __typename: 'InterviewQuestion';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  placeholder: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum InterviewStatusEnum {
  INTERVIEW_DECLINED = 'INTERVIEW_DECLINED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_REQUESTED = 'INTERVIEW_REQUESTED',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_TO_REVIEW = 'INTERVIEW_TO_REVIEW',
}

/** An Invoice */
export type Invoice = {
  __typename: 'Invoice';
  account: Account;
  amountDue: Scalars['String']['output'];
  autoApprove: Scalars['Boolean']['output'];
  created: Scalars['String']['output'];
  createdDatetime: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  defaultContactEmail: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['String']['output']>;
  dueDateDatetime: Maybe<Scalars['ISO8601DateTime']['output']>;
  hostedInvoiceUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceFinalizedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoicePdf: Maybe<Scalars['String']['output']>;
  invoiceUid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  number: Maybe<Scalars['String']['output']>;
  status: StripeInvoiceStatusEnum;
  stripeCustomerId: Scalars['String']['output'];
  stripeInvoiceId: Scalars['String']['output'];
  subtotal: Scalars['String']['output'];
  total: Scalars['String']['output'];
};

export enum InvoiceCutOffEnum {
  BIWEEKLY = 'BIWEEKLY',
  DAILY = 'DAILY',
  JOB = 'JOB',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

/** Available filter operations for a Invoice */
export type InvoiceFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<TextSearchFilterInput>;
};

/** Autogenerated return type of InvoiceGenerate */
export type InvoiceGeneratePayload = {
  __typename: 'InvoiceGeneratePayload';
  invoice: Invoice;
};

export enum InvoiceGroupByEnum {
  LOCATION = 'LOCATION',
  NONE = 'NONE',
  SKILL = 'SKILL',
}

/** An Invoice Item */
export type InvoiceItem = {
  __typename: 'InvoiceItem';
  address: Maybe<Scalars['String']['output']>;
  amount: Scalars['String']['output'];
  billRate: Maybe<Scalars['NonNegativeInt']['output']>;
  cancelled: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['String']['output'];
  date: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Scalars['String']['output']>;
  isEdited: Maybe<Scalars['Boolean']['output']>;
  isHoliday: Maybe<Scalars['Boolean']['output']>;
  isOvertime: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['ID']['output']>;
  netTime: Maybe<Scalars['String']['output']>;
  netTimeMinutes: Maybe<Scalars['NonNegativeInt']['output']>;
  order: Maybe<Scalars['String']['output']>;
  quantity: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
  skill: Maybe<Skill>;
  stripeCustomerId: Scalars['String']['output'];
  timesheet: Maybe<Scalars['String']['output']>;
  timesheetId: Maybe<Scalars['ID']['output']>;
  timesheetInvoice: Maybe<Invoice>;
  tip: Maybe<Scalars['NonNegativeFloat']['output']>;
  unitAmount: Scalars['String']['output'];
  unitAmountDecimal: Maybe<Scalars['String']['output']>;
  worker: Maybe<Worker>;
  workerTimesheet: Maybe<Timesheet>;
};

/** Pagination container for Invoice */
export type InvoicePage = {
  __typename: 'InvoicePage';
  items: Array<Invoice>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of InvoicePay */
export type InvoicePayPayload = {
  __typename: 'InvoicePayPayload';
  success: Scalars['Boolean']['output'];
};

export enum InvoiceSortKeyEnum {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  CREATED_ON = 'CREATED_ON',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  DUE_DATE = 'DUE_DATE',
  ID = 'ID',
  STATUS = 'STATUS',
}

/** Autogenerated return type of InvoiceTimesheetsPdf */
export type InvoiceTimesheetsPdfPayload = {
  __typename: 'InvoiceTimesheetsPdfPayload';
  pdfCreated: UploadUrl;
};

/** Invoicing Report */
export type InvoicingReport = {
  __typename: 'InvoicingReport';
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  invoiceDate: Maybe<Scalars['String']['output']>;
  invoiceNumber: Maybe<Scalars['String']['output']>;
  invoicePaid: Maybe<Scalars['String']['output']>;
  invoiceStatus: Maybe<Scalars['String']['output']>;
  invoiced: Scalars['String']['output'];
  jobDate: Scalars['String']['output'];
  openInvoice: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  unapprovedHours: Scalars['Float']['output'];
};

/** Job */
export type Job = {
  __typename: 'Job';
  account: Account;
  activeJobRequests: Array<JobRequest>;
  activeJobWorkers: Array<JobWorker>;
  address: Address;
  addressInstructions: Maybe<Scalars['String']['output']>;
  agency: Agency;
  approvalStatus: JobApprovalStatusEnum;
  autoAcceptRequests: Scalars['Boolean']['output'];
  cancelUser: Maybe<CancelUserUnion>;
  cancelled: Scalars['Boolean']['output'];
  cancelledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  certificates: Array<Certificate>;
  chatGid: Maybe<Scalars['String']['output']>;
  checkinEnabled: Scalars['Boolean']['output'];
  checkinWorkerCount: Maybe<Scalars['NonNegativeInt']['output']>;
  checkoutEnabled: Scalars['Boolean']['output'];
  comments: Array<Comment>;
  contact: CustomerAdmin;
  contactInstructions: Maybe<Scalars['String']['output']>;
  costRate: Scalars['PositiveInt']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  filled: Scalars['Boolean']['output'];
  firstShiftStartAt: Scalars['ISO8601DateTime']['output'];
  groupId: Maybe<Scalars['String']['output']>;
  groupJobs: Maybe<Array<Job>>;
  hasNoShow: Scalars['Boolean']['output'];
  hiredWorkersCount: Scalars['NonNegativeInt']['output'];
  id: Scalars['ID']['output'];
  inactiveJobWorkers: Array<JobWorker>;
  instructions: Maybe<Scalars['String']['output']>;
  isHoliday: Scalars['Boolean']['output'];
  isRateLock: Maybe<Scalars['Boolean']['output']>;
  jobHoldBy: Maybe<Scalars['Int']['output']>;
  jobHoldOn: Maybe<Scalars['ISO8601DateTime']['output']>;
  jobOffers: Array<JobOffer>;
  jobOffersPendingToAccept: Maybe<Array<Job>>;
  jobRequests: Array<JobRequest>;
  jobStatus: Maybe<Scalars['Int']['output']>;
  jobType: JobTypeEnum;
  jobWorkers: Array<JobWorker>;
  jobsAvailable: Maybe<Scalars['Boolean']['output']>;
  lastShiftEndAt: Scalars['ISO8601DateTime']['output'];
  mandatoryBreakTime: Maybe<Scalars['Minutes']['output']>;
  markupPercent: Maybe<Scalars['Float']['output']>;
  minBillRate: Scalars['PositiveInt']['output'];
  minPayRate: Scalars['PositiveInt']['output'];
  needsApproval: Scalars['Boolean']['output'];
  order: Order;
  orderId: Maybe<Scalars['String']['output']>;
  originalCostRate: Scalars['PositiveInt']['output'];
  originalPayRate: Scalars['PositiveInt']['output'];
  payRate: Scalars['PositiveInt']['output'];
  postedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  publishedOrInvited: Scalars['Boolean']['output'];
  quantity: Scalars['PositiveInt']['output'];
  rate: Rate;
  reason: Maybe<Scalars['String']['output']>;
  sameWorkerAllShifts: Scalars['Boolean']['output'];
  shifts: Array<Shift>;
  skill: Skill;
  status: JobStatusEnum;
  tags: Array<Tag>;
  taxType: Maybe<TaxTypeEnum>;
  timesheets: Array<Timesheet>;
  uniform: Uniform;
  uniformInstructions: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userCancelId: Maybe<Scalars['ID']['output']>;
  workerGroupJobs: Maybe<Array<Job>>;
};

/** Job */
export type JobJobOffersPendingToAcceptArgs = {
  workerId: Scalars['ID']['input'];
};

/** Job */
export type JobTimesheetsArgs = {
  filters: InputMaybe<TimesheetFilterSetInput>;
};

/** Job */
export type JobWorkerGroupJobsArgs = {
  workerId: Scalars['ID']['input'];
};

/** Autogenerated return type of JobAddSubscriber */
export type JobAddSubscriberPayload = {
  __typename: 'JobAddSubscriberPayload';
  agency: Agency;
  job: Job;
  order: Order;
  shift: Shift;
};

export enum JobApplicationEnum {
  ACCEPTED = 'ACCEPTED',
  ALL = 'ALL',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given Job Application Status */
export type JobApplicationEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: JobApplicationEnum;
};

export enum JobApprovalStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NO_SHOW = 'NO_SHOW',
  NO_TIMESHEET = 'NO_TIMESHEET',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TIMESHEET_UNFINISHED = 'TIMESHEET_UNFINISHED',
}

/** Autogenerated return type of JobCancelBatch */
export type JobCancelBatchPayload = {
  __typename: 'JobCancelBatchPayload';
  order: Order;
};

/** Autogenerated return type of JobCancel */
export type JobCancelPayload = {
  __typename: 'JobCancelPayload';
  cancelUser: User;
  reason: Scalars['String']['output'];
};

export enum JobDashboardStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  NO_SHOW = 'NO_SHOW',
}

/** Available filter operations for a Job */
export type JobFilterSetInput = {
  accounts?: InputMaybe<IdMembershipFilterInput>;
  addresses?: InputMaybe<IdMembershipFilterInput>;
  agencies?: InputMaybe<IdMembershipFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledAt?: InputMaybe<DateTimeCompareFilterInput>;
  contacts?: InputMaybe<IdMembershipFilterInput>;
  costRate?: InputMaybe<IntCompareFilterInput>;
  customerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customers?: InputMaybe<IdMembershipFilterInput>;
  dashboardJobStatus?: InputMaybe<Array<JobStatusEnum>>;
  dashboardVisibility?: InputMaybe<Array<VisibilityStatusEnum>>;
  description?: InputMaybe<TextSearchFilterInput>;
  fillStatus?: InputMaybe<FillStatusEnum>;
  firstShiftStartAt?: InputMaybe<DateTimeCompareFilterInput>;
  hiredWorkers?: InputMaybe<IntCompareFilterInput>;
  instructions?: InputMaybe<TextSearchFilterInput>;
  jobDashboardStatus?: InputMaybe<Array<JobDashboardStatusEnum>>;
  jobStatus?: InputMaybe<JobStatusEqualityFilterInput>;
  lastShiftEndAt?: InputMaybe<DateTimeCompareFilterInput>;
  needsApproval?: InputMaybe<NeedsApprovalEnum>;
  orders?: InputMaybe<IdMembershipFilterInput>;
  payRate?: InputMaybe<IntCompareFilterInput>;
  posted?: InputMaybe<Scalars['Boolean']['input']>;
  postedAt?: InputMaybe<DateTimeCompareFilterInput>;
  quantity?: InputMaybe<IntCompareFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  rates?: InputMaybe<IdMembershipFilterInput>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  skills?: InputMaybe<IdMembershipFilterInput>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  surge?: InputMaybe<BooleanEqualityFilterInput>;
  timelineStatus?: InputMaybe<TimelineStatusEnum>;
  unfilledWorkers?: InputMaybe<IntCompareFilterInput>;
  uniforms?: InputMaybe<IdMembershipFilterInput>;
  visibility?: InputMaybe<VisibilityStatusEnum>;
};

/** Autogenerated return type of JobHireWorker */
export type JobHireWorkerPayload = {
  __typename: 'JobHireWorkerPayload';
  job: Job;
  jobWorker: JobWorker;
};

/** Job history */
export type JobHistory = {
  __typename: 'JobHistory';
  createdBy: Maybe<User>;
  jobId: Maybe<Scalars['ID']['output']>;
  ratesUpdates: Maybe<Array<Event>>;
};

export type JobInput = {
  addressId: Scalars['ID']['input'];
  addressInstructions?: InputMaybe<Scalars['String']['input']>;
  allShiftsSameWorker?: InputMaybe<Scalars['Boolean']['input']>;
  autoAcceptRequests?: InputMaybe<Scalars['Boolean']['input']>;
  certificates?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactId: Scalars['ID']['input'];
  contactInstructions?: InputMaybe<Scalars['String']['input']>;
  costRate?: InputMaybe<Scalars['PositiveInt']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  hireWorkers?: InputMaybe<Array<Scalars['ID']['input']>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  inviteWorkers?: InputMaybe<Array<Scalars['ID']['input']>>;
  isHoliday?: InputMaybe<Scalars['Boolean']['input']>;
  jobType?: InputMaybe<JobTypeEnum>;
  mandatoryBreakTime?: InputMaybe<Scalars['Minutes']['input']>;
  originalCostRate?: InputMaybe<Scalars['PositiveInt']['input']>;
  originalPayRate?: InputMaybe<Scalars['PositiveInt']['input']>;
  payRate?: InputMaybe<Scalars['PositiveInt']['input']>;
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  publishIn?: InputMaybe<Scalars['Minutes']['input']>;
  quantity: Scalars['PositiveInt']['input'];
  rateId?: InputMaybe<Scalars['ID']['input']>;
  shifts: Array<ShiftInput>;
  skillId: Scalars['ID']['input'];
  uniformId: Scalars['ID']['input'];
  uniformInstructions?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<VisibilityStatusEnum>;
};

/** Job offer */
export type JobOffer = {
  __typename: 'JobOffer';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  costRate: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  payRate: Scalars['Int']['output'];
  rejectedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  source: Maybe<Scalars['String']['output']>;
  surge: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

/** Autogenerated return type of JobOfferCreate */
export type JobOfferCreatePayload = {
  __typename: 'JobOfferCreatePayload';
  jobOffer: JobOffer;
};

/** Autogenerated return type of JobOfferGroupCreate */
export type JobOfferGroupCreatePayload = {
  __typename: 'JobOfferGroupCreatePayload';
  status: Scalars['Boolean']['output'];
};

/** A group of jobs for an order */
export type JobOffersGroup = {
  __typename: 'JobOffersGroup';
  createdAt: Scalars['ISO8601DateTime']['output'];
  firstPayRate: Scalars['PositiveInt']['output'];
  lastShiftEndAt: Scalars['ISO8601DateTime']['output'];
  maxEstimatedPay: Scalars['PositiveInt']['output'];
  maxMandatoryBreakTime: Scalars['PositiveInt']['output'];
  maxPayRate: Scalars['PositiveInt']['output'];
  maxWorkMinutes: Scalars['PositiveInt']['output'];
  minEstimatedPay: Scalars['PositiveInt']['output'];
  minMandatoryBreakTime: Scalars['PositiveInt']['output'];
  minPayRate: Scalars['PositiveInt']['output'];
  minWorkMinutes: Scalars['PositiveInt']['output'];
  nextJobId: Scalars['ID']['output'];
  nextJobOffer: JobOffer;
  nextShiftStartAt: Scalars['ISO8601DateTime']['output'];
  orderId: Scalars['ID']['output'];
  shiftCount: Scalars['PositiveInt']['output'];
  totalEstimatedPay: Scalars['PositiveInt']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workerId: Scalars['ID']['output'];
};

/** Pagination container for Job */
export type JobPage = {
  __typename: 'JobPage';
  items: Array<Job>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of JobPublish */
export type JobPublishPayload = {
  __typename: 'JobPublishPayload';
  job: Job;
};

/** Autogenerated return type of JobRemoveSubscriber */
export type JobRemoveSubscriberPayload = {
  __typename: 'JobRemoveSubscriberPayload';
  agency: Agency;
};

/** Job request */
export type JobRequest = {
  __typename: 'JobRequest';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  rejectedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  status: JobRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

/** Autogenerated return type of JobRequestAccept */
export type JobRequestAcceptPayload = {
  __typename: 'JobRequestAcceptPayload';
  job: Job;
  jobWorker: JobWorker;
};

/** Autogenerated return type of JobRequestReject */
export type JobRequestRejectPayload = {
  __typename: 'JobRequestRejectPayload';
  job: Job;
  jobRequest: JobRequest;
};

export enum JobRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum JobStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  UPCOMING = 'UPCOMING',
}

/** Tests field for equality to a given JobStatus */
export type JobStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: JobStatusEnum;
};

/** Job Subscriber */
export type JobSubscriber = {
  __typename: 'JobSubscriber';
  agency: Agency;
  agencyId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  jobId: Scalars['ID']['output'];
  order: Order;
  orderId: Scalars['ID']['output'];
  shift: Shift;
  shiftId: Scalars['ID']['output'];
  subscriberEmail: Scalars['String']['output'];
  tenantId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum JobTypeEnum {
  GIG = 'GIG',
  LTA = 'LTA',
}

/** Autogenerated return type of JobUnpublish */
export type JobUnpublishPayload = {
  __typename: 'JobUnpublishPayload';
  job: Job;
};

/** Autogenerated return type of JobUpdate */
export type JobUpdatePayload = {
  __typename: 'JobUpdatePayload';
  job: Job;
};

/** Job worker */
export type JobWorker = {
  __typename: 'JobWorker';
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  checkInStatus: JobWorkerCheckInStatusEnum;
  costRate: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  dismissReason: Maybe<Scalars['String']['output']>;
  dismissedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  dismissedBy: Maybe<User>;
  dropReason: Maybe<Scalars['String']['output']>;
  droppedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  job: Job;
  jobs: JobPage;
  noShowMarkedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  noShowMarkedBy: Maybe<User>;
  order: Order;
  payRate: Scalars['Int']['output'];
  paydate: Maybe<Scalars['ISO8601DateTime']['output']>;
  showed: Maybe<Scalars['Boolean']['output']>;
  status: JobWorkerStatusEnum;
  timesheets: Array<Timesheet>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

/** Job worker */
export type JobWorkerJobsArgs = {
  filters: InputMaybe<JobFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export enum JobWorkerCheckInStatusEnum {
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  NOT_CHECKED_IN = 'NOT_CHECKED_IN',
  NO_SHOW = 'NO_SHOW',
}

/** Available filter operations for a JobWorker */
export type JobWorkerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  breakMinutes?: InputMaybe<IntCompareFilterInput>;
  checkinAt?: InputMaybe<DateTimeCompareFilterInput>;
  checkoutAt?: InputMaybe<DateTimeCompareFilterInput>;
  costRate?: InputMaybe<IntCompareFilterInput>;
  currentJobs?: InputMaybe<BooleanEqualityFilterInput>;
  dismissed?: InputMaybe<Scalars['Boolean']['input']>;
  dropped?: InputMaybe<Scalars['Boolean']['input']>;
  firstShiftStartAt?: InputMaybe<DateTimeCompareFilterInput>;
  jobApplication?: InputMaybe<JobApplicationEqualityFilterInput>;
  jobStatus?: InputMaybe<JobStatusEqualityFilterInput>;
  lastShiftEndAt?: InputMaybe<DateTimeCompareFilterInput>;
  minutes?: InputMaybe<IntCompareFilterInput>;
  overtimeMinutes?: InputMaybe<IntCompareFilterInput>;
  payRate?: InputMaybe<IntCompareFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  rating?: InputMaybe<IntCompareFilterInput>;
  reportedEndAt?: InputMaybe<DateTimeCompareFilterInput>;
  reportedStartAt?: InputMaybe<DateTimeCompareFilterInput>;
  status?: InputMaybe<JobWorkerStatusEqualityFilterInput>;
  surge?: InputMaybe<BooleanEqualityFilterInput>;
  tipAmount?: InputMaybe<IntCompareFilterInput>;
};

/** Pagination container for JobWorker */
export type JobWorkerPage = {
  __typename: 'JobWorkerPage';
  items: Array<JobWorker>;
  pageInfo: PageInfo;
};

export enum JobWorkerStatusEnum {
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DISMISSED = 'DISMISSED',
  DROPPED = 'DROPPED',
  EXPIRED = 'EXPIRED',
  HIRED = 'HIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given JobStatus */
export type JobWorkerStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: JobWorkerStatusEnum;
};

/** Worker interview */
export type Jurisdiction = {
  __typename: 'Jurisdiction';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  state: Scalars['String']['output'];
  tenant: Tenant;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

/** A spatial multi-polygon */
export type MultiPolygon = {
  __typename: 'MultiPolygon';
  polygons: Array<Polygon>;
};

export type MultiPolygonInput = {
  polygons: Array<PolygonInput>;
};

/** The mutation root of this schema */
export type Mutation = {
  __typename: 'Mutation';
  /** Attach a bank account to a billing account */
  accountAttachBankAccount: AccountAttachBankAccountPayload;
  /** Attach a credit/debit card to a customer billing account */
  accountAttachCard: AccountAttachCardPayload;
  /** Create an account */
  accountCreate: AccountCreatePayload;
  /** Update account cut off duration */
  accountCutOffUpdate: AccountCutOffUpdatePayload;
  /** Detach a payment method from a customer billing account */
  accountDetachPaymentMethod: AccountDetachPaymentMethodPayload;
  /** Set the current payment method for a billing account */
  accountSetCurrentPaymentMethod: AccountSetCurrentPaymentMethodPayload;
  /** Update an account */
  accountUpdate: AccountUpdatePayload;
  /** Enable/Re-enable account */
  accountUpdateActive: AccountUpdateActivePayload;
  /** Create an address */
  addressCreate: AddressCreatePayload;
  /** Delete an address */
  addressDelete: AddressDeletePayload;
  /** Update an address */
  addressUpdate: AddressUpdatePayload;
  /** Validate an address and create Check Workplace if valid */
  addressValidate: AddressValidatePayload;
  /** Change the payment method for an invoice. For QA purposes. */
  adminChangePaymentMethod: AdminChangePaymentMethodPayload;
  /** Edit certificates of job */
  adminEditCertificatesOfJob: AdminEditCertificatesOfJobPayload;
  /** Edit number workers of a job posted */
  adminEditNumberWorkersJob: AdminEditNumberWorkersJobPayload;
  /** Edit pay/cost rate of a job posted */
  adminEditRateOfJob: AdminEditRateOfJobPayload;
  /** Edit date and time of a job posted */
  adminEditTimeOfJob: AdminEditTimeOfJobPayload;
  /** Authenticate either a tenant admin or a customer admin */
  adminLogin: AdminLoginPayload;
  /** Mark worker as showed. */
  adminMarkWorkerAsShowed: AdminMarkWorkerAsShowedPayload;
  /** Reset a worker password */
  adminResetWorkerPassword: Maybe<Scalars['Void']['output']>;
  /** Create an agency */
  agencyCreate: AgencyCreatePayload;
  /** Update an agency */
  agencyUpdate: AgencyUpdatePayload;
  /** Approve a timesheet and make calculations for invoice and payroll */
  approveMissedTimesheets: Maybe<Scalars['Void']['output']>;
  /** Create a new badge */
  badgeCreate: BadgeCreatePayload;
  /** Add a badge to a badgeable model */
  badgeableAddBadge: BadgeableAddBadgePayload;
  /** Change the badges on a badgeable model */
  badgeableChangeBadges: BadgeableChangeBadgesPayload;
  /** Remove a badge from a badgeable model */
  badgeableRemoveBadge: BadgeableRemoveBadgePayload;
  /** Create a chat group with GravyWork support staff */
  chatGroupSupportCreate: ChatGroupSupportCreatePayload;
  /** Create a chat service auth token */
  chatServiceAuthenticate: Scalars['String']['output'];
  /** Create a chat service auth token for agency support */
  chatServiceAuthenticateAgency: Scalars['String']['output'];
  /** Add a comment to a commentable target */
  commentableAddComment: CommentableAddCommentPayload;
  /** Create custom rates to all addresses from a customer by zip code. */
  customRateCreateBatch: CustomRateCreateBatchPayload;
  /** Change a customer admin's avatar */
  customerAdminChangeAvatar: CustomerAdminChangeAvatarPayload;
  /** Authenticate a customer admin */
  customerAdminLogin: CustomerAdminLoginPayload;
  /** Resend an invitation to an user who has not logged to be a customer admin */
  customerAdminResendInvitation: CustomerAdminResendInvitationPayload;
  /** Update a customer admin's profile */
  customerAdminUpdateProfile: CustomerAdminUpdateProfilePayload;
  /** Change a customer's avatar */
  customerChangeLogo: CustomerChangeLogoPayload;
  /** Create a customer */
  customerCreate: CustomerCreatePayload;
  /** Invite a customer admin */
  customerInviteAdmin: CustomerInviteAdminPayload;
  /** Create a worker relationship for this customer */
  customerRelateWorker: CustomerRelateWorkerPayload;
  /** Sign up a customer and send the invitation for its customer admin */
  customerSignup: CustomerSignupPayload;
  /** Delete a worker relationship for this customer */
  customerUnrelateWorker: Maybe<Scalars['Void']['output']>;
  /** Update a customer */
  customerUpdate: CustomerUpdatePayload;
  /** Update a customer job tax type */
  customerUpdateJobTaxType: CustomerUpdateJobTaxTypePayload;
  /** Create a rate */
  defaultRateCreate: DefaultRateCreatePayload;
  /** Update a default rate */
  defaultRateUpdate: DefaultRateUpdatePayload;
  /** Log out of an active session */
  deviceLogout: Maybe<Scalars['Void']['output']>;
  /** Log out from all sessions */
  deviceLogoutAll: Maybe<Scalars['Void']['output']>;
  /** Update the FCM token of current device */
  deviceUpdateFcmToken: Maybe<Scalars['Void']['output']>;
  /** Create an earning code */
  earningCodeCreate: Scalars['String']['output'];
  /** Generate invoices manually */
  invoiceGenerate: InvoiceGeneratePayload;
  /** Pay an Invoice */
  invoicePay: InvoicePayPayload;
  /** Create a pdf with invoice timesheets */
  invoiceTimesheetsPdf: InvoiceTimesheetsPdfPayload;
  /** Add a subscriber to a job */
  jobAddSubscriber: JobAddSubscriberPayload;
  /** Cancel job */
  jobCancel: JobCancelPayload;
  /** Cancel batch job */
  jobCancelBatch: JobCancelBatchPayload;
  /** Dismiss a worker from a job */
  jobDismissWorker: Maybe<Scalars['Void']['output']>;
  /** Hire a worker for a job */
  jobHireWorker: JobHireWorkerPayload;
  /** Create a job offer */
  jobOfferCreate: JobOfferCreatePayload;
  /** Create a group of job offer */
  jobOfferGroupCreate: JobOfferGroupCreatePayload;
  /** Post job to workers */
  jobPublish: JobPublishPayload;
  /** Add a subscriber to a job */
  jobRemoveSubscriber: JobRemoveSubscriberPayload;
  /** Accept a job request */
  jobRequestAccept: JobRequestAcceptPayload;
  /** Reject a job request */
  jobRequestReject: JobRequestRejectPayload;
  /** Remove job from workers board */
  jobUnpublish: JobUnpublishPayload;
  /** Update job details */
  jobUpdate: JobUpdatePayload;
  /** Create a new notification */
  notificationCreate: NotificationCreatePayload;
  /** Delete an existing notification */
  notificationDelete: Maybe<Scalars['Void']['output']>;
  /** Update an existing notification */
  notificationUpdate: NotificationUpdatePayload;
  /** Create an order */
  orderCreate: OrderCreatePayload;
  /** Attach a bank account to a customer admin */
  paymentBankAccountAttach: PaymentBankAccountAttachPayload;
  /** Detach a bank account from a customer admin */
  paymentBankAccountDetach: PaymentBankAccountDetachPayload;
  /** Updates the status of a payment intent to processing and saves the corresponding mandate record */
  paymentIntentAchProcessUpdate: Scalars['Void']['output'];
  /** Create a record of a payment intent to pay an invoice */
  paymentIntentCreate: PaymentIntentCreatePayload;
  /** Attach a credit/debit card to a customer admin */
  paymentMethodAttach: PaymentMethodAttachPayload;
  /** Save the current payment method */
  paymentMethodCurrent: PaymentMethodCurrentPayload;
  /** Detach a payment method from a customer admin */
  paymentMethodDetach: PaymentMethodDetachPayload;
  /** Approves a payroll. */
  payrollApprove: PayrollApprovePayload;
  /** Creates a payroll. */
  payrollCreate: PayrollCreatePayload;
  /** Deletes a payroll (You may delete payrolls only when they are in the draft status). */
  payrollDelete: Maybe<Scalars['Void']['output']>;
  /** Cancel a rate */
  rateCancel: RateCancelPayload;
  /** Create a rate (Deprecated) */
  rateCreate: RateCreatePayload;
  /** Delete a rate */
  rateDelete: RateDeletePayload;
  /** Update a rate */
  rateUpdate: RateUpdatePayload;
  /** Create a referral */
  referralCreate: ReferralCreatePayload;
  /** Send referrals and personalized emails */
  sendMail: Scalars['Void']['output'];
  /** Send checkin notification manually */
  sendManualCheckinNotification: Scalars['Void']['output'];
  /** Create a service area */
  serviceAreaCreate: ServiceAreaCreatePayload;
  /** Update a service area */
  serviceAreaUpdate: ServiceAreaUpdatePayload;
  /** Create a skill category */
  skillCategoryCreate: SkillCategoryCreatePayload;
  /** Update a skill category */
  skillCategoryUpdate: SkillCategoryUpdatePayload;
  /** Create a skill */
  skillCreate: SkillCreatePayload;
  /** Accept a skill request */
  skillRequestAccept: SkillRequestAcceptPayload;
  /** Reject a skill request */
  skillRequestReject: SkillRequestRejectPayload;
  /** Update a skill */
  skillUpdate: SkillUpdatePayload;
  /** Finalize an invoice to change status from Draft to Open */
  stripeInvoiceFinalize: StripeInvoiceFinalizePayload;
  /** Create a new invoice item for an invoice */
  stripeInvoiceItemCreate: StripeInvoiceItemCreatePayload;
  /** Deletes an invoice item */
  stripeInvoiceItemDelete: StripeInvoiceItemDeletePayload;
  /** Updates an invoice item */
  stripeInvoiceItemUpdate: StripeInvoiceItemUpdatePayload;
  /** Mark an invoice as paid manually */
  stripeInvoiceMarkAsPaid: StripeInvoiceMarkAsPaidPayload;
  /** Update a Draft Stripe invoice */
  stripeInvoiceUpdate: StripeInvoiceUpdatePayload;
  /** Mark a finalized invoice as void and recreate it without adding the manual added items */
  stripeInvoiceVoid: StripeInvoiceVoidPayload;
  /** Create GravyWork invoice again recreate it without adding the manual added items */
  stripeUnlinkInvoice: StripeUnlinkInvoicePayload;
  /** Create a new surge rate */
  surgeRatesCreate: SurgeRatesCreatePayload;
  /** Delete a Surge Rate */
  surgeRatesDelete: SurgeRatesDeletePayload;
  /** Create a new surge rate */
  surgeRatesUpdate: SurgeRatesUpdatePayload;
  /** Switch app_approve for invoice auto finish */
  switchAutoApprove: SwitchAutoApprovePayload;
  /** Create a new tag */
  tagCreate: TagCreatePayload;
  /** Add a tag to a taggable model */
  taggableAddTag: TaggableAddTagPayload;
  /** Change the tags on a taggable model */
  taggableChangeTags: TaggableChangeTagsPayload;
  /** Remove a tag from a taggable model */
  taggableRemoveTag: TaggableRemoveTagPayload;
  /** Change a tenant admin's avatar */
  tenantAdminChangeAvatar: TenantAdminChangeAvatarPayload;
  /** Invite a new tenant admin */
  tenantAdminInvite: TenantAdminInvitePayload;
  /** Resend an invitation to an user to be customer admin */
  tenantAdminResendInvitation: TenantAdminResendInvitationPayload;
  /** Update a tenant */
  tenantUpdate: TenantUpdatePayload;
  /** Approve a timesheet and make calculations for invoice and payroll */
  timesheetApprove: TimesheetApprovePayload;
  /** Create a new timesheet comment */
  timesheetCommentsAdd: TimesheetCommentsAddPayload;
  /** Create a timesheet from scratch */
  timesheetCreate: TimesheetCreatePayload;
  /** Reject a timesheet */
  timesheetReject: TimesheetRejectPayload;
  /** Update a timesheet tip */
  timesheetTipUpdate: TimesheetTipUpdatePayload;
  /** Unnaprove a timesheet and reset the approved time. */
  timesheetUnapprove: TimesheetUnapprovePayload;
  /** Unreject a timesheet */
  timesheetUnreject: TimesheetUnrejectPayload;
  /** Update a timesheet */
  timesheetUpdate: TimesheetUpdatePayload;
  /** Edit, Approve, Unapprove, Reject and Unreject the timesheets in bulk */
  timesheetsBulkEdit: TimesheetsBulkEditPayload;
  /** reset a password of an authenticated user */
  userAuthResetPassword: UserAuthResetPasswordPayload;
  /** Change an user's active status */
  userChangeActive: UserChangeActivePayload;
  /** Change current user password */
  userChangePassword: Maybe<Scalars['Void']['output']>;
  /** Saves the filters of the current user */
  userFilterSave: UserFilterSavePayload;
  /** Allow to reset a password of an user and receive it by email */
  userForgotPassword: Maybe<Scalars['Void']['output']>;
  /** Send a URL with a token to a user without validated email */
  userResendToken: Maybe<Scalars['Void']['output']>;
  /** Reset password of user matching the given token */
  userResetPassword: Maybe<Scalars['Void']['output']>;
  /** Update an user */
  userUpdate: UserUpdatePayload;
  /** Validates the address on check */
  validateAddress: ValidateAddressPayload;
  /** Add a certificate to a worker */
  workerAddCertificate: WorkerAddCertificatePayload;
  /** Create a worker reference */
  workerAddReference: WorkerAddReferencePayload;
  /** Save an array of work references */
  workerAddReferenceList: WorkerAddReferenceListPayload;
  /** Add a skill to a worker */
  workerAddSkill: WorkerAddSkillPayload;
  /** Manually approve a worker's I9 form. For QA purposes. */
  workerApproveFormI9: WorkerApproveFormI9Payload;
  /** Assign a job workplace to a worker */
  workerAssignJobWorkplace: WorkerAssignJobWorkplacePayload;
  /** Update a worker's active status */
  workerChangeActive: WorkerChangeActivePayload;
  /** Change a worker's address */
  workerChangeAddress: WorkerChangeAddressPayload;
  /** Change a worker's avatar */
  workerChangeAvatar: WorkerChangeAvatarPayload;
  /** Manually mark closed I9 form for a worker. For QA purposes. */
  workerClosedFormI9: WorkerClosedFormI9Payload;
  /** Create a worker */
  workerCreate: WorkerCreatePayload;
  /** Create an interview at Willo to a worker */
  workerCreateWilloInterview: WorkerCreateWilloInterviewPayload;
  /** Disable a worker is available by tenant admin */
  workerDisable: WorkerDisablePayload;
  /** Manually disapprove a worker's I9 form. For QA purposes. */
  workerDisapproveFormI9: WorkerDisapproveFormI9Payload;
  /** Enable a worker is available by tenant admin */
  workerEnable: WorkerEnablePayload;
  /** Create an interview for an applicant worker */
  workerInterviewCreate: WorkerInterviewCreatePayload;
  /** Update an interview for an applicant worker */
  workerInterviewUpdate: WorkerInterviewUpdatePayload;
  /** Evaluate an interview (Video Questionnaire) for an applicant worker */
  workerInterviewUpdateV2: WorkerInterviewUpdateV2Payload;
  /** Authenticate a worker */
  workerLogin: WorkerLoginPayload;
  /** Worker Onboard */
  workerOnboard: WorkerOnboardPayload;
  /** Set prospect status to a worker's I9 form. For QA purposes */
  workerProspectFormI9: WorkerProspectFormI9Payload;
  /** Remove a certificate from a worker */
  workerRemoveCertificate: WorkerRemoveCertificatePayload;
  /** Remove a worker reference */
  workerRemoveReference: Maybe<Scalars['Void']['output']>;
  /** Remove a skill from a worker */
  workerRemoveSkill: WorkerRemoveSkillPayload;
  /** Resend a Checkr invitation to a worker */
  workerResendCheckrInvitation: WorkerResendCheckrInvitationPayload;
  /** Reset worker verification status */
  workerResetVerification: WorkerResetVerificationPayload;
  /** Worker self signup */
  workerSignup: WorkerSignupPayload;
  /** Manually mark as TENTATIVE_NC I9 form for a worker. For QA purposes. */
  workerTentativeNcFormI9: WorkerTentativeNcFormI9Payload;
  /** Update an applicant worker */
  workerUpdateApplicant: WorkerUpdateApplicantPayload;
  /** Update a worker's background check status */
  workerUpdateBackgroundCheck: WorkerUpdateBackgroundCheckPayload;
  /** Update a candidate worker */
  workerUpdateCandidate: WorkerUpdateCandidatePayload;
  /** Modify the onboarding status if the worker scheduled an interview */
  workerUpdateCheckInterview: WorkerUpdateCheckInterviewPayload;
  /** Update a worker's profile */
  workerUpdateProfile: WorkerUpdateProfilePayload;
  /** Update worker's rating */
  workerUpdateRating: WorkerUpdateRatingPayload;
  /** Update a worker's status */
  workerUpdateStatus: WorkerUpdateStatusPayload;
  /** Update a worker's tax type */
  workerUpdateTaxType: WorkerUpdateTaxTypePayload;
  /** Modify the onboarding status if the worker completed their interview */
  workerUpdateWilloInterview: WorkerUpdateWilloInterviewPayload;
  /** Verify worker mobile code */
  workerVerify: WorkerVerifyPayload;
};

/** The mutation root of this schema */
export type MutationAccountAttachBankAccountArgs = {
  accountId: Scalars['ID']['input'];
  bankAccountId: Scalars['ID']['input'];
  publicToken: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAccountAttachCardArgs = {
  accountId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAccountCreateArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  billingWeek: InputMaybe<BillingWeekEnum>;
  city: Scalars['String']['input'];
  consolidateUnprocessedCharges: InputMaybe<Scalars['Boolean']['input']>;
  customerId: Scalars['ID']['input'];
  cutOff: InputMaybe<InvoiceCutOffEnum>;
  defaultContactId: Scalars['ID']['input'];
  groupBy: InputMaybe<InvoiceGroupByEnum>;
  markupPercent?: InputMaybe<Scalars['Float']['input']>;
  monthlyCutOff: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  rateType: AccountRateTypeEnum;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAccountCutOffUpdateArgs = {
  accountId: Scalars['ID']['input'];
  billingWeek: InputMaybe<BillingWeekEnum>;
  consolidateUnprocessedCharges: InputMaybe<Scalars['Boolean']['input']>;
  cutOff: InputMaybe<InvoiceCutOffEnum>;
  groupBy: InputMaybe<InvoiceGroupByEnum>;
  monthlyCutOff: InputMaybe<Scalars['Boolean']['input']>;
};

/** The mutation root of this schema */
export type MutationAccountDetachPaymentMethodArgs = {
  accountId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
  paymentMethodType: PaymentMethodEnum;
};

/** The mutation root of this schema */
export type MutationAccountSetCurrentPaymentMethodArgs = {
  accountId: Scalars['ID']['input'];
  paymentMethodId: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationAccountUpdateArgs = {
  accountId: Scalars['ID']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  defaultContactId: Scalars['ID']['input'];
  markupPercent: InputMaybe<Scalars['Float']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAccountUpdateActiveArgs = {
  accountId: Scalars['ID']['input'];
  active: Scalars['Boolean']['input'];
};

/** The mutation root of this schema */
export type MutationAddressCreateArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  coords: PointInput;
  customerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  neighborhood: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAddressDeleteArgs = {
  addressId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAddressUpdateArgs = {
  addressId: Scalars['ID']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  coords: PointInput;
  name: Scalars['String']['input'];
  neighborhood: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAddressValidateArgs = {
  addressId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAdminChangePaymentMethodArgs = {
  invoiceId: Scalars['ID']['input'];
  paymentMethodToken: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationAdminEditCertificatesOfJobArgs = {
  certificateIds: Array<Scalars['ID']['input']>;
  jobId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAdminEditNumberWorkersJobArgs = {
  jobId: Scalars['ID']['input'];
  numberWorkers: Scalars['Int']['input'];
};

/** The mutation root of this schema */
export type MutationAdminEditRateOfJobArgs = {
  costRate: InputMaybe<Scalars['PositiveInt']['input']>;
  isRateLock?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  markupPercent?: InputMaybe<Scalars['Float']['input']>;
  originalCostRate: InputMaybe<Scalars['PositiveInt']['input']>;
  originalPayRate: InputMaybe<Scalars['PositiveInt']['input']>;
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** The mutation root of this schema */
export type MutationAdminEditTimeOfJobArgs = {
  firstShiftStartAt: Scalars['ISO8601DateTime']['input'];
  jobId: Scalars['ID']['input'];
  lastShiftEndAt: Scalars['ISO8601DateTime']['input'];
  mandatoryBreakTime: InputMaybe<Scalars['Minutes']['input']>;
  timeZoneOffset: InputMaybe<Scalars['Float']['input']>;
};

/** The mutation root of this schema */
export type MutationAdminLoginArgs = {
  email: Scalars['Email']['input'];
  fcmToken: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAdminMarkWorkerAsShowedArgs = {
  jobWorkerId: Scalars['ID']['input'];
  showed: Scalars['Boolean']['input'];
};

/** The mutation root of this schema */
export type MutationAdminResetWorkerPasswordArgs = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAgencyCreateArgs = {
  key: Scalars['Key']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationAgencyUpdateArgs = {
  agencyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationApproveMissedTimesheetsArgs = {
  maxCheckin: Scalars['ISO8601Date']['input'];
  minCheckin: Scalars['ISO8601Date']['input'];
};

/** The mutation root of this schema */
export type MutationBadgeCreateArgs = {
  agencyId: Scalars['ID']['input'];
  category: InputMaybe<BadgeCategoryEnum>;
  description: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  points: InputMaybe<Scalars['Int']['input']>;
};

/** The mutation root of this schema */
export type MutationBadgeableAddBadgeArgs = {
  badgeableId: Scalars['ID']['input'];
  badgeableType: BadgeableEnum;
  tagId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationBadgeableChangeBadgesArgs = {
  addBadgeIds: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  badgeableId: Scalars['ID']['input'];
  badgeableType: BadgeableEnum;
  removeBadgeIds: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
};

/** The mutation root of this schema */
export type MutationBadgeableRemoveBadgeArgs = {
  badgeId: Scalars['ID']['input'];
  badgeableId: Scalars['ID']['input'];
  badgeableType: BadgeableEnum;
};

/** The mutation root of this schema */
export type MutationChatGroupSupportCreateArgs = {
  chatUid: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationChatServiceAuthenticateAgencyArgs = {
  agencyId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationCommentableAddCommentArgs = {
  body: Scalars['String']['input'];
  commentableId: Scalars['ID']['input'];
  commentableType: CommentableEnum;
};

/** The mutation root of this schema */
export type MutationCustomRateCreateBatchArgs = {
  accountId: Scalars['ID']['input'];
  agencyId: Scalars['ID']['input'];
  baseCost: Scalars['PositiveInt']['input'];
  basePay: Scalars['PositiveInt']['input'];
  level: Scalars['PositiveInt']['input'];
  serviceAreaId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminChangeAvatarArgs = {
  avatarKey: Scalars['String']['input'];
  customerAdminId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminLoginArgs = {
  customerId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  fcmToken: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminResendInvitationArgs = {
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminUpdateProfileArgs = {
  customerAdminId: Scalars['ID']['input'];
  department: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['Email']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationCustomerChangeLogoArgs = {
  customerId: Scalars['ID']['input'];
  logoKey: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerCreateArgs = {
  agencyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerInviteAdminArgs = {
  customerId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerRelateWorkerArgs = {
  comments: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  kind: RelationshipKindEnum;
  reason: InputMaybe<Scalars['String']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerSignupArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  agencyId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  coords: PointInput;
  customerName: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  neighborhood: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  referral: InputMaybe<ReferralEnum>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerUnrelateWorkerArgs = {
  customerId: Scalars['ID']['input'];
  kind: InputMaybe<RelationshipKindEnum>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerUpdateArgs = {
  certificateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  checkInRadiusFeet: InputMaybe<Scalars['Int']['input']>;
  customerId: Scalars['ID']['input'];
  invoiceDueDate: InputMaybe<DefaultDueDateEnum>;
  jobTaxType: InputMaybe<TaxTypeEnum>;
  ltaAllowed: InputMaybe<Scalars['Boolean']['input']>;
  markupPercent?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationCustomerUpdateJobTaxTypeArgs = {
  customerId: Scalars['ID']['input'];
  jobTaxType: TaxTypeEnum;
};

/** The mutation root of this schema */
export type MutationDefaultRateCreateArgs = {
  costRate: InputMaybe<Scalars['PositiveInt']['input']>;
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
  skillId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  zip: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationDefaultRateUpdateArgs = {
  costRate: InputMaybe<Scalars['PositiveInt']['input']>;
  defaultRate: Scalars['ID']['input'];
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** The mutation root of this schema */
export type MutationDeviceLogoutAllArgs = {
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** The mutation root of this schema */
export type MutationDeviceUpdateFcmTokenArgs = {
  fcmToken: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationEarningCodeCreateArgs = {
  name: Scalars['String']['input'];
  skillId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationInvoiceGenerateArgs = {
  accountId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
  timesheetIds: Array<Scalars['ID']['input']>;
};

/** The mutation root of this schema */
export type MutationInvoicePayArgs = {
  invoice: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationInvoiceTimesheetsPdfArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobAddSubscriberArgs = {
  agencyId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  shiftId: Scalars['ID']['input'];
  subscriberEmail: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationJobCancelArgs = {
  jobId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobCancelBatchArgs = {
  orderId: Scalars['ID']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationJobDismissWorkerArgs = {
  jobWorkerId: Scalars['ID']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationJobHireWorkerArgs = {
  forceHire?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobOfferCreateArgs = {
  costRate: InputMaybe<Scalars['PositiveInt']['input']>;
  forceInvite?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobOfferGroupCreateArgs = {
  costRate: InputMaybe<Scalars['PositiveInt']['input']>;
  jobIds: Array<Scalars['ID']['input']>;
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
  workerIds: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The mutation root of this schema */
export type MutationJobPublishArgs = {
  jobId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobRemoveSubscriberArgs = {
  agencyId: Scalars['ID']['input'];
  jobSubscriberId: Scalars['Int']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobRequestAcceptArgs = {
  jobRequestId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobRequestRejectArgs = {
  jobRequestId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobUnpublishArgs = {
  jobId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationJobUpdateArgs = {
  addressId: Scalars['ID']['input'];
  addressInstructions: Scalars['String']['input'];
  contactId: Scalars['ID']['input'];
  contactInstructions: Scalars['String']['input'];
  instructions: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
  uniformId: Scalars['ID']['input'];
  uniformInstructions: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationNotificationCreateArgs = {
  link: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  notificationType: NotificationTypeEnum;
  publishAt: Scalars['ISO8601DateTime']['input'];
  title: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationNotificationDeleteArgs = {
  notificationId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationNotificationUpdateArgs = {
  link: InputMaybe<Scalars['String']['input']>;
  message: InputMaybe<Scalars['String']['input']>;
  notificationId: Scalars['ID']['input'];
  notificationType: InputMaybe<NotificationTypeEnum>;
  publishAt: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationOrderCreateArgs = {
  accountId: Scalars['ID']['input'];
  jobs: Array<JobInput>;
};

/** The mutation root of this schema */
export type MutationPaymentBankAccountAttachArgs = {
  accountId: Scalars['ID']['input'];
  customerAdminId: Scalars['ID']['input'];
  publicToken: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationPaymentBankAccountDetachArgs = {
  bankAccountId: Scalars['ID']['input'];
  customerAdminId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationPaymentIntentAchProcessUpdateArgs = {
  clientSecret: Scalars['String']['input'];
  invoiceId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationPaymentIntentCreateArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationPaymentMethodAttachArgs = {
  customerAdminId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationPaymentMethodCurrentArgs = {
  customerAdminId: Scalars['ID']['input'];
  paymentMethodId: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationPaymentMethodDetachArgs = {
  customerAdminId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationPayrollApproveArgs = {
  payrollId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationPayrollCreateArgs = {
  payday: InputMaybe<Scalars['ISO8601Date']['input']>;
  weekDay: Scalars['ISO8601Date']['input'];
};

/** The mutation root of this schema */
export type MutationPayrollDeleteArgs = {
  payrollId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationRateCancelArgs = {
  rateId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationRateCreateArgs = {
  accountId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
  agencyId: Scalars['ID']['input'];
  baseCost: Scalars['PositiveInt']['input'];
  basePay: Scalars['PositiveInt']['input'];
  level: Scalars['PositiveInt']['input'];
  serviceAreaId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationRateDeleteArgs = {
  rateId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationRateUpdateArgs = {
  baseCost: InputMaybe<Scalars['PositiveInt']['input']>;
  basePay: InputMaybe<Scalars['PositiveInt']['input']>;
  rateId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationReferralCreateArgs = {
  prospectEmail: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSendMailArgs = {
  action: EmailActionEnum;
  body: InputMaybe<Scalars['String']['input']>;
  emails: Array<Scalars['Email']['input']>;
  link: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationSendManualCheckinNotificationArgs = {
  jobWorkerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationServiceAreaCreateArgs = {
  agencyId: Scalars['ID']['input'];
  geometry: MultiPolygonInput;
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationServiceAreaUpdateArgs = {
  name: Scalars['String']['input'];
  serviceAreaId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSkillCategoryCreateArgs = {
  agencyId: Scalars['ID']['input'];
  iconUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationSkillCategoryUpdateArgs = {
  name: Scalars['String']['input'];
  skillCategoryId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSkillCreateArgs = {
  iconUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  skillCategoryId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSkillRequestAcceptArgs = {
  skillRequestId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSkillRequestRejectArgs = {
  skillRequestId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSkillUpdateArgs = {
  name: Scalars['String']['input'];
  skillId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceFinalizeArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemCreateArgs = {
  amount: Scalars['String']['input'];
  description: Scalars['String']['input'];
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemDeleteArgs = {
  invoiceItemId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemUpdateArgs = {
  amount: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  invoiceItemId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceMarkAsPaidArgs = {
  invoiceId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceUpdateArgs = {
  description: InputMaybe<Scalars['String']['input']>;
  dueDate: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  invoiceId: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceVoidArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationStripeUnlinkInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesCreateArgs = {
  date: Scalars['ISO8601DateTime']['input'];
  description: Scalars['String']['input'];
  rate: Scalars['PositiveInt']['input'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesDeleteArgs = {
  surgeRateId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesUpdateArgs = {
  date: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  rate: InputMaybe<Scalars['PositiveInt']['input']>;
  surgeRateId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationSwitchAutoApproveArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTagCreateArgs = {
  agencyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationTaggableAddTagArgs = {
  tagId: Scalars['ID']['input'];
  taggableId: Scalars['ID']['input'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTaggableChangeTagsArgs = {
  addTagIds: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  removeTagIds: InputMaybe<Array<Scalars['PositiveInt']['input']>>;
  taggableId: Scalars['ID']['input'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTaggableRemoveTagArgs = {
  tagId: Scalars['ID']['input'];
  taggableId: Scalars['ID']['input'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTenantAdminChangeAvatarArgs = {
  avatarKey: Scalars['String']['input'];
  tenantAdminId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTenantAdminInviteArgs = {
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  roleId: UserRoleEnum;
  tenantId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTenantAdminResendInvitationArgs = {
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTenantUpdateArgs = {
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetApproveArgs = {
  approvedBreakMinutes: Scalars['NonNegativeInt']['input'];
  approvedCheckinAt: Scalars['ISO8601DateTime']['input'];
  approvedCheckoutAt: Scalars['ISO8601DateTime']['input'];
  ratingComment: InputMaybe<Scalars['String']['input']>;
  timesheetId: Scalars['ID']['input'];
  tipAmount: InputMaybe<Scalars['NonNegativeFloat']['input']>;
};

/** The mutation root of this schema */
export type MutationTimesheetCommentsAddArgs = {
  body: Scalars['String']['input'];
  timesheetId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetCreateArgs = {
  jobId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetRejectArgs = {
  ratingComment: InputMaybe<Scalars['String']['input']>;
  timesheetId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetTipUpdateArgs = {
  timesheetId: Scalars['ID']['input'];
  tipAmount: Scalars['NonNegativeFloat']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetUnapproveArgs = {
  timesheetId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetUnrejectArgs = {
  timesheetId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationTimesheetUpdateArgs = {
  ratingComment: InputMaybe<Scalars['String']['input']>;
  reportedBreakMinutes: Scalars['NonNegativeInt']['input'];
  reportedCheckinAt: Scalars['ISO8601DateTime']['input'];
  reportedCheckoutAt: Scalars['ISO8601DateTime']['input'];
  timesheetId: Scalars['ID']['input'];
  tipAmount: InputMaybe<Scalars['NonNegativeFloat']['input']>;
};

/** The mutation root of this schema */
export type MutationTimesheetsBulkEditArgs = {
  action: TimesheetsBulkEditActionEnum;
  jobId: Scalars['ID']['input'];
  jobWorkerIds: Array<Scalars['ID']['input']>;
  timesheetsInfo: InputMaybe<BulkEditTimesheetsInput>;
};

/** The mutation root of this schema */
export type MutationUserAuthResetPasswordArgs = {
  password: Scalars['String']['input'];
  repeatedPassword: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationUserChangeActiveArgs = {
  active: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationUserChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationUserFilterSaveArgs = {
  filterType: UserFilterTypeEnum;
  filterValue: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationUserForgotPasswordArgs = {
  app: TypeAppEnum;
  email: Scalars['Email']['input'];
  platform: InputMaybe<SmartphoneTypeEnum>;
};

/** The mutation root of this schema */
export type MutationUserResendTokenArgs = {
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationUserResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationUserUpdateArgs = {
  email: InputMaybe<Scalars['Email']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  roleId: UserRoleEnum;
  userId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationValidateAddressArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  agencyId: InputMaybe<Scalars['ID']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerAddCertificateArgs = {
  certificateId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerAddReferenceArgs = {
  city: Scalars['String']['input'];
  duration: DurationEnum;
  email: InputMaybe<Scalars['Email']['input']>;
  employerName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  summary: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerAddReferenceListArgs = {
  references: Array<ReferenceInput>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerAddSkillArgs = {
  skillId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerApproveFormI9Args = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerAssignJobWorkplaceArgs = {
  jobId: Scalars['ID']['input'];
  workerId: InputMaybe<Scalars['ID']['input']>;
};

/** The mutation root of this schema */
export type MutationWorkerChangeActiveArgs = {
  active: Scalars['Boolean']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerChangeAddressArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  coords: PointInput;
  state: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerChangeAvatarArgs = {
  avatarKey: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerClosedFormI9Args = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerCreateArgs = {
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  agencyId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  coords: PointInput;
  dateOfBirth: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  gender: GenderEnum;
  language: LanguageEnum;
  lastName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  ownsCar: Scalars['Boolean']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  skillIds: InputMaybe<Array<Scalars['ID']['input']>>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerDisableArgs = {
  reason: InputMaybe<Scalars['String']['input']>;
  reenableDate: InputMaybe<Scalars['ISO8601Date']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerDisapproveFormI9Args = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerEnableArgs = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewCreateArgs = {
  answers: Array<InterviewAnswerInput>;
  date: Scalars['ISO8601DateTime']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewUpdateArgs = {
  answers: InputMaybe<Array<InterviewAnswerInput>>;
  date: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  status: InputMaybe<InterviewStatusEnum>;
  workerInterviewId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewUpdateV2Args = {
  status: InterviewStatusEnum;
  willoWorkerInterviewId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerLoginArgs = {
  agencyId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  fcmToken: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerOnboardArgs = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerProspectFormI9Args = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveCertificateArgs = {
  certificateId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveReferenceArgs = {
  workerReferenceId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveSkillArgs = {
  skillId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerResendCheckrInvitationArgs = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerResetVerificationArgs = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerSignupArgs = {
  agencyId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  fcmToken: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  language: LanguageEnum;
  lastName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  zipCode: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationWorkerTentativeNcFormI9Args = {
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateApplicantArgs = {
  addressLine1: InputMaybe<Scalars['String']['input']>;
  addressLine2: InputMaybe<Scalars['String']['input']>;
  candidateStatus: CandidateStatusEnum;
  city: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: InputMaybe<Scalars['DateOrEmptyString']['input']>;
  email: InputMaybe<Scalars['Email']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
  language: LanguageEnum;
  lastName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  ownsCar: Scalars['Boolean']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  skillIds: Array<Scalars['ID']['input']>;
  smartphoneType: SmartphoneTypeEnum;
  state: InputMaybe<Scalars['String']['input']>;
  taxType: TaxTypeEnum;
  usCitizenOrWorkPermit: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
  zip: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationWorkerUpdateBackgroundCheckArgs = {
  backgroundStatus: BackgroundStatusEnum;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateCandidateArgs = {
  addressLine1: InputMaybe<Scalars['String']['input']>;
  addressLine2: InputMaybe<Scalars['String']['input']>;
  candidateStatus: CandidateStatusEnum;
  city: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  language: InputMaybe<LanguageEnum>;
  lastName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  smartphoneType: InputMaybe<SmartphoneTypeEnum>;
  state: InputMaybe<Scalars['String']['input']>;
  workerId: Scalars['ID']['input'];
  zip: InputMaybe<Scalars['String']['input']>;
};

/** The mutation root of this schema */
export type MutationWorkerUpdateCheckInterviewArgs = {
  email: Scalars['Email']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateProfileArgs = {
  dateOfBirth: Scalars['ISO8601DateTime']['input'];
  email: InputMaybe<Scalars['Email']['input']>;
  firstName: Scalars['String']['input'];
  gender: InputMaybe<GenderEnum>;
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
  heardFrom: InputMaybe<Scalars['String']['input']>;
  language: InputMaybe<LanguageEnum>;
  lastName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  ownsCar: InputMaybe<Scalars['Boolean']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  smartphoneType: InputMaybe<SmartphoneTypeEnum>;
  usCitizenOrWorkPermit: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateRatingArgs = {
  body: InputMaybe<Scalars['String']['input']>;
  rating: InputMaybe<Scalars['Rating']['input']>;
  timesheetId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateStatusArgs = {
  i9Status: InputMaybe<I9StatusEnum>;
  interviewStatus: InputMaybe<InterviewStatusEnum>;
  onboardingStatus: InputMaybe<OnboardingStatusEnum>;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateTaxTypeArgs = {
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
  taxType: TaxTypeEnum;
  workerId: Scalars['ID']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateWilloInterviewArgs = {
  email: Scalars['Email']['input'];
};

/** The mutation root of this schema */
export type MutationWorkerVerifyArgs = {
  code: Scalars['String']['input'];
};

export enum NeedsApprovalEnum {
  ALL = 'ALL',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
}

/** Notification Type */
export type Notification = {
  __typename: 'Notification';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  link: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  notificationType: NotificationTypeEnum;
  publishAt: Scalars['ISO8601DateTime']['output'];
  status: NotificationStatusEnum;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of NotificationCreate */
export type NotificationCreatePayload = {
  __typename: 'NotificationCreatePayload';
  notification: Notification;
};

/** Pagination container for Notification */
export type NotificationPage = {
  __typename: 'NotificationPage';
  items: Array<Notification>;
  pageInfo: PageInfo;
};

export enum NotificationStatusEnum {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
}

export enum NotificationTypeEnum {
  INFORMATION = 'INFORMATION',
  RELEASE_NOTES = 'RELEASE_NOTES',
  SYSTEM_UPDATE = 'SYSTEM_UPDATE',
}

/** Autogenerated return type of NotificationUpdate */
export type NotificationUpdatePayload = {
  __typename: 'NotificationUpdatePayload';
  notification: Notification;
};

export enum OnboardingStatusEnum {
  DEFAULT_STATUS = 'DEFAULT_STATUS',
  INTERVIEW_DECLINED = 'INTERVIEW_DECLINED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_REQUESTED = 'INTERVIEW_REQUESTED',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  ONBOARDING_IN_PROGRESS = 'ONBOARDING_IN_PROGRESS',
  PENDING_BACKGROUND_CHECK = 'PENDING_BACKGROUND_CHECK',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
  PENDING_FORMI9 = 'PENDING_FORMI9',
  PENDING_PAYMENT_METHOD = 'PENDING_PAYMENT_METHOD',
  PENDING_PHONE_VERIFY = 'PENDING_PHONE_VERIFY',
  PENDING_PROFILE_COMPLETITION = 'PENDING_PROFILE_COMPLETITION',
  PENDING_SSN = 'PENDING_SSN',
  PENDING_TAX_WITHHOLDING = 'PENDING_TAX_WITHHOLDING',
  PENDING_WORKPLACE = 'PENDING_WORKPLACE',
}

/** Order */
export type Order = {
  __typename: 'Order';
  account: Account;
  agency: Agency;
  cancelledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  endAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  jobs: Array<Job>;
  jobsCount: Scalars['PositiveInt']['output'];
  markupPercent: Maybe<Scalars['Float']['output']>;
  startAt: Scalars['ISO8601DateTime']['output'];
  status: OrderStatusEnum;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of OrderCreate */
export type OrderCreatePayload = {
  __typename: 'OrderCreatePayload';
  order: Order;
  workersHired: Maybe<Scalars['String']['output']>;
};

/** Available filter operations for a Order */
export type OrderFilterSetInput = {
  account?: InputMaybe<IdEqualityFilterInput>;
  agency?: InputMaybe<IdEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  customer?: InputMaybe<IdEqualityFilterInput>;
  endAt?: InputMaybe<DateTimeCompareFilterInput>;
  jobsCount?: InputMaybe<IntCompareFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  startAt?: InputMaybe<DateTimeCompareFilterInput>;
  status?: InputMaybe<OrderStatusEqualityFilterInput>;
  tags?: InputMaybe<IdMembershipFilterInput>;
  uuid?: InputMaybe<TextSearchFilterInput>;
};

/** Pagination container for Order */
export type OrderPage = {
  __typename: 'OrderPage';
  items: Array<Order>;
  pageInfo: PageInfo;
};

export enum OrderSortKeyEnum {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  JOBS_COUNT = 'JOBS_COUNT',
  START_AT = 'START_AT',
}

export enum OrderStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NO_SHOW = 'NO_SHOW',
  NO_TIMESHEET = 'NO_TIMESHEET',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TIMESHEET_UNFINISHED = 'TIMESHEET_UNFINISHED',
}

/** Field for equality to a given Order Status */
export type OrderStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: OrderStatusEnum;
};

/** Orientation */
export type Orientation = {
  __typename: 'Orientation';
  agency: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  endAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  seats: Scalars['Int']['output'];
  seatsRemaining: Scalars['Int']['output'];
  site: Maybe<OrientationSite>;
  startAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Orientation site */
export type OrientationSite = {
  __typename: 'OrientationSite';
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  agency: Maybe<Agency>;
  city: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  maxSeats: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  orientations: Array<Orientation>;
  state: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip: Maybe<Scalars['String']['output']>;
};

/** Page metadata */
export type PageInfo = {
  __typename: 'PageInfo';
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

/** Autogenerated return type of PaymentBankAccountAttach */
export type PaymentBankAccountAttachPayload = {
  __typename: 'PaymentBankAccountAttachPayload';
  attach: Scalars['Boolean']['output'];
};

/** Autogenerated return type of PaymentBankAccountDetach */
export type PaymentBankAccountDetachPayload = {
  __typename: 'PaymentBankAccountDetachPayload';
  detach: Scalars['Boolean']['output'];
};

/** Autogenerated return type of PaymentIntentCreate */
export type PaymentIntentCreatePayload = {
  __typename: 'PaymentIntentCreatePayload';
  clientSecret: Scalars['String']['output'];
};

/** Autogenerated return type of PaymentMethodAttach */
export type PaymentMethodAttachPayload = {
  __typename: 'PaymentMethodAttachPayload';
  attach: Scalars['Boolean']['output'];
};

/** Autogenerated return type of PaymentMethodCurrent */
export type PaymentMethodCurrentPayload = {
  __typename: 'PaymentMethodCurrentPayload';
  transaction: Scalars['Boolean']['output'];
};

/** Autogenerated return type of PaymentMethodDetach */
export type PaymentMethodDetachPayload = {
  __typename: 'PaymentMethodDetachPayload';
  detach: Scalars['Boolean']['output'];
};

export enum PaymentMethodEnum {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD',
}

/** Current payment methods in stripe */
export type PaymentMethodInfo = {
  __typename: 'PaymentMethodInfo';
  bankAccounts: Array<StripeBankAccount>;
  cards: Array<StripePaymentMethod>;
  currentPaymentMethod: Maybe<Scalars['String']['output']>;
};

/** Represents a company 's pay obligations for a payroll period. */
export type Payroll = {
  __typename: 'Payroll';
  /** Datetime by which the payroll must be approved. */
  approvalDeadline: Scalars['ISO8601DateTime']['output'];
  /** Datetime at which the payroll was approved. */
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  checkPayrollId: Scalars['String']['output'];
  contractorPayments: Maybe<ContractorPaymentPage>;
  id: Scalars['ID']['output'];
  /** Frequency by which employees get paid. */
  payFrequency: Scalars['String']['output'];
  /** Date on which employees will be paid for the payroll. */
  payday: Scalars['ISO8601Date']['output'];
  payrollItems: Maybe<PayrollItemPage>;
  /** Indicates whether the payroll is a regular or off-cycle payroll. */
  payrollType: Scalars['String']['output'];
  /** End of the date range that the payroll covers. */
  periodEnd: Scalars['ISO8601Date']['output'];
  /** Start of the date range that the payroll covers. */
  periodStart: Scalars['ISO8601Date']['output'];
  /** Status of the payroll in its lifecycle. */
  status: PayrollStatusEnum;
  totals: PayrollTotals;
};

/** Represents a company 's pay obligations for a payroll period. */
export type PayrollContractorPaymentsArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Represents a company 's pay obligations for a payroll period. */
export type PayrollPayrollItemsArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Autogenerated return type of PayrollApprove */
export type PayrollApprovePayload = {
  __typename: 'PayrollApprovePayload';
  payroll: Payroll;
};

/** Autogenerated return type of PayrollCreate */
export type PayrollCreatePayload = {
  __typename: 'PayrollCreatePayload';
  payroll: Payroll;
};

/** Represents a payment to an employee from a company. */
export type PayrollItem = {
  __typename: 'PayrollItem';
  checkPayrollItemId: Scalars['String']['output'];
  deductions: Maybe<Array<PostTaxDeduction>>;
  earnings: Array<PayrollItemEarning>;
  id: Scalars['ID']['output'];
  /** The total amount of net pay earned by the employee for this payroll item. */
  netPay: Maybe<Scalars['String']['output']>;
  /** May be manual or direct_deposit if the employee has a linked bank account. */
  paymentMethod: Scalars['String']['output'];
  payroll: Payroll;
  /** Status of the payroll item. */
  status: PayrollItemStatusEnum;
  taxes: Array<PayrollItemTax>;
  /** Worker associated with the payroll item. */
  worker: Maybe<Worker>;
};

/** Represent the set of earning object associated with the payroll item. */
export type PayrollItemEarning = {
  __typename: 'PayrollItemEarning';
  /** Address where work for this earning was performed. */
  address: Maybe<Address>;
  /** Amount earned for this earning. */
  amount: Scalars['String']['output'];
  /** Readable description of the earning code that will show up on the paystub. */
  description: Maybe<Scalars['String']['output']>;
  /** Type of earning. */
  earningType: PayrollItemEarningEnum;
  /** Number of hours worked for this earning */
  hours: Maybe<Scalars['Float']['output']>;
  payrollItem: Maybe<PayrollItem>;
  timesheet: Maybe<Timesheet>;
};

export enum PayrollItemEarningEnum {
  BONUS = 'BONUS',
  CASH_TIPS = 'CASH_TIPS',
  COMMISSION = 'COMMISSION',
  GROUP_TERM_LIFE = 'GROUP_TERM_LIFE',
  HOURLY = 'HOURLY',
  OTHER_IMPUTED = 'OTHER_IMPUTED',
  OVERTIME = 'OVERTIME',
  PAYCHECK_TIPS = 'PAYCHECK_TIPS',
  PTO = 'PTO',
  REGULAR = 'REGULAR',
  SICK = 'SICK',
}

/** Pagination container for PayrollItem */
export type PayrollItemPage = {
  __typename: 'PayrollItemPage';
  items: Array<PayrollItem>;
  pageInfo: PageInfo;
};

export enum PayrollItemStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type PayrollItemTax = {
  __typename: 'PayrollItemTax';
  amount: Scalars['String']['output'];
  checkTaxId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  payer: Scalars['String']['output'];
};

/** Pagination container for Payroll */
export type PayrollPage = {
  __typename: 'PayrollPage';
  items: Array<Payroll>;
  pageInfo: PageInfo;
};

export enum PayrollStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

/** Represents the total payroll item amounts for a given pay period. */
export type PayrollTotals = {
  __typename: 'PayrollTotals';
  /** Total cash amount that Check will withdraw from the company's account for the payroll after it is approved. */
  cashRequirement: Scalars['String']['output'];
  /** Total amount of company benefit contributions. */
  companyBenefits: Scalars['String']['output'];
  /** Total amount of company tax liability. */
  companyTaxes: Scalars['String']['output'];
  /** Total amount of all employee benefit contributions. */
  employeeBenefits: Scalars['String']['output'];
  /**
   * Total amount of all employee gross pay, including taxable and non-taxable
   * components. This is the sum of gross_pay for all payroll items.
   */
  employeeGross: Scalars['String']['output'];
  /**
   * Total amount that should be paid from the company to its employees, excluding
   * employee taxes, employee benefit contributions, and imputed income. This is
   * the sum of net_pay for all payroll items.
   */
  employeeNet: Scalars['String']['output'];
  /** Total amount of all employee reimbursements. */
  employeeReimbursements: Scalars['String']['output'];
  /** Total amount of all employee tax liability. */
  employeeTaxes: Scalars['String']['output'];
  /** Total amount that the company owes to employees, contractors, tax agencies, and benefits providers. */
  liability: Scalars['String']['output'];
  /** Total amount of all employee post-tax deductions. */
  postTaxDeductions: Scalars['String']['output'];
};

/** A lat/long point */
export type Point = {
  __typename: 'Point';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type PointInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

/** A spatial polygon */
export type Polygon = {
  __typename: 'Polygon';
  rings: Array<PolygonRing>;
};

export type PolygonInput = {
  rings: Array<PolygonRingInput>;
};

/** A ring of lat/long points */
export type PolygonRing = {
  __typename: 'PolygonRing';
  points: Array<Point>;
};

export type PolygonRingInput = {
  points: Array<PointInput>;
};

export type PostTaxDeduction = {
  __typename: 'PostTaxDeduction';
  amount: Scalars['String']['output'];
  checkPtdId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ptdType: Scalars['String']['output'];
};

/** The query root of this schema */
export type Query = {
  __typename: 'Query';
  /** Get account */
  account: Account;
  /** Check if a client name already exists */
  accountNameDuplicate: Scalars['Boolean']['output'];
  /** Get all payment methods for a billing account */
  accountPaymentMethods: PaymentMethodInfo;
  /** Get accounts of customer */
  accounts: Array<Account>;
  /** Get Active Region States */
  activeRegionState: Array<Scalars['String']['output']>;
  /** Active User List Report */
  activeUserListReport: Scalars['Boolean']['output'];
  /** Active users by skill report */
  activeUsersBySkillReport: Array<ActiveUsersBySkillReport>;
  /** Get address */
  address: Address;
  /** List addresses */
  addresses: AddressPage;
  /** List agencies */
  agencies: Array<Agency>;
  /** Get agency */
  agency: Agency;
  /** Get agency by key */
  agencyByKey: Agency;
  /** Get all jobs from a worker account */
  allJobsWorker: Array<JobWorker>;
  /** Returns a list of payrolls. */
  allPayrolls: PayrollPage;
  /** Get candidate */
  candidate: Candidate;
  /** Get candidate by token */
  candidateByToken: Candidate;
  /** Get current actor */
  currentActor: CurrentActorUnion;
  /** Get current device */
  currentDevice: Device;
  /** Get current tenant admin */
  currentTenantAdmin: TenantAdmin;
  /** Get customer */
  customer: Customer;
  /** Get customer admin */
  customerAdmin: CustomerAdmin;
  /** Get all payment methods from a customer admin */
  customerAdminPaymentMethod: PaymentMethodInfo;
  /** Get customer invite */
  customerInvite: Maybe<CustomerAdmin>;
  /** Customer List Report */
  customerListReport: Maybe<Array<CustomerListReport>>;
  /** Check if a client name already exists */
  customerNameDuplicate: Scalars['Boolean']['output'];
  /** Get default rate */
  defaultRate: DefaultRate;
  /** List default rates */
  defaultRates: Array<DefaultRate>;
  /** List default rates */
  defaultRatesBySkill: DefaultRate;
  /** List pre-defined boundries to build sevice areas */
  definedBoundries: DefinedBoundryPage;
  /** List eligible workers for a job */
  eligibleWorkers: WorkerPage;
  /** Check if a user with the specified email exists */
  existingUser: Scalars['Boolean']['output'];
  /** Fetch default/nearest rates */
  fetchRates: FetchRates;
  /** Get timesheets to generate invoice */
  generateInvoiceTimesheets: TimesheetPage;
  /** Get interview question */
  interviewQuestion: InterviewQuestion;
  /** List interview questions */
  interviewQuestions: Array<InterviewQuestion>;
  /** Get address */
  invoice: Invoice;
  /** Invoicing Report */
  invoicingReport: Maybe<Array<InvoicingReport>>;
  /** Get job */
  job: Job;
  /** Get job */
  jobHireableWorkers: Maybe<Array<Worker>>;
  /** Get job history */
  jobHistory: JobHistory;
  /** Get job subscribers */
  jobSubscriber: Array<JobSubscriber>;
  /** Get job worker */
  jobWorker: JobWorker;
  /** Returns a list of items from an Stripe Invoice. */
  listStripeInvoiceDetail: StripeInvoiceDetail;
  /** Returns a list of invoices from Stripe. */
  listStripeInvoices: InvoicePage;
  /** Returns a list of surge rates. */
  listSurgeRates: SurgeRatePage;
  /** Get needs approval job count */
  needsApproval: Array<Shift>;
  /** Get notifications */
  notifications: NotificationPage;
  /** Get order */
  order: Order;
  /** Returns the specified payroll. */
  payroll: Payroll;
  /** Returns the specified payroll item. */
  payrollItem: PayrollItem;
  /** Payroll journal information */
  payrollJournalReport: Scalars['Boolean']['output'];
  /** Returns a preview of an approved version of the input payroll. */
  payrollPreview: Payroll;
  /** Get a Plaid Link Token */
  plaidLinkToken: Scalars['String']['output'];
  /** Get rate */
  rate: Rate;
  /** Get rate quote */
  rateQuote: Maybe<Rate>;
  /** Get Date Ranges */
  rateRange: Array<RateRange>;
  /** Returns the regions of the user */
  region: Array<Region>;
  /** Revenue Report */
  revenueReport: Maybe<Array<RevenueReport>>;
  /** Get service area */
  serviceArea: ServiceArea;
  /** Get skill */
  skill: Skill;
  /** Get skill category */
  skillCategory: SkillCategory;
  /** Get surge rate by date */
  surgeRateByDate: SurgeRate;
  /** Get surge rates from given date */
  surgeRatesFromDate: Array<SurgeRate>;
  /** Get tenant */
  tenant: Tenant;
  /** Get tenant by key */
  tenantByKey: Maybe<Tenant>;
  /** Get timesheet */
  timesheet: Timesheet;
  /** Timesheet Report */
  timesheetReport: Scalars['String']['output'];
  /** Get a URL to upload files to */
  uploadUrl: UploadUrl;
  /** Get filters of current user */
  userFilter: Maybe<UserFilter>;
  /** Get worker interview (Video Questionnaire) */
  willoWorkerInterview: Maybe<WilloWorkerInterview>;
  /** Get worker */
  worker: Worker;
  /** Get worker interview */
  workerInterview: Maybe<WorkerInterview>;
  /** Get signature requests for one workerId from TenantAdmin */
  workerSignatureRequestsTenant: Array<WorkerSignatureRequest>;
  /** List workers */
  workers: WorkerPage;
};

/** The query root of this schema */
export type QueryAccountArgs = {
  accountId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAccountNameDuplicateArgs = {
  clientName: Scalars['String']['input'];
};

/** The query root of this schema */
export type QueryAccountPaymentMethodsArgs = {
  accountId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAccountsArgs = {
  customerId: Scalars['ID']['input'];
  skillIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The query root of this schema */
export type QueryActiveRegionStateArgs = {
  tenantId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryActiveUsersBySkillReportArgs = {
  skills: Array<Scalars['Int']['input']>;
};

/** The query root of this schema */
export type QueryAddressArgs = {
  addressId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAgencyArgs = {
  agencyId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAgencyByKeyArgs = {
  key: Scalars['Key']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAllJobsWorkerArgs = {
  workerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryAllPayrollsArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
  tenantId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryCandidateArgs = {
  candidateId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryCandidateByTokenArgs = {
  token: Scalars['String']['input'];
};

/** The query root of this schema */
export type QueryCustomerArgs = {
  customerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryCustomerAdminArgs = {
  customerAdminId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryCustomerAdminPaymentMethodArgs = {
  customerAdminId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryCustomerInviteArgs = {
  inviteCode: Scalars['String']['input'];
};

/** The query root of this schema */
export type QueryCustomerNameDuplicateArgs = {
  clientName: Scalars['String']['input'];
};

/** The query root of this schema */
export type QueryDefaultRateArgs = {
  defaultRateId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryDefaultRatesArgs = {
  addressId: InputMaybe<Scalars['ID']['input']>;
};

/** The query root of this schema */
export type QueryDefaultRatesBySkillArgs = {
  addressId: InputMaybe<Scalars['ID']['input']>;
  skillId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryDefinedBoundriesArgs = {
  query: InputMaybe<Scalars['String']['input']>;
  within: InputMaybe<PolygonInput>;
};

/** The query root of this schema */
export type QueryEligibleWorkersArgs = {
  jobId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

/** The query root of this schema */
export type QueryExistingUserArgs = {
  email: Scalars['Email']['input'];
};

/** The query root of this schema */
export type QueryFetchRatesArgs = {
  accountId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryGenerateInvoiceTimesheetsArgs = {
  customerId: Scalars['ID']['input'];
  filters: InputMaybe<TimesheetFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** The query root of this schema */
export type QueryInterviewQuestionArgs = {
  interviewQuestionId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryInvoiceArgs = {
  invoice: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryInvoicingReportArgs = {
  from: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  to: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The query root of this schema */
export type QueryJobArgs = {
  jobId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryJobHireableWorkersArgs = {
  filters: InputMaybe<WorkerFilterSetInput>;
  jobId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryJobHistoryArgs = {
  jobId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryJobSubscriberArgs = {
  job: Scalars['Int']['input'];
};

/** The query root of this schema */
export type QueryJobWorkerArgs = {
  jobWorkerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryListStripeInvoiceDetailArgs = {
  invoiceId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryListStripeInvoicesArgs = {
  customerId: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<InvoiceFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<InvoiceSortKeyEnum>;
};

/** The query root of this schema */
export type QueryListSurgeRatesArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** The query root of this schema */
export type QueryNeedsApprovalArgs = {
  agencyId: Scalars['ID']['input'];
  endDate: Scalars['ISO8601Date']['input'];
  filters: InputMaybe<JobFilterSetInput>;
  startDate: Scalars['ISO8601Date']['input'];
  tenantId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryNotificationsArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** The query root of this schema */
export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryPayrollArgs = {
  payrollId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryPayrollItemArgs = {
  payrollItemId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryPayrollJournalReportArgs = {
  payrollId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryPayrollPreviewArgs = {
  payrollId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryRateArgs = {
  rateId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryRateQuoteArgs = {
  accountId: Scalars['ID']['input'];
  point: PointInput;
  skillId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryRateRangeArgs = {
  addressId: InputMaybe<Scalars['ID']['input']>;
  skillId: InputMaybe<Scalars['ID']['input']>;
};

/** The query root of this schema */
export type QueryRegionArgs = {
  agencyId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryRevenueReportArgs = {
  from: Scalars['ISO8601DateTime']['input'];
  to: Scalars['ISO8601DateTime']['input'];
};

/** The query root of this schema */
export type QueryServiceAreaArgs = {
  serviceAreaId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QuerySkillArgs = {
  skillId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QuerySkillCategoryArgs = {
  skillCategoryId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QuerySurgeRateByDateArgs = {
  date: Scalars['ISO8601Date']['input'];
};

/** The query root of this schema */
export type QuerySurgeRatesFromDateArgs = {
  fromDate: Scalars['ISO8601Date']['input'];
};

/** The query root of this schema */
export type QueryTenantArgs = {
  tenantId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryTenantByKeyArgs = {
  key: Scalars['Key']['input'];
};

/** The query root of this schema */
export type QueryTimesheetArgs = {
  timesheetId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryTimesheetReportArgs = {
  payPeriod: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** The query root of this schema */
export type QueryUploadUrlArgs = {
  fileType: FileTypeEnum;
  filename: Scalars['String']['input'];
};

/** The query root of this schema */
export type QueryUserFilterArgs = {
  filterType: UserFilterTypeEnum;
};

/** The query root of this schema */
export type QueryWilloWorkerInterviewArgs = {
  typeInterview: WilloWorkerInterviewStatusEnum;
  workerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryWorkerArgs = {
  workerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryWorkerInterviewArgs = {
  workerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryWorkerSignatureRequestsTenantArgs = {
  type: InputMaybe<SignatureRequestTypeEnum>;
  workerId: Scalars['ID']['input'];
};

/** The query root of this schema */
export type QueryWorkersArgs = {
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Rate */
export type Rate = {
  __typename: 'Rate';
  account: Maybe<Account>;
  active: Maybe<Scalars['Boolean']['output']>;
  address: Maybe<Address>;
  agency: Maybe<Agency>;
  baseCost: Scalars['Int']['output'];
  basePay: Scalars['Int']['output'];
  cost: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  level: Maybe<Scalars['Int']['output']>;
  overtimeFactor: Maybe<Scalars['Int']['output']>;
  pay: Scalars['Int']['output'];
  serviceArea: ServiceArea;
  skill: Maybe<Skill>;
  surgeCost: Maybe<Scalars['Int']['output']>;
  surgePay: Maybe<Scalars['Int']['output']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Rate */
export type RateCostArgs = {
  overtime: InputMaybe<Scalars['Boolean']['input']>;
  surge: InputMaybe<Scalars['Boolean']['input']>;
};

/** Rate */
export type RatePayArgs = {
  overtime: InputMaybe<Scalars['Boolean']['input']>;
  surge: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of RateCancel */
export type RateCancelPayload = {
  __typename: 'RateCancelPayload';
  rate: Rate;
};

/** Autogenerated return type of RateCreate */
export type RateCreatePayload = {
  __typename: 'RateCreatePayload';
  rate: Rate;
};

/** Autogenerated return type of RateDelete */
export type RateDeletePayload = {
  __typename: 'RateDeletePayload';
  deleted: Scalars['Boolean']['output'];
};

/** Date Range Type */
export type RateRange = {
  __typename: 'RateRange';
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
};

/** Autogenerated return type of RateUpdate */
export type RateUpdatePayload = {
  __typename: 'RateUpdatePayload';
  rate: Rate;
};

export type ReferenceInput = {
  city: Scalars['String']['input'];
  duration: DurationEnum;
  email?: InputMaybe<Scalars['Email']['input']>;
  employerName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Referral */
export type Referral = {
  __typename: 'Referral';
  clickedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  onboardedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  prospectEmail: Scalars['String']['output'];
  sentAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Maybe<Worker>;
};

/** Autogenerated return type of ReferralCreate */
export type ReferralCreatePayload = {
  __typename: 'ReferralCreatePayload';
  referral: Referral;
};

export enum ReferralEnum {
  CRAIGLIST = 'CRAIGLIST',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  INDEED = 'INDEED',
  INSTAGRAM = 'INSTAGRAM',
  OTHER = 'OTHER',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
}

/** Region Type */
export type Region = {
  __typename: 'Region';
  /** Unique identifier for the region */
  id: Scalars['ID']['output'];
  /** Indicates if the region is active */
  isActive: Scalars['Boolean']['output'];
  /** job tax type */
  jobTaxType: Maybe<TaxTypeEnum>;
  /** Name of the region */
  name: Maybe<Scalars['String']['output']>;
  /** Zipcodes associated with the region */
  regionZipcodes: Maybe<Array<RegionZipcode>>;
};

export type RegionZipcode = {
  __typename: 'RegionZipcode';
  /** The region associated with the zipcode */
  region: Region;
  /** Zipcode data associated with the region */
  zipcodeData: ZipcodeData;
};

export enum RelationshipKindEnum {
  BLOCKED = 'BLOCKED',
  FAVORITED = 'FAVORITED',
  LTA_WORKERS = 'LTA_WORKERS',
}

/** Revenue Report */
export type RevenueReport = {
  __typename: 'RevenueReport';
  company: Scalars['String']['output'];
  netProfit: Scalars['Float']['output'];
  period: Scalars['String']['output'];
  region: Scalars['String']['output'];
  totalHours: Scalars['Float']['output'];
  totalPayrollExpense: Scalars['Float']['output'];
  totalRevenue: Scalars['Float']['output'];
};

export enum SearchRadiusEnum {
  FIFTEEN_MILES = 'FIFTEEN_MILES',
  FIVE_MILES = 'FIVE_MILES',
  TEN_MILES = 'TEN_MILES',
  THIRTY_MILES = 'THIRTY_MILES',
  TWENTY_FIVE_MILES = 'TWENTY_FIVE_MILES',
  TWENTY_MILES = 'TWENTY_MILES',
}

/** Service area */
export type ServiceArea = {
  __typename: 'ServiceArea';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  geometry: MultiPolygon;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  rates: Array<Rate>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of ServiceAreaCreate */
export type ServiceAreaCreatePayload = {
  __typename: 'ServiceAreaCreatePayload';
  serviceArea: ServiceArea;
};

/** Autogenerated return type of ServiceAreaUpdate */
export type ServiceAreaUpdatePayload = {
  __typename: 'ServiceAreaUpdatePayload';
  serviceArea: ServiceArea;
};

/** Shift */
export type Shift = {
  __typename: 'Shift';
  createdAt: Scalars['ISO8601DateTime']['output'];
  endAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  startAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ShiftInput = {
  endAt: Scalars['ISO8601DateTime']['input'];
  startAt: Scalars['ISO8601DateTime']['input'];
  timeZoneOffset?: InputMaybe<Scalars['Float']['input']>;
};

export enum SignatureRequestStatusEnum {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum SignatureRequestTypeEnum {
  ONBOARDING_DOCS = 'ONBOARDING_DOCS',
}

/** Skill */
export type Skill = {
  __typename: 'Skill';
  agency: Agency;
  assessment: Scalars['Boolean']['output'];
  category: Maybe<SkillCategory>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionEn: Maybe<Scalars['String']['output']>;
  descriptionEs: Maybe<Scalars['String']['output']>;
  displayNameEn: Maybe<Scalars['String']['output']>;
  displayNameEs: Maybe<Scalars['String']['output']>;
  gravyTrained: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Skill category */
export type SkillCategory = {
  __typename: 'SkillCategory';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionEn: Maybe<Scalars['String']['output']>;
  descriptionEs: Maybe<Scalars['String']['output']>;
  displayNameEn: Maybe<Scalars['String']['output']>;
  displayNameEs: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  skills: Array<Skill>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of SkillCategoryCreate */
export type SkillCategoryCreatePayload = {
  __typename: 'SkillCategoryCreatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCategoryUpdate */
export type SkillCategoryUpdatePayload = {
  __typename: 'SkillCategoryUpdatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCreate */
export type SkillCreatePayload = {
  __typename: 'SkillCreatePayload';
  skill: Skill;
};

/** Skill request */
export type SkillRequest = {
  __typename: 'SkillRequest';
  assessment: Maybe<Array<Assessment>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  skill: Skill;
  status: SkillRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

/** Autogenerated return type of SkillRequestAccept */
export type SkillRequestAcceptPayload = {
  __typename: 'SkillRequestAcceptPayload';
  skillRequest: SkillRequest;
};

/** Autogenerated return type of SkillRequestReject */
export type SkillRequestRejectPayload = {
  __typename: 'SkillRequestRejectPayload';
  skillRequest: SkillRequest;
};

export enum SkillRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Autogenerated return type of SkillUpdate */
export type SkillUpdatePayload = {
  __typename: 'SkillUpdatePayload';
  skill: Skill;
};

export enum SmartphoneTypeEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  OTHER = 'OTHER',
}

export enum SsnVerificationStatusEnum {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

/** A Stripe Bank Account object */
export type StripeBankAccount = {
  __typename: 'StripeBankAccount';
  accountHolderName: Maybe<Scalars['String']['output']>;
  accountHolderType: Maybe<Scalars['String']['output']>;
  accountType: Maybe<Scalars['String']['output']>;
  bankName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  customer: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** A Card */
export type StripeCard = {
  __typename: 'StripeCard';
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
  funding: Scalars['String']['output'];
  holderName: Maybe<Scalars['String']['output']>;
  last4: Scalars['String']['output'];
};

/** An Invoice */
export type StripeInvoice = {
  __typename: 'StripeInvoice';
  amountDue: Scalars['String']['output'];
  created: Scalars['String']['output'];
  createdDatetime: Scalars['ISO8601DateTime']['output'];
  customer: Scalars['String']['output'];
  customerEmail: Scalars['Email']['output'];
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['String']['output']>;
  dueDateDatetime: Maybe<Scalars['ISO8601DateTime']['output']>;
  hostedInvoiceUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoicePdf: Maybe<Scalars['String']['output']>;
  status: StripeInvoiceStatusEnum;
  subtotal: Scalars['String']['output'];
  total: Scalars['String']['output'];
};

/** Invoice Detail */
export type StripeInvoiceDetail = {
  __typename: 'StripeInvoiceDetail';
  invoice: Invoice;
  invoiceItems: Maybe<Array<InvoiceItem>>;
};

/** Autogenerated return type of StripeInvoiceFinalize */
export type StripeInvoiceFinalizePayload = {
  __typename: 'StripeInvoiceFinalizePayload';
  invoice: Invoice;
};

/** Autogenerated return type of StripeInvoiceItemCreate */
export type StripeInvoiceItemCreatePayload = {
  __typename: 'StripeInvoiceItemCreatePayload';
  invoiceItem: Maybe<InvoiceItem>;
};

/** Autogenerated return type of StripeInvoiceItemDelete */
export type StripeInvoiceItemDeletePayload = {
  __typename: 'StripeInvoiceItemDeletePayload';
  deleted: Scalars['Boolean']['output'];
};

/** Autogenerated return type of StripeInvoiceItemUpdate */
export type StripeInvoiceItemUpdatePayload = {
  __typename: 'StripeInvoiceItemUpdatePayload';
  invoiceItem: Maybe<InvoiceItem>;
};

/** Autogenerated return type of StripeInvoiceMarkAsPaid */
export type StripeInvoiceMarkAsPaidPayload = {
  __typename: 'StripeInvoiceMarkAsPaidPayload';
  invoice: StripeInvoice;
};

export enum StripeInvoiceStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  PENDING = 'PENDING',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
}

/** Autogenerated return type of StripeInvoiceUpdate */
export type StripeInvoiceUpdatePayload = {
  __typename: 'StripeInvoiceUpdatePayload';
  invoice: Maybe<Invoice>;
};

/** Autogenerated return type of StripeInvoiceVoid */
export type StripeInvoiceVoidPayload = {
  __typename: 'StripeInvoiceVoidPayload';
  invoice: Invoice;
};

/** A payment method */
export type StripePaymentMethod = {
  __typename: 'StripePaymentMethod';
  card: StripeCard;
  created: Scalars['Int']['output'];
  customer: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Autogenerated return type of StripeUnlinkInvoice */
export type StripeUnlinkInvoicePayload = {
  __typename: 'StripeUnlinkInvoicePayload';
  invoice: Invoice;
};

export enum SubjectRoleEnum {
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  TARGET = 'TARGET',
}

/** Surge Rate */
export type SurgeRate = {
  __typename: 'SurgeRate';
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rate: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Pagination container for SurgeRate */
export type SurgeRatePage = {
  __typename: 'SurgeRatePage';
  items: Array<SurgeRate>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of SurgeRatesCreate */
export type SurgeRatesCreatePayload = {
  __typename: 'SurgeRatesCreatePayload';
  surgeRates: SurgeRate;
};

/** Autogenerated return type of SurgeRatesDelete */
export type SurgeRatesDeletePayload = {
  __typename: 'SurgeRatesDeletePayload';
  deleted: Scalars['Boolean']['output'];
};

/** Autogenerated return type of SurgeRatesUpdate */
export type SurgeRatesUpdatePayload = {
  __typename: 'SurgeRatesUpdatePayload';
  surgeRate: SurgeRate;
};

/** Autogenerated return type of SwitchAutoApprove */
export type SwitchAutoApprovePayload = {
  __typename: 'SwitchAutoApprovePayload';
  invoice: Invoice;
};

/** Tag */
export type Tag = {
  __typename: 'Tag';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of TagCreate */
export type TagCreatePayload = {
  __typename: 'TagCreatePayload';
  tag: Tag;
};

/** Pagination container for Tag */
export type TagPage = {
  __typename: 'TagPage';
  items: Array<Tag>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of TaggableAddTag */
export type TaggableAddTagPayload = {
  __typename: 'TaggableAddTagPayload';
  tags: Array<Tag>;
};

/** Autogenerated return type of TaggableChangeTags */
export type TaggableChangeTagsPayload = {
  __typename: 'TaggableChangeTagsPayload';
  tags: Array<Tag>;
};

export enum TaggableEnum {
  ACCOUNT = 'ACCOUNT',
  CUSTOMER = 'CUSTOMER',
  JOB = 'JOB',
  ORDER = 'ORDER',
  RATE = 'RATE',
  SERVICE_AREA = 'SERVICE_AREA',
  WORKER = 'WORKER',
}

/** Autogenerated return type of TaggableRemoveTag */
export type TaggableRemoveTagPayload = {
  __typename: 'TaggableRemoveTagPayload';
  tags: Array<Tag>;
};

/** Tests model for inclusion of ALL given tags */
export type TaggedFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds: Array<Scalars['ID']['input']>;
};

export enum TaxTypeEnum {
  ALL = 'ALL',
  TAX_1099 = 'TAX_1099',
  TAX_W2 = 'TAX_W2',
}

/** Field for equality to a given Tax Type */
export type TaxTypeEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: TaxTypeEnum;
};

/** A tenant */
export type Tenant = {
  __typename: 'Tenant';
  admins: Array<TenantAdmin>;
  agencies: Array<Agency>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['Key']['output'];
  locationDesc: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A tenant */
export type TenantAdminsArgs = {
  filters: InputMaybe<TenantAdminFilterSetInput>;
};

/** Tenant admin */
export type TenantAdmin = {
  __typename: 'TenantAdmin';
  avatarKey: Maybe<Scalars['String']['output']>;
  avatarUrl: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  pendingCustomers: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  tenant: Tenant;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

/** Autogenerated return type of TenantAdminChangeAvatar */
export type TenantAdminChangeAvatarPayload = {
  __typename: 'TenantAdminChangeAvatarPayload';
  tenantAdmin: TenantAdmin;
};

/** Available filter operations for a TenantAdmin */
export type TenantAdminFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<TextSearchFilterInput>;
};

/** Autogenerated return type of TenantAdminInvite */
export type TenantAdminInvitePayload = {
  __typename: 'TenantAdminInvitePayload';
  tenantAdmin: TenantAdmin;
};

/** Autogenerated return type of TenantAdminResendInvitation */
export type TenantAdminResendInvitationPayload = {
  __typename: 'TenantAdminResendInvitationPayload';
  result: Scalars['Boolean']['output'];
};

/** Autogenerated return type of TenantUpdate */
export type TenantUpdatePayload = {
  __typename: 'TenantUpdatePayload';
  tenant: Tenant;
};

/** Tests field for inclusion of a given substring */
export type TextSearchFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sets the testing mode */
  mode?: InputMaybe<TextSearchModeEnum>;
  value: Scalars['String']['input'];
};

/** Represents a mode of searching text for a substring */
export enum TextSearchModeEnum {
  /** String contains */
  CONTAINS = 'CONTAINS',
  /** String starts with */
  PREFIX = 'PREFIX',
  /** String ends with */
  SUFFIX = 'SUFFIX',
}

export enum TimelineStatusEnum {
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

/** Timesheet */
export type Timesheet = {
  __typename: 'Timesheet';
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedBreakMinutes: Scalars['Minutes']['output'];
  approvedCheckinAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedCheckoutAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedMinutes: Scalars['Minutes']['output'];
  checkinAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  checkinCoords: Maybe<Point>;
  checkoutAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  checkoutCoords: Maybe<Point>;
  costRate: Scalars['NonNegativeInt']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  holdByInvoiceId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invoiceItems: Maybe<Array<InvoiceItem>>;
  job: Job;
  jobWorker: JobWorker;
  netApprovedTime: Scalars['Minutes']['output'];
  payRate: Scalars['NonNegativeInt']['output'];
  rating: Maybe<Scalars['NonNegativeInt']['output']>;
  ratingComment: Maybe<Scalars['String']['output']>;
  rejectedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  reportComment: Maybe<Scalars['String']['output']>;
  reportedBreakMinutes: Scalars['Minutes']['output'];
  reportedCheckinAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  reportedCheckoutAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  shift: Shift;
  status: TimesheetStatusEnum;
  timesheetComments: Array<TimesheetComment>;
  tipAmount: Maybe<Scalars['NonNegativeFloat']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedBy: Maybe<User>;
  updatedById: Maybe<Scalars['Int']['output']>;
  updatedByType: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of TimesheetApprove */
export type TimesheetApprovePayload = {
  __typename: 'TimesheetApprovePayload';
  timesheet: Timesheet;
};

/** Timesheet Comment */
export type TimesheetComment = {
  __typename: 'TimesheetComment';
  actor: Maybe<User>;
  actorType: Maybe<Scalars['String']['output']>;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  timesheet: Maybe<Timesheet>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of TimesheetCommentsAdd */
export type TimesheetCommentsAddPayload = {
  __typename: 'TimesheetCommentsAddPayload';
  timesheet: Timesheet;
  timesheetComments: TimesheetComment;
};

/** Autogenerated return type of TimesheetCreate */
export type TimesheetCreatePayload = {
  __typename: 'TimesheetCreatePayload';
  timesheet: Timesheet;
};

/** Available filter operations for a Timesheet */
export type TimesheetFilterSetInput = {
  account?: InputMaybe<IdEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  approvedCheckinAt?: InputMaybe<DateTimeRangeFilterInput>;
  invoiceStatus?: InputMaybe<TimesheetInvoiceStatusEnum>;
  status?: InputMaybe<TimesheetStatusEqualityFilterInput>;
};

export enum TimesheetInvoiceStatusEnum {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  NOT_INVOICED = 'NOT_INVOICED',
}

/** Pagination container for Timesheet */
export type TimesheetPage = {
  __typename: 'TimesheetPage';
  items: Array<Timesheet>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of TimesheetReject */
export type TimesheetRejectPayload = {
  __typename: 'TimesheetRejectPayload';
  timesheet: Timesheet;
};

export enum TimesheetStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given TimesheetStatus */
export type TimesheetStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: InputMaybe<Scalars['Boolean']['input']>;
  value: TimesheetStatusEnum;
};

/** Autogenerated return type of TimesheetTipUpdate */
export type TimesheetTipUpdatePayload = {
  __typename: 'TimesheetTipUpdatePayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUnapprove */
export type TimesheetUnapprovePayload = {
  __typename: 'TimesheetUnapprovePayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUnreject */
export type TimesheetUnrejectPayload = {
  __typename: 'TimesheetUnrejectPayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUpdate */
export type TimesheetUpdatePayload = {
  __typename: 'TimesheetUpdatePayload';
  timesheet: Timesheet;
};

export enum TimesheetsBulkEditActionEnum {
  APPROVE = 'APPROVE',
  EDIT = 'EDIT',
  EDIT_AND_APPROVE = 'EDIT_AND_APPROVE',
  REJECT = 'REJECT',
  UN_APPROVE = 'UN_APPROVE',
  UN_REJECT = 'UN_REJECT',
}

/** Autogenerated return type of TimesheetsBulkEdit */
export type TimesheetsBulkEditPayload = {
  __typename: 'TimesheetsBulkEditPayload';
  message: Scalars['String']['output'];
};

export enum TypeAppEnum {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

/** Uniform */
export type Uniform = {
  __typename: 'Uniform';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  defaultInstructions: Scalars['String']['output'];
  defaultInstructionsEs: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nameEs: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Upload URL */
export type UploadUrl = {
  __typename: 'UploadURL';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A user */
export type User = {
  __typename: 'User';
  active: Scalars['Boolean']['output'];
  allowedPlatform: Scalars['Int']['output'];
  appReviewActionDate: Maybe<Scalars['ISO8601DateTime']['output']>;
  appReviewStatus: Maybe<AppReviewStatusEnum>;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['String']['output'];
  emailConfirmed: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language: LanguageEnum;
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  resetPassword: Maybe<Scalars['Boolean']['output']>;
  roleId: UserRoleEnum;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of UserAuthResetPassword */
export type UserAuthResetPasswordPayload = {
  __typename: 'UserAuthResetPasswordPayload';
  result: Scalars['Boolean']['output'];
  user: User;
};

/** Autogenerated return type of UserChangeActive */
export type UserChangeActivePayload = {
  __typename: 'UserChangeActivePayload';
  user: User;
};

/** User filter type */
export type UserFilter = {
  __typename: 'UserFilter';
  filterType: UserFilterTypeEnum;
  filterValue: Scalars['String']['output'];
  version: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of UserFilterSave */
export type UserFilterSavePayload = {
  __typename: 'UserFilterSavePayload';
  userFilter: UserFilter;
};

export enum UserFilterTypeEnum {
  DASHBOARD = 'DASHBOARD',
}

export enum UserRoleEnum {
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  HELPDESK_ADMIN = 'HELPDESK_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  WORKER = 'WORKER',
}

/** Autogenerated return type of UserUpdate */
export type UserUpdatePayload = {
  __typename: 'UserUpdatePayload';
  user: User;
};

/** Autogenerated return type of ValidateAddress */
export type ValidateAddressPayload = {
  __typename: 'ValidateAddressPayload';
  error: Maybe<Scalars['String']['output']>;
  region: Maybe<Region>;
  success: Scalars['Boolean']['output'];
  suggestedAlternative: Maybe<Address>;
};

export enum VisibilityStatusEnum {
  ALL = 'ALL',
  LTA = 'LTA',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  W2 = 'W2',
}

/** Willo Worker interview */
export type WilloWorkerInterview = {
  __typename: 'WilloWorkerInterview';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interviewerKey: Scalars['String']['output'];
  invitationLink: Scalars['String']['output'];
  showcaseLink: Maybe<Scalars['String']['output']>;
  stage: Maybe<InterviewStatusEnum>;
  typeInterview: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum WilloWorkerInterviewStatusEnum {
  ONBOARDING = 'ONBOARDING',
}

/** Worker */
export type Worker = {
  __typename: 'Worker';
  active: Maybe<Scalars['Boolean']['output']>;
  activeCertificates: Array<Certificate>;
  activeSkills: Array<Skill>;
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  agency: Agency;
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarKey: Maybe<Scalars['String']['output']>;
  avatarUrl: Maybe<Scalars['String']['output']>;
  averageRating: Scalars['Float']['output'];
  backgroundStatus: Maybe<BackgroundStatusEnum>;
  badges: Array<Badge>;
  candidateStatus: CandidateStatusEnum;
  candidateStatusUpdatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  chatUid: Scalars['String']['output'];
  checkEmployeeId: Maybe<Scalars['String']['output']>;
  checkWorkplaceId: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  comments: Array<Comment>;
  complianceId: Maybe<Scalars['ID']['output']>;
  coords: Maybe<Point>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  deactivatedBy: Maybe<WorkerDeactivatedTypeEnum>;
  deactivatedByUser: Maybe<User>;
  defaultDistance: SearchRadiusEnum;
  deniedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  disableReason: Maybe<Scalars['String']['output']>;
  formi9Restartable: Scalars['Boolean']['output'];
  gender: GenderEnum;
  hasSsn: Scalars['Boolean']['output'];
  heardFrom: Maybe<Scalars['String']['output']>;
  i9Status: Maybe<I9StatusEnum>;
  i9form: Maybe<I9form>;
  id: Scalars['ID']['output'];
  interviewStatus: Maybe<InterviewStatusEnum>;
  jobOffersGroup: Maybe<Array<JobOffersGroup>>;
  jobWorkers: JobWorkerPage;
  jobsDropped: Scalars['NonNegativeInt']['output'];
  jobsFailed: Scalars['NonNegativeInt']['output'];
  jobsRatingCount: Scalars['NonNegativeInt']['output'];
  jobsWorked: Scalars['NonNegativeInt']['output'];
  lastFourSsn: Maybe<Scalars['String']['output']>;
  migrated: Maybe<Scalars['Boolean']['output']>;
  noShow: Maybe<Scalars['NonNegativeInt']['output']>;
  onboarding: Maybe<Scalars['JSON']['output']>;
  onboardingDocsStatus: Maybe<SignatureRequestStatusEnum>;
  onboardingStatus: Maybe<OnboardingStatusEnum>;
  ownsCar: Scalars['Boolean']['output'];
  pendingJobOffers: Array<JobOffer>;
  pendingJobRequests: Array<JobRequest>;
  readyToWork: Scalars['Boolean']['output'];
  reenableDate: Maybe<Scalars['ISO8601DateTime']['output']>;
  references: Array<WorkerReference>;
  referrals: Array<Referral>;
  rejectedJobOffers: Maybe<Array<JobOffer>>;
  shiftsWorked: Scalars['NonNegativeInt']['output'];
  skillRequests: Array<SkillRequest>;
  skills: Array<Skill>;
  ssnVerificationRetryCount: Maybe<Scalars['Int']['output']>;
  state: Maybe<Scalars['String']['output']>;
  status: WorkerStatusEnum;
  tags: Array<Tag>;
  taxType: TaxTypeEnum;
  tcAcceptedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  timezone: Scalars['Timezone']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usCitizenOrWorkPermit: Scalars['Boolean']['output'];
  user: User;
  verificationCode: Maybe<Scalars['String']['output']>;
  verificationRequiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  verificationStatus: SsnVerificationStatusEnum;
  verifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  workerJurisdictions: Maybe<Array<Jurisdiction>>;
  workerSkills: Array<WorkerSkill>;
  zip: Maybe<Scalars['String']['output']>;
};

/** Worker */
export type WorkerJobWorkersArgs = {
  filters: InputMaybe<JobWorkerFilterSetInput>;
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

/** Autogenerated return type of WorkerAddCertificate */
export type WorkerAddCertificatePayload = {
  __typename: 'WorkerAddCertificatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerAddReferenceList */
export type WorkerAddReferenceListPayload = {
  __typename: 'WorkerAddReferenceListPayload';
  saved: Scalars['Boolean']['output'];
};

/** Autogenerated return type of WorkerAddReference */
export type WorkerAddReferencePayload = {
  __typename: 'WorkerAddReferencePayload';
  reference: WorkerReference;
};

/** Autogenerated return type of WorkerAddSkill */
export type WorkerAddSkillPayload = {
  __typename: 'WorkerAddSkillPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerApproveFormI9 */
export type WorkerApproveFormI9Payload = {
  __typename: 'WorkerApproveFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerAssignJobWorkplace */
export type WorkerAssignJobWorkplacePayload = {
  __typename: 'WorkerAssignJobWorkplacePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeActive */
export type WorkerChangeActivePayload = {
  __typename: 'WorkerChangeActivePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAddress */
export type WorkerChangeAddressPayload = {
  __typename: 'WorkerChangeAddressPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAvatar */
export type WorkerChangeAvatarPayload = {
  __typename: 'WorkerChangeAvatarPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerClosedFormI9 */
export type WorkerClosedFormI9Payload = {
  __typename: 'WorkerClosedFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerCreate */
export type WorkerCreatePayload = {
  __typename: 'WorkerCreatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerCreateWilloInterview */
export type WorkerCreateWilloInterviewPayload = {
  __typename: 'WorkerCreateWilloInterviewPayload';
  link: Scalars['String']['output'];
};

export enum WorkerDeactivatedTypeEnum {
  ADMIN_DEACTIVATED = 'ADMIN_DEACTIVATED',
  FNC_DEACTIVATED = 'FNC_DEACTIVATED',
  WORKER_DEACTIVATED = 'WORKER_DEACTIVATED',
}

/** Autogenerated return type of WorkerDisable */
export type WorkerDisablePayload = {
  __typename: 'WorkerDisablePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerDisapproveFormI9 */
export type WorkerDisapproveFormI9Payload = {
  __typename: 'WorkerDisapproveFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerEnable */
export type WorkerEnablePayload = {
  __typename: 'WorkerEnablePayload';
  worker: Worker;
};

/** Available filter operations for a hired Worker */
export type WorkerFilterSetInput = {
  active?: InputMaybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  chatUid?: InputMaybe<Scalars['String']['input']>;
  coords?: InputMaybe<PointInput>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hired?: InputMaybe<Scalars['Boolean']['input']>;
  jobsDropped?: InputMaybe<IntCompareFilterInput>;
  jobsFailed?: InputMaybe<IntCompareFilterInput>;
  jobsWorked?: InputMaybe<IntCompareFilterInput>;
  jurisdiction?: InputMaybe<Array<Scalars['String']['input']>>;
  ownsCar?: InputMaybe<BooleanEqualityFilterInput>;
  query?: InputMaybe<TextSearchFilterInput>;
  relationship?: InputMaybe<WorkerRelationshipFilterInput>;
  taxType?: InputMaybe<TaxTypeEqualityFilterInput>;
  tcAcceptedAt?: InputMaybe<DateTimeCompareFilterInput>;
  workerCertificates?: InputMaybe<Array<IntCompareFilterInput>>;
  workerSkills?: InputMaybe<Array<IntCompareFilterInput>>;
};

/** Worker interview */
export type WorkerInterview = {
  __typename: 'WorkerInterview';
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  status: WorkerInterviewStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
  workerInterviewAnswers: Array<WorkerInterviewAnswer>;
};

/** Worker interview answer */
export type WorkerInterviewAnswer = {
  __typename: 'WorkerInterviewAnswer';
  answer: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interviewQuestion: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of WorkerInterviewCreate */
export type WorkerInterviewCreatePayload = {
  __typename: 'WorkerInterviewCreatePayload';
  workerInterview: WorkerInterview;
};

export enum WorkerInterviewStatusEnum {
  NOT_PASSED = 'NOT_PASSED',
  PASSED = 'PASSED',
}

/** Autogenerated return type of WorkerInterviewUpdate */
export type WorkerInterviewUpdatePayload = {
  __typename: 'WorkerInterviewUpdatePayload';
  workerInterview: WorkerInterview;
};

/** Autogenerated return type of WorkerInterviewUpdateV2 */
export type WorkerInterviewUpdateV2Payload = {
  __typename: 'WorkerInterviewUpdateV2Payload';
  willoWorkerInterview: WilloWorkerInterview;
};

/** Autogenerated return type of WorkerLogin */
export type WorkerLoginPayload = {
  __typename: 'WorkerLoginPayload';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  worker: Worker;
};

/** Autogenerated return type of WorkerOnboard */
export type WorkerOnboardPayload = {
  __typename: 'WorkerOnboardPayload';
  worker: Worker;
};

/** Pagination container for Worker */
export type WorkerPage = {
  __typename: 'WorkerPage';
  items: Array<Worker>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of WorkerProspectFormI9 */
export type WorkerProspectFormI9Payload = {
  __typename: 'WorkerProspectFormI9Payload';
  worker: Worker;
};

/** Worker reference */
export type WorkerReference = {
  __typename: 'WorkerReference';
  city: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  duration: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  employerName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  summary: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Maybe<Worker>;
};

/** Worker relationship */
export type WorkerRelationship = {
  __typename: 'WorkerRelationship';
  comments: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  kind: RelationshipKindEnum;
  reason: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

/** filter workers between a relationship with a customer */
export type WorkerRelationshipFilterInput = {
  customerId: Scalars['ID']['input'];
  kind: RelationshipKindEnum;
};

/** Available filter operations for a Worker Relationship */
export type WorkerRelationshipFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdiction?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WorkerRemoveCertificate */
export type WorkerRemoveCertificatePayload = {
  __typename: 'WorkerRemoveCertificatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerRemoveSkill */
export type WorkerRemoveSkillPayload = {
  __typename: 'WorkerRemoveSkillPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerResendCheckrInvitation */
export type WorkerResendCheckrInvitationPayload = {
  __typename: 'WorkerResendCheckrInvitationPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerResetVerification */
export type WorkerResetVerificationPayload = {
  __typename: 'WorkerResetVerificationPayload';
  worker: Worker;
};

/** Represents a HelloSign Signature Request for a Worker */
export type WorkerSignatureRequest = {
  __typename: 'WorkerSignatureRequest';
  documentUrl: Maybe<Scalars['String']['output']>;
  hellosignId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  requestStatus: Maybe<SignatureRequestStatusEnum>;
  requestType: SignatureRequestTypeEnum;
  tenantId: Scalars['ID']['output'];
  workerId: Scalars['ID']['output'];
};

/** Autogenerated return type of WorkerSignup */
export type WorkerSignupPayload = {
  __typename: 'WorkerSignupPayload';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  region: Maybe<Region>;
  worker: Worker;
};

/** Worker skills */
export type WorkerSkill = {
  __typename: 'WorkerSkill';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  jobsWorked: Maybe<Scalars['Int']['output']>;
  skill: Skill;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  worker: Worker;
};

export enum WorkerStatusEnum {
  CANDIDATE = 'CANDIDATE',
  HIRED = 'HIRED',
}

/** Autogenerated return type of WorkerTentativeNcFormI9 */
export type WorkerTentativeNcFormI9Payload = {
  __typename: 'WorkerTentativeNcFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateApplicant */
export type WorkerUpdateApplicantPayload = {
  __typename: 'WorkerUpdateApplicantPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateBackgroundCheck */
export type WorkerUpdateBackgroundCheckPayload = {
  __typename: 'WorkerUpdateBackgroundCheckPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateCandidate */
export type WorkerUpdateCandidatePayload = {
  __typename: 'WorkerUpdateCandidatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateCheckInterview */
export type WorkerUpdateCheckInterviewPayload = {
  __typename: 'WorkerUpdateCheckInterviewPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateProfile */
export type WorkerUpdateProfilePayload = {
  __typename: 'WorkerUpdateProfilePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateRating */
export type WorkerUpdateRatingPayload = {
  __typename: 'WorkerUpdateRatingPayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of WorkerUpdateStatus */
export type WorkerUpdateStatusPayload = {
  __typename: 'WorkerUpdateStatusPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateTaxType */
export type WorkerUpdateTaxTypePayload = {
  __typename: 'WorkerUpdateTaxTypePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateWilloInterview */
export type WorkerUpdateWilloInterviewPayload = {
  __typename: 'WorkerUpdateWilloInterviewPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerVerify */
export type WorkerVerifyPayload = {
  __typename: 'WorkerVerifyPayload';
  worker: Worker;
};

export type ZipcodeData = {
  __typename: 'ZipcodeData';
  /** Unique identifier for the state */
  stateId: Scalars['String']['output'];
  /** zip field */
  zip: Scalars['String']['output'];
};

export type DeviceLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type DeviceLogoutMutation = {
  __typename: 'Mutation';
  deviceLogout: void | null;
};

export type RemoveWorkerReferenceMutationVariables = Exact<{
  workerReferenceId: Scalars['ID']['input'];
}>;

export type RemoveWorkerReferenceMutation = {
  __typename: 'Mutation';
  workerRemoveReference: void | null;
};

export type GetPlaidLinkTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlaidLinkTokenQuery = {
  __typename: 'Query';
  plaidLinkToken: string;
};

export type GetWorkerForJobTenantQueryVariables = Exact<{
  filters: InputMaybe<WorkerFilterSetInput>;
}>;

export type GetWorkerForJobTenantQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    workers: {
      __typename: 'WorkerPage';
      items: Array<{
        __typename: 'Worker';
        id: string;
        onboardingStatus: OnboardingStatusEnum | null;
        readyToWork: boolean;
        workerJurisdictions: Array<{
          __typename: 'Jurisdiction';
          state: string;
        }> | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
        };
      }>;
    };
  };
};

export type GetWorkersForJobQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
  filters: InputMaybe<WorkerFilterSetInput>;
}>;

export type GetWorkersForJobQuery = {
  __typename: 'Query';
  jobHireableWorkers: Array<{
    __typename: 'Worker';
    id: string;
    onboardingStatus: OnboardingStatusEnum | null;
    readyToWork: boolean;
    workerJurisdictions: Array<{
      __typename: 'Jurisdiction';
      state: string;
    }> | null;
    user: {
      __typename: 'User';
      id: string;
      email: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
    };
  }> | null;
};

export type AccountFragmentFragment = {
  __typename: 'Account';
  id: string;
  name: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  cutOff: InvoiceCutOffEnum | null;
  monthlyCutOff: boolean;
  billingWeek: BillingWeekEnum | null;
  consolidateUnprocessedCharges: boolean | null;
  groupBy: InvoiceGroupByEnum | null;
  markupPercent: number | null;
  rateType: AccountRateTypeEnum;
  canDisable: boolean | null;
  active: boolean;
  customer: {
    __typename: 'Customer';
    id: string;
    invoiceDueDate: string;
    logoUrl: string | null;
    name: string;
    jobTaxType: string | null;
    status: number;
    ltaAllowed: boolean | null;
    markupPercent: number;
    checkInRadiusFeet: number;
    rateType: AccountRateTypeEnum;
    addresses: Array<{
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      city: string | null;
      name: string | null;
      state: string | null;
      zip: string | null;
      active: boolean | null;
      jobsCount: number | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
      rates: Array<{
        __typename: 'Rate';
        id: string;
        cost: number;
        pay: number;
        account: { __typename: 'Account'; id: string; name: string } | null;
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          active: boolean | null;
          zip: string | null;
        } | null;
        skill: {
          __typename: 'Skill';
          id: string;
          name: string;
          imageUrl: string | null;
        } | null;
      }>;
    }>;
    accounts: Array<{
      __typename: 'Account';
      id: string;
      name: string;
      markupPercent: number | null;
      rateType: AccountRateTypeEnum;
      currentPaymentMethod: string | null;
      active: boolean;
      canDisable: boolean | null;
      defaultContact: { __typename: 'CustomerAdmin'; id: string };
    }>;
    admins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        active: boolean;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          email: string;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          emailConfirmed: boolean | null;
          channelId: string;
        };
      }>;
    };
    workerRelationships: Array<{
      __typename: 'WorkerRelationship';
      id: string;
      kind: RelationshipKindEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
  };
  defaultContact: {
    __typename: 'CustomerAdmin';
    id: string;
    avatarUrl: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    };
  };
};

export type SimpleCommentFragment = {
  __typename: 'Comment';
  id: string;
  body: string | null;
  createdAt: string;
  actor:
    | {
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        active: boolean;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          email: string;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          emailConfirmed: boolean | null;
          channelId: string;
        };
      }
    | {
        __typename: 'TenantAdmin';
        id: string;
        avatarUrl: string | null;
        tenant: { __typename: 'Tenant'; id: string };
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          email: string;
          firstName: string;
          lastName: string;
        };
      };
  commentable:
    | { __typename: 'Account' }
    | { __typename: 'Customer' }
    | { __typename: 'CustomerAdmin' }
    | { __typename: 'Job' }
    | { __typename: 'Order' }
    | { __typename: 'Worker' };
};

export type CurrentAgencyFragment = {
  __typename: 'Agency';
  id: string;
  key: string;
  name: string;
  checkinWindow: any;
  maxCheckinDistance: any;
  email: string;
  phoneNumber: string;
  address: {
    __typename: 'Address';
    addressLine1: string | null;
    addressLine2: string | null;
    timezone: string;
    zip: string | null;
    city: string | null;
    state: string | null;
  } | null;
};

export type CurrentCustomerAdminFragment = {
  __typename: 'CustomerAdmin';
  id: string;
  avatarUrl: string | null;
  role: number;
  user: {
    __typename: 'User';
    id: string;
    email: string;
    firstName: string;
    language: LanguageEnum;
    lastName: string;
    channelId: string;
    roleId: UserRoleEnum;
  };
  customer: {
    __typename: 'Customer';
    id: string;
    name: string;
    status: number;
    onboardingStatus: CustomerOnboardingStatusEnum;
    ltaAllowed: boolean | null;
  };
};

export type CurrentTenantAdminFragment = {
  __typename: 'TenantAdmin';
  id: string;
  avatarUrl: string | null;
  role: number;
  pendingCustomers: number;
  user: {
    __typename: 'User';
    id: string;
    active: boolean;
    firstName: string;
    language: LanguageEnum;
    lastName: string;
    email: string;
    emailConfirmed: boolean | null;
    phoneNumber: string | null;
    allowedPlatform: number;
    channelId: string;
    roleId: UserRoleEnum;
  };
};

export type CurrentTenantFragment = {
  __typename: 'Tenant';
  id: string;
  key: string;
  name: string;
  logoUrl: string | null;
};

export type ListWorkersForAutocompleteQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<WorkerFilterSetInput>;
}>;

export type ListWorkersForAutocompleteQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    workers: {
      __typename: 'WorkerPage';
      items: Array<{
        __typename: 'Worker';
        id: string;
        onboardingStatus: OnboardingStatusEnum | null;
        readyToWork: boolean;
        avatarUrl: string | null;
        workerJurisdictions: Array<{
          __typename: 'Jurisdiction';
          state: string;
        }> | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      }>;
    };
  };
};

export type OrderListItemFragment = {
  __typename: 'Order';
  id: string;
  uuid: string | null;
  startAt: string;
  endAt: string;
  jobsCount: number;
  status: OrderStatusEnum;
  account: { __typename: 'Account'; id: string; name: string };
  jobs: Array<{
    __typename: 'Job';
    id: string;
    filled: boolean;
    cancelled: boolean;
    taxType: TaxTypeEnum | null;
    approvalStatus: JobApprovalStatusEnum;
    timesheets: Array<{
      __typename: 'Timesheet';
      id: string;
      status: TimesheetStatusEnum;
    }>;
    jobWorkers: Array<{
      __typename: 'JobWorker';
      showed: boolean | null;
      status: JobWorkerStatusEnum;
    }>;
    skill: { __typename: 'Skill'; id: string; imageUrl: string | null };
  }>;
};

export type SimpleEarningsFragment = {
  __typename: 'PayrollItemEarning';
  amount: string;
  description: string | null;
  hours: number | null;
  earningType: PayrollItemEarningEnum;
  address: {
    __typename: 'Address';
    id: string;
    addressLine1: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  } | null;
  timesheet: {
    __typename: 'Timesheet';
    id: string;
    updatedByType: string | null;
    payRate: number;
    tipAmount: any | null;
    updatedBy: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    job: {
      __typename: 'Job';
      id: string;
      customer: { __typename: 'Customer'; id: string; name: string };
      account: { __typename: 'Account'; id: string; name: string };
      order: { __typename: 'Order'; id: string };
    };
  } | null;
};

export type SimpleTotalsFragment = {
  __typename: 'PayrollTotals';
  companyTaxes: string;
  companyBenefits: string;
  postTaxDeductions: string;
  liability: string;
  cashRequirement: string;
  employeeGross: string;
  employeeTaxes: string;
  employeeBenefits: string;
  employeeNet: string;
  employeeReimbursements: string;
};

export type SimpleTaxesFragment = {
  __typename: 'PayrollItemTax';
  amount: string;
  description: string;
  payer: string;
  checkTaxId: string;
};

export type SimpleContractorPaymentsFragment = {
  __typename: 'ContractorPayment';
  id: string;
  netPay: string | null;
  paymentMethod: string;
  status: PayrollItemStatusEnum;
  contractorPaymentItems: Array<{
    __typename: 'ContractorPaymentItem';
    amount: string;
    description: string | null;
    hours: number | null;
    earningType: PayrollItemEarningEnum;
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
    } | null;
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      updatedByType: string | null;
      payRate: number;
      tipAmount: any | null;
      updatedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      job: {
        __typename: 'Job';
        id: string;
        customer: { __typename: 'Customer'; id: string; name: string };
        account: { __typename: 'Account'; id: string; name: string };
        order: { __typename: 'Order'; id: string };
      };
    } | null;
  }>;
  worker: {
    __typename: 'Worker';
    user: {
      __typename: 'User';
      firstName: string;
      middleName: string | null;
      lastName: string;
    };
  } | null;
};

export type SimplePayrollItemFragment = {
  __typename: 'PayrollItem';
  id: string;
  netPay: string | null;
  paymentMethod: string;
  status: PayrollItemStatusEnum;
  earnings: Array<{
    __typename: 'PayrollItemEarning';
    amount: string;
    description: string | null;
    hours: number | null;
    earningType: PayrollItemEarningEnum;
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
    } | null;
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      updatedByType: string | null;
      payRate: number;
      tipAmount: any | null;
      updatedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      job: {
        __typename: 'Job';
        id: string;
        customer: { __typename: 'Customer'; id: string; name: string };
        account: { __typename: 'Account'; id: string; name: string };
        order: { __typename: 'Order'; id: string };
      };
    } | null;
  }>;
  worker: {
    __typename: 'Worker';
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
    };
  } | null;
  taxes: Array<{
    __typename: 'PayrollItemTax';
    amount: string;
    description: string;
    payer: string;
    checkTaxId: string;
  }>;
  deductions: Array<{
    __typename: 'PostTaxDeduction';
    amount: string;
    checkPtdId: string;
    description: string;
    ptdType: string;
  }> | null;
};

export type SimpleTagFragment = { __typename: 'Tag'; id: string; name: string };

export type AddCommentMutationVariables = Exact<{
  commentableId: Scalars['ID']['input'];
  commentableType: CommentableEnum;
  body: Scalars['String']['input'];
}>;

export type AddCommentMutation = {
  __typename: 'Mutation';
  commentableAddComment: {
    __typename: 'CommentableAddCommentPayload';
    comments: Array<{
      __typename: 'Comment';
      id: string;
      body: string | null;
      createdAt: string;
      actor:
        | {
            __typename: 'CustomerAdmin';
            id: string;
            acceptedAt: string | null;
            avatarUrl: string | null;
            active: boolean;
            user: {
              __typename: 'User';
              id: string;
              active: boolean;
              email: string;
              firstName: string;
              language: LanguageEnum;
              lastName: string;
              emailConfirmed: boolean | null;
              channelId: string;
            };
          }
        | {
            __typename: 'TenantAdmin';
            id: string;
            avatarUrl: string | null;
            tenant: { __typename: 'Tenant'; id: string };
            user: {
              __typename: 'User';
              id: string;
              active: boolean;
              email: string;
              firstName: string;
              lastName: string;
            };
          };
      commentable:
        | { __typename: 'Account' }
        | { __typename: 'Customer' }
        | { __typename: 'CustomerAdmin' }
        | { __typename: 'Job' }
        | { __typename: 'Order' }
        | { __typename: 'Worker' };
    }>;
  };
};

export type GetUploadUrlQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;

export type GetUploadUrlQuery = {
  __typename: 'Query';
  uploadUrl: { __typename: 'UploadURL'; key: string; url: string };
};

export type UserChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type UserChangePasswordMutation = {
  __typename: 'Mutation';
  userChangePassword: void | null;
};

export type ListAgenciesQueryVariables = Exact<{ [key: string]: never }>;

export type ListAgenciesQuery = {
  __typename: 'Query';
  agencies: Array<{
    __typename: 'Agency';
    id: string;
    key: string;
    name: string;
    checkinWindow: any;
    maxCheckinDistance: any;
    email: string;
    phoneNumber: string;
    address: {
      __typename: 'Address';
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      zip: string | null;
      city: string | null;
      state: string | null;
    } | null;
  }>;
};

export type ListCustomerAdminsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type ListCustomerAdminsQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    admins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        };
      }>;
    };
  };
};

export type DisableAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  active: Scalars['Boolean']['input'];
}>;

export type DisableAccountMutation = {
  __typename: 'Mutation';
  accountUpdateActive: {
    __typename: 'AccountUpdateActivePayload';
    account: { __typename: 'Account'; active: boolean; id: string };
  };
};

export type GetAccountQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type GetAccountQuery = {
  __typename: 'Query';
  account: {
    __typename: 'Account';
    id: string;
    name: string;
    addressLine1: string | null;
    addressLine2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    cutOff: InvoiceCutOffEnum | null;
    monthlyCutOff: boolean;
    billingWeek: BillingWeekEnum | null;
    consolidateUnprocessedCharges: boolean | null;
    groupBy: InvoiceGroupByEnum | null;
    markupPercent: number | null;
    rateType: AccountRateTypeEnum;
    canDisable: boolean | null;
    active: boolean;
    customer: {
      __typename: 'Customer';
      id: string;
      invoiceDueDate: string;
      logoUrl: string | null;
      name: string;
      jobTaxType: string | null;
      status: number;
      ltaAllowed: boolean | null;
      markupPercent: number;
      checkInRadiusFeet: number;
      rateType: AccountRateTypeEnum;
      addresses: Array<{
        __typename: 'Address';
        id: string;
        addressLine1: string | null;
        addressLine2: string | null;
        timezone: string;
        city: string | null;
        name: string | null;
        state: string | null;
        zip: string | null;
        active: boolean | null;
        jobsCount: number | null;
        coords: { __typename: 'Point'; latitude: number; longitude: number };
        rates: Array<{
          __typename: 'Rate';
          id: string;
          cost: number;
          pay: number;
          account: { __typename: 'Account'; id: string; name: string } | null;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            active: boolean | null;
            zip: string | null;
          } | null;
          skill: {
            __typename: 'Skill';
            id: string;
            name: string;
            imageUrl: string | null;
          } | null;
        }>;
      }>;
      accounts: Array<{
        __typename: 'Account';
        id: string;
        name: string;
        markupPercent: number | null;
        rateType: AccountRateTypeEnum;
        currentPaymentMethod: string | null;
        active: boolean;
        canDisable: boolean | null;
        defaultContact: { __typename: 'CustomerAdmin'; id: string };
      }>;
      admins: {
        __typename: 'CustomerAdminPage';
        items: Array<{
          __typename: 'CustomerAdmin';
          id: string;
          acceptedAt: string | null;
          avatarUrl: string | null;
          active: boolean;
          user: {
            __typename: 'User';
            id: string;
            active: boolean;
            email: string;
            firstName: string;
            language: LanguageEnum;
            lastName: string;
            emailConfirmed: boolean | null;
            channelId: string;
          };
        }>;
      };
      workerRelationships: Array<{
        __typename: 'WorkerRelationship';
        id: string;
        kind: RelationshipKindEnum;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      }>;
    };
    defaultContact: {
      __typename: 'CustomerAdmin';
      id: string;
      avatarUrl: string | null;
      user: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string | null;
      };
    };
  };
};

export type GetAccountOrdersQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type GetAccountOrdersQuery = {
  __typename: 'Query';
  account: {
    __typename: 'Account';
    id: string;
    orders: {
      __typename: 'OrderPage';
      items: Array<{
        __typename: 'Order';
        id: string;
        uuid: string | null;
        startAt: string;
        endAt: string;
        jobsCount: number;
        status: OrderStatusEnum;
        account: { __typename: 'Account'; id: string; name: string };
        jobs: Array<{
          __typename: 'Job';
          id: string;
          filled: boolean;
          cancelled: boolean;
          taxType: TaxTypeEnum | null;
          approvalStatus: JobApprovalStatusEnum;
          timesheets: Array<{
            __typename: 'Timesheet';
            id: string;
            status: TimesheetStatusEnum;
          }>;
          jobWorkers: Array<{
            __typename: 'JobWorker';
            showed: boolean | null;
            status: JobWorkerStatusEnum;
          }>;
          skill: { __typename: 'Skill'; id: string; imageUrl: string | null };
        }>;
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type AttachCardToAccountMutationVariables = Exact<{
  account: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;

export type AttachCardToAccountMutation = {
  __typename: 'Mutation';
  accountAttachCard: {
    __typename: 'AccountAttachCardPayload';
    attach: boolean;
  };
};

export type DettachPaymentMethodMutationVariables = Exact<{
  account: Scalars['ID']['input'];
  paymentMethodType: PaymentMethodEnum;
  paymentMethodId: Scalars['String']['input'];
}>;

export type DettachPaymentMethodMutation = {
  __typename: 'Mutation';
  accountDetachPaymentMethod: {
    __typename: 'AccountDetachPaymentMethodPayload';
    detach: boolean;
  };
};

export type GetAccountPaymentMethodsQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type GetAccountPaymentMethodsQuery = {
  __typename: 'Query';
  accountPaymentMethods: {
    __typename: 'PaymentMethodInfo';
    currentPaymentMethod: string | null;
    cards: Array<{
      __typename: 'StripePaymentMethod';
      id: string;
      created: number;
      customer: string;
      card: {
        __typename: 'StripeCard';
        brand: string;
        country: string;
        expMonth: number;
        expYear: string;
        fingerprint: string;
        funding: string;
        last4: string;
      };
    }>;
    bankAccounts: Array<{
      __typename: 'StripeBankAccount';
      accountHolderName: string | null;
      routingNumber: string;
      status: string;
      accountHolderType: string | null;
      accountType: string | null;
      bankName: string;
      country: string;
      currency: string;
      customer: string;
      fingerprint: string;
      id: string;
      last4: string;
    }>;
  };
};

export type AttachBankAccountMutationVariables = Exact<{
  account: Scalars['ID']['input'];
  publicToken: Scalars['String']['input'];
  bankAccountId: Scalars['ID']['input'];
}>;

export type AttachBankAccountMutation = {
  __typename: 'Mutation';
  accountAttachBankAccount: {
    __typename: 'AccountAttachBankAccountPayload';
    attach: boolean;
    bankAccount: { __typename: 'StripeBankAccount'; id: string } | null;
  };
};

export type SetCurrentPaymentMethodMutationVariables = Exact<{
  account: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;

export type SetCurrentPaymentMethodMutation = {
  __typename: 'Mutation';
  accountSetCurrentPaymentMethod: {
    __typename: 'AccountSetCurrentPaymentMethodPayload';
    transaction: boolean;
  };
};

export type UpdateAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  defaultContactId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
  state: Scalars['String']['input'];
  markupPercent: InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdateAccountMutation = {
  __typename: 'Mutation';
  accountUpdate: {
    __typename: 'AccountUpdatePayload';
    account: {
      __typename: 'Account';
      id: string;
      name: string;
      updatedAt: string;
      defaultContact: {
        __typename: 'CustomerAdmin';
        id: string;
        avatarUrl: string | null;
        user: { __typename: 'User'; firstName: string; lastName: string };
      };
    };
  };
};

export type UpdateAccountCutOffMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  billingWeek: InputMaybe<BillingWeekEnum>;
  invoiceCutOff: InputMaybe<InvoiceCutOffEnum>;
  monthlyCutOff: InputMaybe<Scalars['Boolean']['input']>;
  consolidateUnprocessedCharges: InputMaybe<Scalars['Boolean']['input']>;
  groupBy: InputMaybe<InvoiceGroupByEnum>;
}>;

export type UpdateAccountCutOffMutation = {
  __typename: 'Mutation';
  accountCutOffUpdate: {
    __typename: 'AccountCutOffUpdatePayload';
    account: { __typename: 'Account'; id: string };
  };
};

export type AuthenticateChatServiceMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AuthenticateChatServiceMutation = {
  __typename: 'Mutation';
  chatServiceAuthenticate: string;
};

export type UpdateUserProfileMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  email: InputMaybe<Scalars['Email']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  roleId: UserRoleEnum;
}>;

export type UpdateUserProfileMutation = {
  __typename: 'Mutation';
  userUpdate: {
    __typename: 'UserUpdatePayload';
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
      phoneNumber: string | null;
    };
  };
};

export type ChangeTenantAdminAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String']['input'];
  tenantAdminId: Scalars['ID']['input'];
}>;

export type ChangeTenantAdminAvatarMutation = {
  __typename: 'Mutation';
  tenantAdminChangeAvatar: {
    __typename: 'TenantAdminChangeAvatarPayload';
    tenantAdmin: {
      __typename: 'TenantAdmin';
      id: string;
      avatarUrl: string | null;
    };
  };
};

export type ChangeStatusUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  active: Scalars['Boolean']['input'];
}>;

export type ChangeStatusUserMutation = {
  __typename: 'Mutation';
  userChangeActive: {
    __typename: 'UserChangeActivePayload';
    user: { __typename: 'User'; id: string; active: boolean };
  };
};

export type TenantAdminItemFragment = {
  __typename: 'TenantAdmin';
  id: string;
  avatarUrl: string | null;
  tenant: { __typename: 'Tenant'; id: string };
  user: {
    __typename: 'User';
    id: string;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
  };
};

export type CreateTenantAdminMutationVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roleId: UserRoleEnum;
}>;

export type CreateTenantAdminMutation = {
  __typename: 'Mutation';
  tenantAdminInvite: {
    __typename: 'TenantAdminInvitePayload';
    tenantAdmin: {
      __typename: 'TenantAdmin';
      id: string;
      avatarUrl: string | null;
      tenant: { __typename: 'Tenant'; id: string };
      user: {
        __typename: 'User';
        id: string;
        active: boolean;
        email: string;
        firstName: string;
        lastName: string;
      };
    };
  };
};

export type ListCustomerAdminQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<CustomerAdminFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type ListCustomerAdminQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customerAdmins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        customer: {
          __typename: 'Customer';
          id: string;
          logoUrl: string | null;
          name: string;
        };
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          email: string;
          emailConfirmed: boolean | null;
          phoneNumber: string | null;
          allowedPlatform: number;
          channelId: string;
          roleId: UserRoleEnum;
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type ListTenantAdminQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<TenantAdminFilterSetInput>;
}>;

export type ListTenantAdminQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    tenant: {
      __typename: 'Tenant';
      id: string;
      admins: Array<{
        __typename: 'TenantAdmin';
        id: string;
        avatarUrl: string | null;
        role: number;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          email: string;
          emailConfirmed: boolean | null;
          phoneNumber: string | null;
          allowedPlatform: number;
          channelId: string;
          roleId: UserRoleEnum;
        };
      }>;
    };
  };
};

export type AutocompleteCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
  customerStatus: InputMaybe<
    | Array<Scalars['NonNegativeInt']['input']>
    | Scalars['NonNegativeInt']['input']
  >;
  rateType: InputMaybe<Array<AccountRateTypeEnum> | AccountRateTypeEnum>;
}>;

export type AutocompleteCustomersQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        id: string;
        name: string;
        jobTaxType: string | null;
        status: number;
        ltaAllowed: boolean | null;
        admins: {
          __typename: 'CustomerAdminPage';
          items: Array<{
            __typename: 'CustomerAdmin';
            id: string;
            user: {
              __typename: 'User';
              firstName: string;
              lastName: string;
              id: string;
            };
          }>;
        };
        accounts: Array<{
          __typename: 'Account';
          id: string;
          name: string;
          markupPercent: number | null;
          rateType: AccountRateTypeEnum;
          active: boolean;
          currentPaymentMethod: string | null;
          customer: {
            __typename: 'Customer';
            id: string;
            logoUrl: string | null;
            name: string;
          };
          defaultContact: {
            __typename: 'CustomerAdmin';
            id: string;
            avatarUrl: string | null;
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              phoneNumber: string | null;
            };
          };
        }>;
        addresses: Array<{
          __typename: 'Address';
          id: string;
          active: boolean | null;
          addressLine1: string | null;
          addressLine2: string | null;
          timezone: string;
          city: string | null;
          name: string | null;
          state: string | null;
          zip: string | null;
          coords: { __typename: 'Point'; latitude: number; longitude: number };
          rates: Array<{
            __typename: 'Rate';
            id: string;
            pay: number;
            cost: number;
            account: { __typename: 'Account'; id: string } | null;
            address: {
              __typename: 'Address';
              id: string;
              active: boolean | null;
            } | null;
            skill: { __typename: 'Skill'; id: string; name: string } | null;
          }>;
        }>;
      }>;
    };
  };
};

export type ListUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type ListUniformsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    uniforms: Array<{
      __typename: 'Uniform';
      id: string;
      name: string;
      imageUrl: string | null;
      defaultInstructions: string;
    }>;
  };
};

export type GetRateRangeQueryVariables = Exact<{
  addressId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
}>;

export type GetRateRangeQuery = {
  __typename: 'Query';
  rateRange: Array<{
    __typename: 'RateRange';
    maximum: number;
    minimum: number;
  }>;
};

export type CreateGigOrderMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  jobs: Array<JobInput> | JobInput;
}>;

export type CreateGigOrderMutation = {
  __typename: 'Mutation';
  orderCreate: {
    __typename: 'OrderCreatePayload';
    workersHired: string | null;
    order: {
      __typename: 'Order';
      id: string;
      uuid: string | null;
      jobsCount: number;
      jobs: Array<{ __typename: 'Job'; id: string }>;
    };
  };
};

export type CreateGroupJobOfferMutationVariables = Exact<{
  jobIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  workerIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type CreateGroupJobOfferMutation = {
  __typename: 'Mutation';
  jobOfferGroupCreate: {
    __typename: 'JobOfferGroupCreatePayload';
    status: boolean;
  };
};

export type GetSurgeRatesQueryVariables = Exact<{
  fromDate: Scalars['ISO8601Date']['input'];
}>;

export type GetSurgeRatesQuery = {
  __typename: 'Query';
  surgeRatesFromDate: Array<{
    __typename: 'SurgeRate';
    id: string;
    date: string;
    rate: number;
  }>;
};

export type GetWorkerRelationshipsByCustomerQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  filters: InputMaybe<WorkerRelationshipFilterSetInput>;
}>;

export type GetWorkerRelationshipsByCustomerQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    workerRelationships: Array<{
      __typename: 'WorkerRelationship';
      id: string;
      kind: RelationshipKindEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
        skills: Array<{ __typename: 'Skill'; id: string }>;
      };
    }>;
  };
};

export type DefaultRateByCityQueryVariables = Exact<{
  skillId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
}>;

export type DefaultRateByCityQuery = {
  __typename: 'Query';
  defaultRatesBySkill: {
    __typename: 'DefaultRate';
    id: string;
    costRate: number | null;
    payRate: number | null;
    skill: {
      __typename: 'Skill';
      id: string;
      name: string;
      imageUrl: string | null;
      descriptionEn: string | null;
      category: {
        __typename: 'SkillCategory';
        id: string;
        name: string | null;
        imageUrl: string | null;
      } | null;
    } | null;
  };
};

export type FetchNearestRatesQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
}>;

export type FetchNearestRatesQuery = {
  __typename: 'Query';
  fetchRates: {
    __typename: 'FetchRates';
    rates: Array<{
      __typename: 'Rate';
      cost: number;
      id: string;
      pay: number;
    }> | null;
    defaultRates: Array<{
      __typename: 'DefaultRate';
      costRate: number | null;
      id: string;
      payRate: number | null;
    }> | null;
  };
};

export type ListSkillCategoriesByDefaultRatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListSkillCategoriesByDefaultRatesQuery = {
  __typename: 'Query';
  defaultRates: Array<{
    __typename: 'DefaultRate';
    id: string;
    costRate: number | null;
    payRate: number | null;
    skill: {
      __typename: 'Skill';
      id: string;
      name: string;
      imageUrl: string | null;
      descriptionEn: string | null;
      gravyTrained: boolean | null;
      category: {
        __typename: 'SkillCategory';
        id: string;
        name: string | null;
        imageUrl: string | null;
      } | null;
    } | null;
  }>;
};

export type ListSkillCategoriesByCustomRatesQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type ListSkillCategoriesByCustomRatesQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    addresses: Array<{
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      rates: Array<{
        __typename: 'Rate';
        id: string;
        account: { __typename: 'Account'; id: string } | null;
        skill: {
          __typename: 'Skill';
          id: string;
          descriptionEn: string | null;
          name: string;
          imageUrl: string | null;
          gravyTrained: boolean | null;
          category: {
            __typename: 'SkillCategory';
            id: string;
            name: string | null;
            imageUrl: string | null;
          } | null;
        } | null;
      }>;
    }>;
  };
};

export type ListAgencyCustomerCertificatesQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  customerFilters: InputMaybe<CustomerFilterSetInput>;
}>;

export type ListAgencyCustomerCertificatesQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
      certificateType: CertificateTypeEnum;
    }>;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        certificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
          certificateType: CertificateTypeEnum;
        }>;
      }>;
    };
  };
};

export type CreateAccountMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  defaultContactId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  city: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
  state: Scalars['String']['input'];
  cutOff: InputMaybe<InvoiceCutOffEnum>;
  monthlyCutOff: InputMaybe<Scalars['Boolean']['input']>;
  billingWeek: InputMaybe<BillingWeekEnum>;
  consolidateUnprocessedCharges: InputMaybe<Scalars['Boolean']['input']>;
  groupBy: InputMaybe<InvoiceGroupByEnum>;
  markupPercent: InputMaybe<Scalars['Float']['input']>;
  rateType: AccountRateTypeEnum;
}>;

export type CreateAccountMutation = {
  __typename: 'Mutation';
  accountCreate: {
    __typename: 'AccountCreatePayload';
    account: {
      __typename: 'Account';
      id: string;
      name: string;
      markupPercent: number | null;
      rateType: AccountRateTypeEnum;
      currentPaymentMethod: string | null;
      active: boolean;
      canDisable: boolean | null;
      defaultContact: { __typename: 'CustomerAdmin'; id: string };
    };
  };
};

export type ActiveAddressMutationVariables = Exact<{
  addressId: Scalars['ID']['input'];
}>;

export type ActiveAddressMutation = {
  __typename: 'Mutation';
  addressValidate: {
    __typename: 'AddressValidatePayload';
    address: {
      __typename: 'Address';
      id: string;
      active: boolean | null;
      addressLine1: string | null;
      addressLine2: string | null;
      city: string | null;
      name: string | null;
      state: string | null;
      zip: string | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
    };
  };
};

export type CreateAddressMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
  coords: PointInput;
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
}>;

export type CreateAddressMutation = {
  __typename: 'Mutation';
  addressCreate: {
    __typename: 'AddressCreatePayload';
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      city: string | null;
      name: string | null;
      state: string | null;
      zip: string | null;
      active: boolean | null;
      jobsCount: number | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
      rates: Array<{
        __typename: 'Rate';
        id: string;
        cost: number;
        pay: number;
        account: { __typename: 'Account'; id: string; name: string } | null;
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          active: boolean | null;
          zip: string | null;
        } | null;
        skill: {
          __typename: 'Skill';
          id: string;
          name: string;
          imageUrl: string | null;
        } | null;
      }>;
    };
  };
};

export type DeleteAddressMutationVariables = Exact<{
  addressId: Scalars['ID']['input'];
}>;

export type DeleteAddressMutation = {
  __typename: 'Mutation';
  addressDelete: { __typename: 'AddressDeletePayload'; success: boolean };
};

export type UpdateAddressMutationVariables = Exact<{
  addressId: Scalars['ID']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
  coords: PointInput;
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
}>;

export type UpdateAddressMutation = {
  __typename: 'Mutation';
  addressUpdate: {
    __typename: 'AddressUpdatePayload';
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      city: string | null;
      name: string | null;
      state: string | null;
      zip: string | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
    };
  };
};

export type GetCustomersAdminsPaginetedQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  page: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage: InputMaybe<Scalars['PositiveInt']['input']>;
}>;

export type GetCustomersAdminsPaginetedQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    admins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        active: boolean;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          email: string;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          emailConfirmed: boolean | null;
          channelId: string;
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type InviteAdminMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
}>;

export type InviteAdminMutation = {
  __typename: 'Mutation';
  customerInviteAdmin: {
    __typename: 'CustomerInviteAdminPayload';
    customerAdmin: {
      __typename: 'CustomerAdmin';
      id: string;
      acceptedAt: string | null;
      avatarUrl: string | null;
      active: boolean;
      user: {
        __typename: 'User';
        id: string;
        active: boolean;
        email: string;
        firstName: string;
        language: LanguageEnum;
        lastName: string;
        emailConfirmed: boolean | null;
        channelId: string;
      };
    };
  };
};

export type CreateRateMutationVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  skillId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
  baseCost: Scalars['PositiveInt']['input'];
  basePay: Scalars['PositiveInt']['input'];
}>;

export type CreateRateMutation = {
  __typename: 'Mutation';
  rateCreate: {
    __typename: 'RateCreatePayload';
    rate: {
      __typename: 'Rate';
      id: string;
      cost: number;
      pay: number;
      account: { __typename: 'Account'; id: string; name: string } | null;
      address: {
        __typename: 'Address';
        id: string;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
      } | null;
      skill: { __typename: 'Skill'; id: string; name: string } | null;
    };
  };
};

export type CustomRateCreateBatchMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  agencyId: Scalars['ID']['input'];
  baseCost: Scalars['PositiveInt']['input'];
  basePay: Scalars['PositiveInt']['input'];
  skillId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
}>;

export type CustomRateCreateBatchMutation = {
  __typename: 'Mutation';
  customRateCreateBatch: {
    __typename: 'CustomRateCreateBatchPayload';
    allCreated: boolean;
    message: string | null;
  };
};

export type DeleteRateMutationVariables = Exact<{
  rateId: Scalars['ID']['input'];
}>;

export type DeleteRateMutation = {
  __typename: 'Mutation';
  rateDelete: { __typename: 'RateDeletePayload'; deleted: boolean };
};

export type UpdateRateMutationVariables = Exact<{
  rateId: Scalars['ID']['input'];
  baseCost: Scalars['PositiveInt']['input'];
  basePay: Scalars['PositiveInt']['input'];
}>;

export type UpdateRateMutation = {
  __typename: 'Mutation';
  rateUpdate: {
    __typename: 'RateUpdatePayload';
    rate: {
      __typename: 'Rate';
      id: string;
      cost: number;
      pay: number;
      account: { __typename: 'Account'; id: string; name: string } | null;
      address: {
        __typename: 'Address';
        id: string;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
      } | null;
      skill: { __typename: 'Skill'; id: string; name: string } | null;
    };
  };
};

export type AdminItemFragment = {
  __typename: 'CustomerAdmin';
  id: string;
  acceptedAt: string | null;
  avatarUrl: string | null;
  active: boolean;
  user: {
    __typename: 'User';
    id: string;
    active: boolean;
    email: string;
    firstName: string;
    language: LanguageEnum;
    lastName: string;
    emailConfirmed: boolean | null;
    channelId: string;
  };
};

export type AccountItemFragment = {
  __typename: 'Account';
  id: string;
  name: string;
  markupPercent: number | null;
  rateType: AccountRateTypeEnum;
  currentPaymentMethod: string | null;
  active: boolean;
  canDisable: boolean | null;
  defaultContact: { __typename: 'CustomerAdmin'; id: string };
};

export type WorkerRelationshipItemFragment = {
  __typename: 'WorkerRelationship';
  id: string;
  kind: RelationshipKindEnum;
  worker: {
    __typename: 'Worker';
    id: string;
    avatarUrl: string | null;
    addressLine1: string | null;
    city: string | null;
    state: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    };
  };
};

export type AddressItemFragment = {
  __typename: 'Address';
  id: string;
  addressLine1: string | null;
  addressLine2: string | null;
  timezone: string;
  city: string | null;
  name: string | null;
  state: string | null;
  zip: string | null;
  active: boolean | null;
  jobsCount: number | null;
  coords: { __typename: 'Point'; latitude: number; longitude: number };
  rates: Array<{
    __typename: 'Rate';
    id: string;
    cost: number;
    pay: number;
    account: { __typename: 'Account'; id: string; name: string } | null;
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      city: string | null;
      state: string | null;
      active: boolean | null;
      zip: string | null;
    } | null;
    skill: {
      __typename: 'Skill';
      id: string;
      name: string;
      imageUrl: string | null;
    } | null;
  }>;
};

export type CustomerItemFragment = {
  __typename: 'Customer';
  id: string;
  invoiceDueDate: string;
  logoUrl: string | null;
  name: string;
  jobTaxType: string | null;
  status: number;
  ltaAllowed: boolean | null;
  markupPercent: number;
  checkInRadiusFeet: number;
  rateType: AccountRateTypeEnum;
  addresses: Array<{
    __typename: 'Address';
    id: string;
    addressLine1: string | null;
    addressLine2: string | null;
    timezone: string;
    city: string | null;
    name: string | null;
    state: string | null;
    zip: string | null;
    active: boolean | null;
    jobsCount: number | null;
    coords: { __typename: 'Point'; latitude: number; longitude: number };
    rates: Array<{
      __typename: 'Rate';
      id: string;
      cost: number;
      pay: number;
      account: { __typename: 'Account'; id: string; name: string } | null;
      address: {
        __typename: 'Address';
        id: string;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        active: boolean | null;
        zip: string | null;
      } | null;
      skill: {
        __typename: 'Skill';
        id: string;
        name: string;
        imageUrl: string | null;
      } | null;
    }>;
  }>;
  accounts: Array<{
    __typename: 'Account';
    id: string;
    name: string;
    markupPercent: number | null;
    rateType: AccountRateTypeEnum;
    currentPaymentMethod: string | null;
    active: boolean;
    canDisable: boolean | null;
    defaultContact: { __typename: 'CustomerAdmin'; id: string };
  }>;
  admins: {
    __typename: 'CustomerAdminPage';
    items: Array<{
      __typename: 'CustomerAdmin';
      id: string;
      acceptedAt: string | null;
      avatarUrl: string | null;
      active: boolean;
      user: {
        __typename: 'User';
        id: string;
        active: boolean;
        email: string;
        firstName: string;
        language: LanguageEnum;
        lastName: string;
        emailConfirmed: boolean | null;
        channelId: string;
      };
    }>;
  };
  workerRelationships: Array<{
    __typename: 'WorkerRelationship';
    id: string;
    kind: RelationshipKindEnum;
    worker: {
      __typename: 'Worker';
      id: string;
      avatarUrl: string | null;
      addressLine1: string | null;
      city: string | null;
      state: string | null;
      user: {
        __typename: 'User';
        id: string;
        firstName: string;
        middleName: string | null;
        lastName: string;
        email: string;
        phoneNumber: string | null;
      };
    };
  }>;
};

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type GetCustomerQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    invoiceDueDate: string;
    logoUrl: string | null;
    name: string;
    jobTaxType: string | null;
    status: number;
    ltaAllowed: boolean | null;
    markupPercent: number;
    checkInRadiusFeet: number;
    rateType: AccountRateTypeEnum;
    accounts: Array<{
      __typename: 'Account';
      id: string;
      name: string;
      markupPercent: number | null;
      rateType: AccountRateTypeEnum;
      currentPaymentMethod: string | null;
      active: boolean;
      canDisable: boolean | null;
      defaultContact: { __typename: 'CustomerAdmin'; id: string };
    }>;
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
      certificateType: CertificateTypeEnum;
    }>;
    admins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        active: boolean;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          email: string;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          emailConfirmed: boolean | null;
          channelId: string;
        };
      }>;
    };
    workerRelationships: Array<{
      __typename: 'WorkerRelationship';
      id: string;
      kind: RelationshipKindEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
    addresses: Array<{
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      city: string | null;
      name: string | null;
      state: string | null;
      zip: string | null;
      active: boolean | null;
      jobsCount: number | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
      rates: Array<{
        __typename: 'Rate';
        id: string;
        cost: number;
        pay: number;
        account: { __typename: 'Account'; id: string; name: string } | null;
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          active: boolean | null;
          zip: string | null;
          timezone: string;
        } | null;
        skill: {
          __typename: 'Skill';
          id: string;
          name: string;
          imageUrl: string | null;
        } | null;
      }>;
    }>;
  };
};

export type GetCustomerInvoiceQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type GetCustomerInvoiceQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    invoices: {
      __typename: 'InvoicePage';
      items: Array<{
        __typename: 'Invoice';
        id: string;
        number: string | null;
        total: string;
        createdDatetime: string;
        dueDateDatetime: string | null;
        dueDate: string | null;
        status: StripeInvoiceStatusEnum;
        account: { __typename: 'Account'; id: string; name: string };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type GetCustomerOrdersQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type GetCustomerOrdersQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    orders: {
      __typename: 'OrderPage';
      items: Array<{
        __typename: 'Order';
        id: string;
        uuid: string | null;
        startAt: string;
        endAt: string;
        jobsCount: number;
        status: OrderStatusEnum;
        account: { __typename: 'Account'; id: string; name: string };
        jobs: Array<{
          __typename: 'Job';
          id: string;
          filled: boolean;
          cancelled: boolean;
          taxType: TaxTypeEnum | null;
          approvalStatus: JobApprovalStatusEnum;
          timesheets: Array<{
            __typename: 'Timesheet';
            id: string;
            status: TimesheetStatusEnum;
          }>;
          jobWorkers: Array<{
            __typename: 'JobWorker';
            showed: boolean | null;
            status: JobWorkerStatusEnum;
          }>;
          skill: { __typename: 'Skill'; id: string; imageUrl: string | null };
        }>;
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type ListAgencyBadgeCertificateQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type ListAgencyBadgeCertificateQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    badgeCertificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
      certificateType: CertificateTypeEnum;
    }>;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  invoiceDueDate: InputMaybe<DefaultDueDateEnum>;
  name: Scalars['String']['input'];
  taxType: TaxTypeEnum;
  ltaAllowed: Scalars['Boolean']['input'];
  markupPercent: Scalars['Float']['input'];
  checkInRadiusFeet: InputMaybe<Scalars['Int']['input']>;
  certificateIds: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
}>;

export type UpdateCustomerMutation = {
  __typename: 'Mutation';
  customerUpdate: {
    __typename: 'CustomerUpdatePayload';
    customer: {
      __typename: 'Customer';
      id: string;
      name: string;
      invoiceDueDate: string;
    };
  };
};

export type UpdateJobTaxTypeMutationVariables = Exact<{
  taxType: TaxTypeEnum;
  customerId: Scalars['ID']['input'];
}>;

export type UpdateJobTaxTypeMutation = {
  __typename: 'Mutation';
  customerUpdateJobTaxType: {
    __typename: 'CustomerUpdateJobTaxTypePayload';
    customer: { __typename: 'Customer'; id: string; jobTaxType: string | null };
  };
};

export type CreateCustomerWorkerRelationshipMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  kind: RelationshipKindEnum;
  workerId: Scalars['ID']['input'];
  comments: InputMaybe<Scalars['String']['input']>;
  reason: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCustomerWorkerRelationshipMutation = {
  __typename: 'Mutation';
  customerRelateWorker: {
    __typename: 'CustomerRelateWorkerPayload';
    workerRelationship: {
      __typename: 'WorkerRelationship';
      id: string;
      kind: RelationshipKindEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        addressLine1: string | null;
        city: string | null;
        state: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    };
  };
};

export type RemoveCustomerWorkerRelationshipMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
  kind: RelationshipKindEnum;
}>;

export type RemoveCustomerWorkerRelationshipMutation = {
  __typename: 'Mutation';
  customerUnrelateWorker: void | null;
};

export type ChangeCustomerLogoMutationVariables = Exact<{
  logoKey: Scalars['String']['input'];
  customerId: Scalars['ID']['input'];
}>;

export type ChangeCustomerLogoMutation = {
  __typename: 'Mutation';
  customerChangeLogo: {
    __typename: 'CustomerChangeLogoPayload';
    customer: { __typename: 'Customer'; id: string; logoUrl: string | null };
  };
};

export type UpdateCustomerAdminProfileMutationVariables = Exact<{
  customerAdminId: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: InputMaybe<Scalars['Email']['input']>;
  title: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCustomerAdminProfileMutation = {
  __typename: 'Mutation';
  customerAdminUpdateProfile: {
    __typename: 'CustomerAdminUpdateProfilePayload';
    customerAdmin: {
      __typename: 'CustomerAdmin';
      id: string;
      title: string | null;
      user: {
        __typename: 'User';
        id: string;
        active: boolean;
        firstName: string;
        language: LanguageEnum;
        lastName: string;
        email: string;
        emailConfirmed: boolean | null;
        phoneNumber: string | null;
        allowedPlatform: number;
        channelId: string;
        roleId: UserRoleEnum;
      };
    };
  };
};

export type UserItemFieldsFragment = {
  __typename: 'User';
  id: string;
  active: boolean;
  firstName: string;
  language: LanguageEnum;
  lastName: string;
  email: string;
  emailConfirmed: boolean | null;
  phoneNumber: string | null;
  allowedPlatform: number;
  channelId: string;
  roleId: UserRoleEnum;
};

export type GetAdminCustomerQueryVariables = Exact<{
  customerAdminId: Scalars['ID']['input'];
}>;

export type GetAdminCustomerQuery = {
  __typename: 'Query';
  customerAdmin: {
    __typename: 'CustomerAdmin';
    id: string;
    avatarUrl: string | null;
    title: string | null;
    acceptedAt: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      logoUrl: string | null;
      name: string;
    };
    user: {
      __typename: 'User';
      id: string;
      active: boolean;
      firstName: string;
      language: LanguageEnum;
      lastName: string;
      email: string;
      emailConfirmed: boolean | null;
      phoneNumber: string | null;
      allowedPlatform: number;
      channelId: string;
      roleId: UserRoleEnum;
    };
  };
};

export type ResendCustomerAdminInvitationMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type ResendCustomerAdminInvitationMutation = {
  __typename: 'Mutation';
  customerAdminResendInvitation: {
    __typename: 'CustomerAdminResendInvitationPayload';
    result: boolean;
  };
};

export type ChangeCustomerAdminAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String']['input'];
  customerAdminId: Scalars['ID']['input'];
}>;

export type ChangeCustomerAdminAvatarMutation = {
  __typename: 'Mutation';
  customerAdminChangeAvatar: {
    __typename: 'CustomerAdminChangeAvatarPayload';
    customerAdmin: {
      __typename: 'CustomerAdmin';
      id: string;
      avatarUrl: string | null;
    };
  };
};

export type CreateCustomerMutationVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type CreateCustomerMutation = {
  __typename: 'Mutation';
  customerCreate: {
    __typename: 'CustomerCreatePayload';
    customer: { __typename: 'Customer'; id: string };
  };
};

export type RegionQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type RegionQuery = {
  __typename: 'Query';
  region: Array<{
    __typename: 'Region';
    id: string;
    isActive: boolean;
    jobTaxType: TaxTypeEnum | null;
    name: string | null;
  }>;
};

export type ListCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<CustomerFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
  sortKey: InputMaybe<CustomerSortKeyEnum>;
  reverse: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListCustomersQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        id: string;
        logoUrl: string | null;
        name: string;
        createdAt: string;
        status: number;
        onboardingStatus: CustomerOnboardingStatusEnum;
        jobTaxType: string | null;
        accounts: Array<{ __typename: 'Account'; state: string | null }>;
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type GetCustomerAndAccountsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<CustomerFilterSetInput>;
}>;

export type GetCustomerAndAccountsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        id: string;
        name: string;
        accounts: Array<{ __typename: 'Account'; id: string; name: string }>;
      }>;
    };
  };
};

export type GetCustomerAndSkillsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<CustomerFilterSetInput>;
}>;

export type GetCustomerAndSkillsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        id: string;
        name: string;
        logoUrl: string | null;
      }>;
    };
    skillCategories: Array<{
      __typename: 'SkillCategory';
      id: string;
      name: string | null;
      skills: Array<{ __typename: 'Skill'; id: string; name: string }>;
    }>;
  };
};

export type GetUserFilterQueryVariables = Exact<{
  filterType: UserFilterTypeEnum;
}>;

export type GetUserFilterQuery = {
  __typename: 'Query';
  userFilter: {
    __typename: 'UserFilter';
    filterType: UserFilterTypeEnum;
    filterValue: string;
    version: string | null;
  } | null;
};

export type UserFilterSaveMutationVariables = Exact<{
  filterValue: Scalars['String']['input'];
  filterType: UserFilterTypeEnum;
}>;

export type UserFilterSaveMutation = {
  __typename: 'Mutation';
  userFilterSave: {
    __typename: 'UserFilterSavePayload';
    userFilter: {
      __typename: 'UserFilter';
      filterType: UserFilterTypeEnum;
      filterValue: string;
      version: string | null;
    };
  };
};

export type ListShiftsByWeekQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  startDate: Scalars['ISO8601Date']['input'];
  filters: InputMaybe<JobFilterSetInput>;
  timeZone: InputMaybe<Scalars['String']['input']>;
}>;

export type ListShiftsByWeekQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    shiftsByWeek: Array<{
      __typename: 'Shift';
      id: string;
      endAt: string;
      startAt: string;
      job: {
        __typename: 'Job';
        id: string;
        hiredWorkersCount: number;
        quantity: number;
        published: boolean;
        cancelledAt: string | null;
        needsApproval: boolean;
        jobType: JobTypeEnum;
        account: {
          __typename: 'Account';
          id: string;
          name: string;
          customer: { __typename: 'Customer'; id: string; name: string };
        };
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          zip: string | null;
          timezone: string;
        };
        order: { __typename: 'Order'; id: string; uuid: string | null };
        skill: { __typename: 'Skill'; id: string; name: string };
      };
    }>;
  };
};

export type NeedsApprovalQueryVariables = Exact<{
  startDate: Scalars['ISO8601Date']['input'];
  endDate: Scalars['ISO8601Date']['input'];
  tenantId: Scalars['ID']['input'];
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<JobFilterSetInput>;
}>;

export type NeedsApprovalQuery = {
  __typename: 'Query';
  needsApproval: Array<{
    __typename: 'Shift';
    job: { __typename: 'Job'; id: string };
  }>;
};

export type GetAgencyByKeyQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  key: Scalars['Key']['input'];
}>;

export type GetAgencyByKeyQuery = {
  __typename: 'Query';
  agencyByKey: {
    __typename: 'Agency';
    id: string;
    key: string;
    name: string;
    checkinWindow: any;
    maxCheckinDistance: any;
    email: string;
    phoneNumber: string;
    address: {
      __typename: 'Address';
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      zip: string | null;
      city: string | null;
      state: string | null;
    } | null;
  };
};

export type CustomerListingQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
  customerStatus: InputMaybe<
    | Array<Scalars['NonNegativeInt']['input']>
    | Scalars['NonNegativeInt']['input']
  >;
}>;

export type CustomerListingQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    customers: {
      __typename: 'CustomerPage';
      items: Array<{
        __typename: 'Customer';
        id: string;
        name: string;
        jobTaxType: string | null;
        status: number;
        accounts: Array<{
          __typename: 'Account';
          id: string;
          name: string;
          currentPaymentMethod: string | null;
          customer: {
            __typename: 'Customer';
            id: string;
            logoUrl: string | null;
            name: string;
          };
          defaultContact: {
            __typename: 'CustomerAdmin';
            id: string;
            avatarUrl: string | null;
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              phoneNumber: string | null;
            };
          };
        }>;
      }>;
    };
  };
};

export type GenerateInvoiceTimesheetsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  filters: InputMaybe<TimesheetFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type GenerateInvoiceTimesheetsQuery = {
  __typename: 'Query';
  generateInvoiceTimesheets: {
    __typename: 'TimesheetPage';
    items: Array<{
      __typename: 'Timesheet';
      id: string;
      approvedCheckinAt: string | null;
      costRate: number;
      tipAmount: any | null;
      netApprovedTime: any;
      holdByInvoiceId: string | null;
      invoiceItems: Array<{
        __typename: 'InvoiceItem';
        id: string | null;
        shortDescription: string;
        billRate: number | null;
        isEdited: boolean | null;
        cancelled: boolean | null;
        isOvertime: boolean | null;
        isHoliday: boolean | null;
        order: string | null;
        amount: string;
        netTime: string | null;
        jobId: string | null;
        tip: any | null;
        skill: { __typename: 'Skill'; id: string; name: string } | null;
        timesheetInvoice: {
          __typename: 'Invoice';
          id: string;
          status: StripeInvoiceStatusEnum;
        } | null;
      }> | null;
      jobWorker: {
        __typename: 'JobWorker';
        worker: {
          __typename: 'Worker';
          id: string;
          user: {
            __typename: 'User';
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        };
      };
      job: {
        __typename: 'Job';
        id: string;
        skill: { __typename: 'Skill'; id: string; name: string };
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          addressLine2: string | null;
          timezone: string;
          city: string | null;
          state: string | null;
          zip: string | null;
        };
        order: { __typename: 'Order'; id: string };
      };
    }>;
  };
};

export type InvoiceGenerateMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  timesheetIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type InvoiceGenerateMutation = {
  __typename: 'Mutation';
  invoiceGenerate: {
    __typename: 'InvoiceGeneratePayload';
    invoice: { __typename: 'Invoice'; id: string };
  };
};

export type FinishDraftMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type FinishDraftMutation = {
  __typename: 'Mutation';
  stripeInvoiceFinalize: {
    __typename: 'StripeInvoiceFinalizePayload';
    invoice: { __typename: 'Invoice'; status: StripeInvoiceStatusEnum };
  };
};

export type VoidInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type VoidInvoiceMutation = {
  __typename: 'Mutation';
  stripeInvoiceVoid: {
    __typename: 'StripeInvoiceVoidPayload';
    invoice: { __typename: 'Invoice'; status: StripeInvoiceStatusEnum };
  };
};

export type EditInvoiceDetailMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['ISO8601DateTime']['input'];
}>;

export type EditInvoiceDetailMutation = {
  __typename: 'Mutation';
  stripeInvoiceUpdate: {
    __typename: 'StripeInvoiceUpdatePayload';
    invoice: {
      __typename: 'Invoice';
      id: string;
      description: string | null;
      dueDate: string | null;
    } | null;
  };
};

export type GetTimesheetPdfMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetTimesheetPdfMutation = {
  __typename: 'Mutation';
  invoiceTimesheetsPdf: {
    __typename: 'InvoiceTimesheetsPdfPayload';
    pdfCreated: { __typename: 'UploadURL'; key: string; url: string };
  };
};

export type GetInvoiceDetailsRevampQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetInvoiceDetailsRevampQuery = {
  __typename: 'Query';
  listStripeInvoiceDetail: {
    __typename: 'StripeInvoiceDetail';
    invoice: {
      __typename: 'Invoice';
      id: string;
      autoApprove: boolean;
      stripeInvoiceId: string;
      amountDue: string;
      invoicePdf: string | null;
      status: StripeInvoiceStatusEnum;
      defaultContactEmail: string;
      subtotal: string;
      total: string;
      created: string;
      invoiceFinalizedAt: string | null;
      createdDatetime: string;
      description: string | null;
      dueDate: string | null;
      dueDateDatetime: string | null;
      deleted: boolean;
      hostedInvoiceUrl: string | null;
      number: string | null;
      invoiceUid: string;
      customer: { __typename: 'Customer'; name: string; id: string };
      account: {
        __typename: 'Account';
        currentPaymentMethod: string | null;
        id: string;
        name: string;
        addressLine1: string | null;
        addressLine2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        cutOff: InvoiceCutOffEnum | null;
        monthlyCutOff: boolean;
        billingWeek: BillingWeekEnum | null;
        consolidateUnprocessedCharges: boolean | null;
        groupBy: InvoiceGroupByEnum | null;
        markupPercent: number | null;
        rateType: AccountRateTypeEnum;
        canDisable: boolean | null;
        active: boolean;
        customer: {
          __typename: 'Customer';
          id: string;
          invoiceDueDate: string;
          logoUrl: string | null;
          name: string;
          jobTaxType: string | null;
          status: number;
          ltaAllowed: boolean | null;
          markupPercent: number;
          checkInRadiusFeet: number;
          rateType: AccountRateTypeEnum;
          addresses: Array<{
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            addressLine2: string | null;
            timezone: string;
            city: string | null;
            name: string | null;
            state: string | null;
            zip: string | null;
            active: boolean | null;
            jobsCount: number | null;
            coords: {
              __typename: 'Point';
              latitude: number;
              longitude: number;
            };
            rates: Array<{
              __typename: 'Rate';
              id: string;
              cost: number;
              pay: number;
              account: {
                __typename: 'Account';
                id: string;
                name: string;
              } | null;
              address: {
                __typename: 'Address';
                id: string;
                addressLine1: string | null;
                city: string | null;
                state: string | null;
                active: boolean | null;
                zip: string | null;
              } | null;
              skill: {
                __typename: 'Skill';
                id: string;
                name: string;
                imageUrl: string | null;
              } | null;
            }>;
          }>;
          accounts: Array<{
            __typename: 'Account';
            id: string;
            name: string;
            markupPercent: number | null;
            rateType: AccountRateTypeEnum;
            currentPaymentMethod: string | null;
            active: boolean;
            canDisable: boolean | null;
            defaultContact: { __typename: 'CustomerAdmin'; id: string };
          }>;
          admins: {
            __typename: 'CustomerAdminPage';
            items: Array<{
              __typename: 'CustomerAdmin';
              id: string;
              acceptedAt: string | null;
              avatarUrl: string | null;
              active: boolean;
              user: {
                __typename: 'User';
                id: string;
                active: boolean;
                email: string;
                firstName: string;
                language: LanguageEnum;
                lastName: string;
                emailConfirmed: boolean | null;
                channelId: string;
              };
            }>;
          };
          workerRelationships: Array<{
            __typename: 'WorkerRelationship';
            id: string;
            kind: RelationshipKindEnum;
            worker: {
              __typename: 'Worker';
              id: string;
              avatarUrl: string | null;
              addressLine1: string | null;
              city: string | null;
              state: string | null;
              user: {
                __typename: 'User';
                id: string;
                firstName: string;
                middleName: string | null;
                lastName: string;
                email: string;
                phoneNumber: string | null;
              };
            };
          }>;
        };
        defaultContact: {
          __typename: 'CustomerAdmin';
          id: string;
          avatarUrl: string | null;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      };
    };
    invoiceItems: Array<{
      __typename: 'InvoiceItem';
      id: string | null;
      address: string | null;
      date: string | null;
      order: string | null;
      currency: string;
      stripeCustomerId: string;
      shortDescription: string;
      invoice: string | null;
      amount: string;
      billRate: number | null;
      isEdited: boolean | null;
      netTime: string | null;
      cancelled: boolean | null;
      isOvertime: boolean | null;
      isHoliday: boolean | null;
      timesheetId: string | null;
      netTimeMinutes: number | null;
      tip: any | null;
      jobId: string | null;
      workerTimesheet: {
        __typename: 'Timesheet';
        approvedBreakMinutes: any;
        approvedCheckinAt: string | null;
        approvedCheckoutAt: string | null;
        updatedByType: string | null;
        job: {
          __typename: 'Job';
          address: { __typename: 'Address'; timezone: string };
        };
        updatedBy: {
          __typename: 'User';
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      skill: { __typename: 'Skill'; id: string; name: string } | null;
      worker: {
        __typename: 'Worker';
        id: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        };
      } | null;
    }> | null;
  };
};

export type DeleteInvoiceItemMutationVariables = Exact<{
  invoiceItemId: Scalars['ID']['input'];
}>;

export type DeleteInvoiceItemMutation = {
  __typename: 'Mutation';
  stripeInvoiceItemDelete: {
    __typename: 'StripeInvoiceItemDeletePayload';
    deleted: boolean;
  };
};

export type AddInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  amount: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;

export type AddInvoiceItemMutation = {
  __typename: 'Mutation';
  stripeInvoiceItemCreate: {
    __typename: 'StripeInvoiceItemCreatePayload';
    invoiceItem: { __typename: 'InvoiceItem'; id: string | null } | null;
  };
};

export type EditInvoiceItemMutationVariables = Exact<{
  invoiceItemId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  amount: InputMaybe<Scalars['String']['input']>;
}>;

export type EditInvoiceItemMutation = {
  __typename: 'Mutation';
  stripeInvoiceItemUpdate: {
    __typename: 'StripeInvoiceItemUpdatePayload';
    invoiceItem: { __typename: 'InvoiceItem'; id: string | null } | null;
  };
};

export type ToggleAutoApproveMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type ToggleAutoApproveMutation = {
  __typename: 'Mutation';
  switchAutoApprove: {
    __typename: 'SwitchAutoApprovePayload';
    invoice: { __typename: 'Invoice'; id: string; autoApprove: boolean };
  };
};

export type GetInvoiceDetailsQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetInvoiceDetailsQuery = {
  __typename: 'Query';
  listStripeInvoiceDetail: {
    __typename: 'StripeInvoiceDetail';
    invoice: {
      __typename: 'Invoice';
      id: string;
      autoApprove: boolean;
      stripeInvoiceId: string;
      amountDue: string;
      invoicePdf: string | null;
      status: StripeInvoiceStatusEnum;
      subtotal: string;
      total: string;
      created: string;
      createdDatetime: string;
      description: string | null;
      dueDate: string | null;
      dueDateDatetime: string | null;
      hostedInvoiceUrl: string | null;
      number: string | null;
      customer: { __typename: 'Customer'; name: string };
    };
    invoiceItems: Array<{
      __typename: 'InvoiceItem';
      id: string | null;
      address: string | null;
      date: string | null;
      order: string | null;
      currency: string;
      stripeCustomerId: string;
      shortDescription: string;
      invoice: string | null;
      amount: string;
      billRate: number | null;
      isEdited: boolean | null;
      netTime: string | null;
      cancelled: boolean | null;
      isOvertime: boolean | null;
      isHoliday: boolean | null;
      timesheetId: string | null;
      tip: any | null;
      jobId: string | null;
      skill: { __typename: 'Skill'; id: string; name: string } | null;
      worker: {
        __typename: 'Worker';
        id: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        };
      } | null;
    }> | null;
  };
};

export type GetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetInvoiceQuery = {
  __typename: 'Query';
  invoice: {
    __typename: 'Invoice';
    id: string;
    amountDue: string;
    defaultContactEmail: string;
    description: string | null;
    dueDate: string | null;
    invoicePdf: string | null;
    number: string | null;
    status: StripeInvoiceStatusEnum;
    account: {
      __typename: 'Account';
      currentPaymentMethod: string | null;
      id: string;
      name: string;
      addressLine1: string | null;
      addressLine2: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      cutOff: InvoiceCutOffEnum | null;
      monthlyCutOff: boolean;
      billingWeek: BillingWeekEnum | null;
      consolidateUnprocessedCharges: boolean | null;
      groupBy: InvoiceGroupByEnum | null;
      markupPercent: number | null;
      rateType: AccountRateTypeEnum;
      canDisable: boolean | null;
      active: boolean;
      customer: {
        __typename: 'Customer';
        id: string;
        invoiceDueDate: string;
        logoUrl: string | null;
        name: string;
        jobTaxType: string | null;
        status: number;
        ltaAllowed: boolean | null;
        markupPercent: number;
        checkInRadiusFeet: number;
        rateType: AccountRateTypeEnum;
        addresses: Array<{
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          addressLine2: string | null;
          timezone: string;
          city: string | null;
          name: string | null;
          state: string | null;
          zip: string | null;
          active: boolean | null;
          jobsCount: number | null;
          coords: { __typename: 'Point'; latitude: number; longitude: number };
          rates: Array<{
            __typename: 'Rate';
            id: string;
            cost: number;
            pay: number;
            account: { __typename: 'Account'; id: string; name: string } | null;
            address: {
              __typename: 'Address';
              id: string;
              addressLine1: string | null;
              city: string | null;
              state: string | null;
              active: boolean | null;
              zip: string | null;
            } | null;
            skill: {
              __typename: 'Skill';
              id: string;
              name: string;
              imageUrl: string | null;
            } | null;
          }>;
        }>;
        accounts: Array<{
          __typename: 'Account';
          id: string;
          name: string;
          markupPercent: number | null;
          rateType: AccountRateTypeEnum;
          currentPaymentMethod: string | null;
          active: boolean;
          canDisable: boolean | null;
          defaultContact: { __typename: 'CustomerAdmin'; id: string };
        }>;
        admins: {
          __typename: 'CustomerAdminPage';
          items: Array<{
            __typename: 'CustomerAdmin';
            id: string;
            acceptedAt: string | null;
            avatarUrl: string | null;
            active: boolean;
            user: {
              __typename: 'User';
              id: string;
              active: boolean;
              email: string;
              firstName: string;
              language: LanguageEnum;
              lastName: string;
              emailConfirmed: boolean | null;
              channelId: string;
            };
          }>;
        };
        workerRelationships: Array<{
          __typename: 'WorkerRelationship';
          id: string;
          kind: RelationshipKindEnum;
          worker: {
            __typename: 'Worker';
            id: string;
            avatarUrl: string | null;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              middleName: string | null;
              lastName: string;
              email: string;
              phoneNumber: string | null;
            };
          };
        }>;
      };
      defaultContact: {
        __typename: 'CustomerAdmin';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    };
  };
};

export type CreatePaymentIntentMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type CreatePaymentIntentMutation = {
  __typename: 'Mutation';
  paymentIntentCreate: {
    __typename: 'PaymentIntentCreatePayload';
    clientSecret: string;
  };
};

export type InvoicePayMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type InvoicePayMutation = {
  __typename: 'Mutation';
  invoicePay: { __typename: 'InvoicePayPayload'; success: boolean };
};

export type UpdatePaymentIntentAchProcessMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  clientSecret: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;

export type UpdatePaymentIntentAchProcessMutation = {
  __typename: 'Mutation';
  paymentIntentAchProcessUpdate: void;
};

export type ListInvoicesQueryVariables = Exact<{
  page: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage: InputMaybe<Scalars['PositiveInt']['input']>;
  filters: InputMaybe<InvoiceFilterSetInput>;
  sortKey: InputMaybe<InvoiceSortKeyEnum>;
  reverse: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListInvoicesQuery = {
  __typename: 'Query';
  listStripeInvoices: {
    __typename: 'InvoicePage';
    items: Array<{
      __typename: 'Invoice';
      id: string;
      total: string;
      createdDatetime: string;
      dueDateDatetime: string | null;
      dueDate: string | null;
      status: StripeInvoiceStatusEnum;
      number: string | null;
      customer: { __typename: 'Customer'; id: string; name: string };
      account: { __typename: 'Account'; id: string; name: string };
    }>;
    pageInfo: {
      __typename: 'PageInfo';
      totalItems: number;
      totalPages: number;
    };
  };
};

export type AcceptRequestMutationVariables = Exact<{
  jobRequestId: Scalars['ID']['input'];
}>;

export type AcceptRequestMutation = {
  __typename: 'Mutation';
  jobRequestAccept: {
    __typename: 'JobRequestAcceptPayload';
    job: {
      __typename: 'Job';
      id: string;
      skill: { __typename: 'Skill'; name: string };
    };
  };
};

export type CancelJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
}>;

export type CancelJobMutation = {
  __typename: 'Mutation';
  jobCancel: {
    __typename: 'JobCancelPayload';
    reason: string;
    cancelUser: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export type ListJobCustomerAdminsQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type ListJobCustomerAdminsQuery = {
  __typename: 'Query';
  job: {
    __typename: 'Job';
    id: string;
    customer: {
      __typename: 'Customer';
      id: string;
      admins: {
        __typename: 'CustomerAdminPage';
        items: Array<{
          __typename: 'CustomerAdmin';
          id: string;
          avatarUrl: string | null;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
          };
        }>;
      };
    };
  };
};

export type AdminEditCertificatesOfJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  certificateIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminEditCertificatesOfJobMutation = {
  __typename: 'Mutation';
  adminEditCertificatesOfJob: {
    __typename: 'AdminEditCertificatesOfJobPayload';
    job: {
      __typename: 'Job';
      certificates: Array<{
        __typename: 'Certificate';
        id: string;
        name: string;
        imageUrl: string | null;
        certificateType: CertificateTypeEnum;
      }>;
    };
  };
};

export type GetJobHistoryQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type GetJobHistoryQuery = {
  __typename: 'Query';
  jobHistory: {
    __typename: 'JobHistory';
    createdBy: {
      __typename: 'User';
      firstName: string;
      middleName: string | null;
      lastName: string;
    } | null;
  };
};

export type ListAgencyUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type ListAgencyUniformsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    uniforms: Array<{
      __typename: 'Uniform';
      id: string;
      name: string;
      imageUrl: string | null;
      defaultInstructions: string;
    }>;
  };
};

export type JobOfferItemFragment = {
  __typename: 'JobOffer';
  id: string;
  acceptedAt: string | null;
  costRate: number;
  createdAt: string;
  payRate: number;
  rejectedAt: string | null;
  worker: {
    __typename: 'Worker';
    id: string;
    avatarUrl: string | null;
    city: string | null;
    state: string | null;
    averageRating: number;
    jobsRatingCount: number;
    jobsWorked: number;
    shiftsWorked: number;
    jobsDropped: number;
    noShow: number | null;
    taxType: TaxTypeEnum;
    activeCertificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
    }>;
    workerSkills: Array<{
      __typename: 'WorkerSkill';
      jobsWorked: number | null;
      skill: {
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      };
    }>;
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    };
  };
};

export type WorkerItemFragment = {
  __typename: 'Worker';
  id: string;
  avatarUrl: string | null;
  city: string | null;
  state: string | null;
  averageRating: number;
  jobsRatingCount: number;
  jobsWorked: number;
  shiftsWorked: number;
  jobsDropped: number;
  noShow: number | null;
  taxType: TaxTypeEnum;
  activeCertificates: Array<{
    __typename: 'Certificate';
    id: string;
    name: string;
    imageUrl: string | null;
  }>;
  workerSkills: Array<{
    __typename: 'WorkerSkill';
    jobsWorked: number | null;
    skill: {
      __typename: 'Skill';
      id: string;
      imageUrl: string | null;
      name: string;
    };
  }>;
  user: {
    __typename: 'User';
    id: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    email: string;
    phoneNumber: string | null;
  };
};

export type JobWorkerItemFragment = {
  __typename: 'JobWorker';
  id: string;
  costRate: number;
  payRate: number;
  dropReason: string | null;
  droppedAt: string | null;
  dismissReason: string | null;
  dismissedAt: string | null;
  noShowMarkedAt: string | null;
  showed: boolean | null;
  status: JobWorkerStatusEnum;
  noShowMarkedBy: {
    __typename: 'User';
    firstName: string;
    middleName: string | null;
    lastName: string;
  } | null;
  dismissedBy: {
    __typename: 'User';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  worker: {
    __typename: 'Worker';
    id: string;
    avatarUrl: string | null;
    city: string | null;
    state: string | null;
    averageRating: number;
    jobsRatingCount: number;
    jobsWorked: number;
    shiftsWorked: number;
    jobsDropped: number;
    noShow: number | null;
    taxType: TaxTypeEnum;
    activeCertificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
    }>;
    workerSkills: Array<{
      __typename: 'WorkerSkill';
      jobsWorked: number | null;
      skill: {
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      };
    }>;
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    };
  };
};

export type TimesheetItemFragment = {
  __typename: 'Timesheet';
  id: string;
  tipAmount: any | null;
  approvedAt: string | null;
  rejectedAt: string | null;
  approvedBreakMinutes: any;
  approvedCheckinAt: string | null;
  approvedCheckoutAt: string | null;
  approvedMinutes: any;
  netApprovedTime: any;
  checkinAt: string | null;
  createdAt: string;
  rating: number | null;
  updatedAt: string;
  updatedByType: string | null;
  checkoutAt: string | null;
  payRate: number;
  reportedCheckinAt: string | null;
  reportedCheckoutAt: string | null;
  reportedBreakMinutes: any;
  reportComment: string | null;
  status: TimesheetStatusEnum;
  timesheetComments: Array<{
    __typename: 'TimesheetComment';
    actorType: string | null;
    body: string | null;
    createdAt: string | null;
    actor: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
  updatedBy: {
    __typename: 'User';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  job: { __typename: 'Job'; id: string };
  checkinCoords: {
    __typename: 'Point';
    latitude: number;
    longitude: number;
  } | null;
  checkoutCoords: {
    __typename: 'Point';
    latitude: number;
    longitude: number;
  } | null;
  jobWorker: {
    __typename: 'JobWorker';
    id: string;
    worker: {
      __typename: 'Worker';
      id: string;
      avatarUrl: string | null;
      user: {
        __typename: 'User';
        id: string;
        email: string;
        firstName: string;
        middleName: string | null;
        lastName: string;
      };
    };
  };
  shift: { __typename: 'Shift'; id: string; endAt: string; startAt: string };
};

export type GetJobQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type GetJobQuery = {
  __typename: 'Query';
  job: {
    __typename: 'Job';
    id: string;
    jobType: JobTypeEnum;
    taxType: TaxTypeEnum | null;
    addressInstructions: string | null;
    cancelledAt: string | null;
    contactInstructions: string | null;
    costRate: number;
    firstShiftStartAt: string;
    hiredWorkersCount: number;
    instructions: string | null;
    lastShiftEndAt: string;
    payRate: number;
    postedAt: string | null;
    createdAt: string;
    markupPercent: number | null;
    isRateLock: boolean | null;
    minPayRate: number;
    published: boolean;
    quantity: number;
    isHoliday: boolean;
    mandatoryBreakTime: any | null;
    originalCostRate: number;
    originalPayRate: number;
    status: JobStatusEnum;
    uniformInstructions: string | null;
    account: {
      __typename: 'Account';
      id: string;
      name: string;
      phoneNumber: string | null;
      rateType: AccountRateTypeEnum;
      markupPercent: number | null;
      customer: {
        __typename: 'Customer';
        id: string;
        name: string;
        ltaAllowed: boolean | null;
        jobTaxType: string | null;
        workerRelationships: Array<{
          __typename: 'WorkerRelationship';
          id: string;
          kind: RelationshipKindEnum;
          worker: {
            __typename: 'Worker';
            id: string;
            avatarUrl: string | null;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              middleName: string | null;
              lastName: string;
              email: string;
              phoneNumber: string | null;
            };
          };
        }>;
        addresses: Array<{
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          active: boolean | null;
        }>;
      };
    };
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      city: string | null;
      state: string | null;
      zip: string | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
    };
    contact: {
      __typename: 'CustomerAdmin';
      id: string;
      avatarUrl: string | null;
      user: {
        __typename: 'User';
        id: string;
        email: string;
        firstName: string;
        middleName: string | null;
        lastName: string;
        phoneNumber: string | null;
      };
    };
    jobOffers: Array<{
      __typename: 'JobOffer';
      id: string;
      acceptedAt: string | null;
      costRate: number;
      createdAt: string;
      payRate: number;
      rejectedAt: string | null;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        city: string | null;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        jobsWorked: number;
        shiftsWorked: number;
        jobsDropped: number;
        noShow: number | null;
        taxType: TaxTypeEnum;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
        }>;
        workerSkills: Array<{
          __typename: 'WorkerSkill';
          jobsWorked: number | null;
          skill: {
            __typename: 'Skill';
            id: string;
            imageUrl: string | null;
            name: string;
          };
        }>;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
    jobRequests: Array<{
      __typename: 'JobRequest';
      id: string;
      createdAt: string;
      status: JobRequestStatusEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
        };
      };
    }>;
    jobWorkers: Array<{
      __typename: 'JobWorker';
      id: string;
      costRate: number;
      payRate: number;
      dropReason: string | null;
      droppedAt: string | null;
      dismissReason: string | null;
      dismissedAt: string | null;
      noShowMarkedAt: string | null;
      showed: boolean | null;
      status: JobWorkerStatusEnum;
      noShowMarkedBy: {
        __typename: 'User';
        firstName: string;
        middleName: string | null;
        lastName: string;
      } | null;
      dismissedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        city: string | null;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        jobsWorked: number;
        shiftsWorked: number;
        jobsDropped: number;
        noShow: number | null;
        taxType: TaxTypeEnum;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
        }>;
        workerSkills: Array<{
          __typename: 'WorkerSkill';
          jobsWorked: number | null;
          skill: {
            __typename: 'Skill';
            id: string;
            imageUrl: string | null;
            name: string;
          };
        }>;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
    order: { __typename: 'Order'; id: string; markupPercent: number | null };
    shifts: Array<{
      __typename: 'Shift';
      id: string;
      endAt: string;
      startAt: string;
    }>;
    skill: {
      __typename: 'Skill';
      id: string;
      name: string;
      imageUrl: string | null;
      descriptionEn: string | null;
      gravyTrained: boolean | null;
    };
    timesheets: Array<{
      __typename: 'Timesheet';
      id: string;
      tipAmount: any | null;
      approvedAt: string | null;
      rejectedAt: string | null;
      approvedBreakMinutes: any;
      approvedCheckinAt: string | null;
      approvedCheckoutAt: string | null;
      approvedMinutes: any;
      netApprovedTime: any;
      checkinAt: string | null;
      createdAt: string;
      rating: number | null;
      updatedAt: string;
      updatedByType: string | null;
      checkoutAt: string | null;
      payRate: number;
      reportedCheckinAt: string | null;
      reportedCheckoutAt: string | null;
      reportedBreakMinutes: any;
      reportComment: string | null;
      status: TimesheetStatusEnum;
      timesheetComments: Array<{
        __typename: 'TimesheetComment';
        actorType: string | null;
        body: string | null;
        createdAt: string | null;
        actor: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }>;
      updatedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      job: { __typename: 'Job'; id: string };
      checkinCoords: {
        __typename: 'Point';
        latitude: number;
        longitude: number;
      } | null;
      checkoutCoords: {
        __typename: 'Point';
        latitude: number;
        longitude: number;
      } | null;
      jobWorker: {
        __typename: 'JobWorker';
        id: string;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          user: {
            __typename: 'User';
            id: string;
            email: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        };
      };
      shift: {
        __typename: 'Shift';
        id: string;
        endAt: string;
        startAt: string;
      };
    }>;
    uniform: {
      __typename: 'Uniform';
      id: string;
      name: string;
      imageUrl: string | null;
      defaultInstructions: string;
    };
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
    }>;
  };
};

export type JobRemoveWorkerMutationVariables = Exact<{
  jobWorkerId: Scalars['ID']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
}>;

export type JobRemoveWorkerMutation = {
  __typename: 'Mutation';
  jobDismissWorker: void | null;
};

export type MarkShowedMutationVariables = Exact<{
  jobWorkerId: Scalars['ID']['input'];
  showed: Scalars['Boolean']['input'];
}>;

export type MarkShowedMutation = {
  __typename: 'Mutation';
  adminMarkWorkerAsShowed: {
    __typename: 'AdminMarkWorkerAsShowedPayload';
    jobWorker: { __typename: 'JobWorker'; showed: boolean | null; id: string };
  };
};

export type UpdatePaymentJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  costRate: Scalars['PositiveInt']['input'];
  payRate: Scalars['PositiveInt']['input'];
  originalCostRate: Scalars['PositiveInt']['input'];
  originalPayRate: Scalars['PositiveInt']['input'];
  markupPercent: InputMaybe<Scalars['Float']['input']>;
  isRateLock: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdatePaymentJobMutation = {
  __typename: 'Mutation';
  adminEditRateOfJob: {
    __typename: 'AdminEditRateOfJobPayload';
    job: {
      __typename: 'Job';
      id: string;
      costRate: number;
      payRate: number;
      originalCostRate: number;
      originalPayRate: number;
      markupPercent: number | null;
      isRateLock: boolean | null;
    };
  };
};

export type PublishJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type PublishJobMutation = {
  __typename: 'Mutation';
  jobPublish: {
    __typename: 'JobPublishPayload';
    job: { __typename: 'Job'; id: string; published: boolean };
  };
};

export type RejectRequestMutationVariables = Exact<{
  jobRequestId: Scalars['ID']['input'];
}>;

export type RejectRequestMutation = {
  __typename: 'Mutation';
  jobRequestReject: {
    __typename: 'JobRequestRejectPayload';
    job: {
      __typename: 'Job';
      id: string;
      skill: { __typename: 'Skill'; name: string };
    };
  };
};

export type AddTimesheetCommentMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  body: Scalars['String']['input'];
}>;

export type AddTimesheetCommentMutation = {
  __typename: 'Mutation';
  timesheetCommentsAdd: {
    __typename: 'TimesheetCommentsAddPayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      status: TimesheetStatusEnum;
      rejectedAt: string | null;
      timesheetComments: Array<{
        __typename: 'TimesheetComment';
        actorType: string | null;
        body: string | null;
        createdAt: string | null;
        actor: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }>;
    };
  };
};

export type WorkerUpdateRatingMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  rating: InputMaybe<Scalars['Rating']['input']>;
  body: InputMaybe<Scalars['String']['input']>;
}>;

export type WorkerUpdateRatingMutation = {
  __typename: 'Mutation';
  workerUpdateRating: {
    __typename: 'WorkerUpdateRatingPayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      rating: number | null;
      jobWorker: {
        __typename: 'JobWorker';
        worker: {
          __typename: 'Worker';
          averageRating: number;
          jobsRatingCount: number;
          jobsWorked: number;
        };
      };
      timesheetComments: Array<{
        __typename: 'TimesheetComment';
        actorType: string | null;
        body: string | null;
        createdAt: string | null;
        actor: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }>;
    };
  };
};

export type CreateTimesheetMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type CreateTimesheetMutation = {
  __typename: 'Mutation';
  timesheetCreate: {
    __typename: 'TimesheetCreatePayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      job: { __typename: 'Job'; id: string };
    };
  };
};

export type ApproveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  approvedBreakMinutes: Scalars['NonNegativeInt']['input'];
  approvedCheckinAt: Scalars['ISO8601DateTime']['input'];
  approvedCheckoutAt: Scalars['ISO8601DateTime']['input'];
  ratingComment: InputMaybe<Scalars['String']['input']>;
  tipAmount: InputMaybe<Scalars['NonNegativeFloat']['input']>;
}>;

export type ApproveTimesheetMutation = {
  __typename: 'Mutation';
  timesheetApprove: {
    __typename: 'TimesheetApprovePayload';
    timesheet: { __typename: 'Timesheet'; id: string };
  };
};

export type RejectTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  ratingComment: InputMaybe<Scalars['String']['input']>;
}>;

export type RejectTimesheetMutation = {
  __typename: 'Mutation';
  timesheetReject: {
    __typename: 'TimesheetRejectPayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      status: TimesheetStatusEnum;
      rejectedAt: string | null;
    };
  };
};

export type SaveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  reportedBreakMinutes: Scalars['NonNegativeInt']['input'];
  reportedCheckinAt: Scalars['ISO8601DateTime']['input'];
  reportedCheckoutAt: Scalars['ISO8601DateTime']['input'];
  ratingComment: InputMaybe<Scalars['String']['input']>;
  tipAmount: InputMaybe<Scalars['NonNegativeFloat']['input']>;
}>;

export type SaveTimesheetMutation = {
  __typename: 'Mutation';
  timesheetUpdate: {
    __typename: 'TimesheetUpdatePayload';
    timesheet: { __typename: 'Timesheet'; id: string };
  };
};

export type SaveTimesheetTipMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
  tipAmount: Scalars['NonNegativeFloat']['input'];
}>;

export type SaveTimesheetTipMutation = {
  __typename: 'Mutation';
  timesheetTipUpdate: {
    __typename: 'TimesheetTipUpdatePayload';
    timesheet: { __typename: 'Timesheet'; id: string };
  };
};

export type UnrejectTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
}>;

export type UnrejectTimesheetMutation = {
  __typename: 'Mutation';
  timesheetUnreject: {
    __typename: 'TimesheetUnrejectPayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      status: TimesheetStatusEnum;
      rejectedAt: string | null;
    };
  };
};

export type UnapproveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID']['input'];
}>;

export type UnapproveTimesheetMutation = {
  __typename: 'Mutation';
  timesheetUnapprove: {
    __typename: 'TimesheetUnapprovePayload';
    timesheet: {
      __typename: 'Timesheet';
      id: string;
      approvedAt: string | null;
      status: TimesheetStatusEnum;
      updatedAt: string;
    };
  };
};

export type UnPublishJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type UnPublishJobMutation = {
  __typename: 'Mutation';
  jobUnpublish: {
    __typename: 'JobUnpublishPayload';
    job: {
      __typename: 'Job';
      id: string;
      published: boolean;
      postedAt: string | null;
    };
  };
};

export type UpdateGravyWorkersMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  numberWorkers: Scalars['Int']['input'];
}>;

export type UpdateGravyWorkersMutation = {
  __typename: 'Mutation';
  adminEditNumberWorkersJob: {
    __typename: 'AdminEditNumberWorkersJobPayload';
    job: {
      __typename: 'Job';
      quantity: number;
      jobWorkers: Array<{
        __typename: 'JobWorker';
        id: string;
        costRate: number;
        payRate: number;
        dropReason: string | null;
        droppedAt: string | null;
        dismissReason: string | null;
        dismissedAt: string | null;
        noShowMarkedAt: string | null;
        showed: boolean | null;
        status: JobWorkerStatusEnum;
        noShowMarkedBy: {
          __typename: 'User';
          firstName: string;
          middleName: string | null;
          lastName: string;
        } | null;
        dismissedBy: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          city: string | null;
          state: string | null;
          averageRating: number;
          jobsRatingCount: number;
          jobsWorked: number;
          shiftsWorked: number;
          jobsDropped: number;
          noShow: number | null;
          taxType: TaxTypeEnum;
          activeCertificates: Array<{
            __typename: 'Certificate';
            id: string;
            name: string;
            imageUrl: string | null;
          }>;
          workerSkills: Array<{
            __typename: 'WorkerSkill';
            jobsWorked: number | null;
            skill: {
              __typename: 'Skill';
              id: string;
              imageUrl: string | null;
              name: string;
            };
          }>;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      }>;
    };
  };
};

export type UpdateJobMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  addressId: Scalars['ID']['input'];
  addressInstructions: Scalars['String']['input'];
  contactId: Scalars['ID']['input'];
  contactInstructions: Scalars['String']['input'];
  instructions: Scalars['String']['input'];
  uniformId: Scalars['ID']['input'];
  uniformInstructions: Scalars['String']['input'];
}>;

export type UpdateJobMutation = {
  __typename: 'Mutation';
  jobUpdate: {
    __typename: 'JobUpdatePayload';
    job: {
      __typename: 'Job';
      id: string;
      addressInstructions: string | null;
      contactInstructions: string | null;
      instructions: string | null;
      uniformInstructions: string | null;
      contact: {
        __typename: 'CustomerAdmin';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      };
      uniform: {
        __typename: 'Uniform';
        id: string;
        imageUrl: string | null;
        name: string;
      };
    };
  };
};

export type UpdateJobScheduleMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  firstShiftStartAt: Scalars['ISO8601DateTime']['input'];
  lastShiftEndAt: Scalars['ISO8601DateTime']['input'];
  timeZoneOffset: Scalars['Float']['input'];
  mandatoryBreakTime: InputMaybe<Scalars['Minutes']['input']>;
}>;

export type UpdateJobScheduleMutation = {
  __typename: 'Mutation';
  adminEditTimeOfJob: {
    __typename: 'AdminEditTimeOfJobPayload';
    job: {
      __typename: 'Job';
      id: string;
      firstShiftStartAt: string;
      lastShiftEndAt: string;
      shifts: Array<{
        __typename: 'Shift';
        id: string;
        startAt: string;
        endAt: string;
      }>;
    };
  };
};

export type ListWorkersForJobQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<WorkerFilterSetInput>;
}>;

export type ListWorkersForJobQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    workers: {
      __typename: 'WorkerPage';
      items: Array<{
        __typename: 'Worker';
        id: string;
        onboardingStatus: OnboardingStatusEnum | null;
        readyToWork: boolean;
        workerJurisdictions: Array<{
          __typename: 'Jurisdiction';
          state: string;
        }> | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      }>;
    };
  };
};

export type JobOfferCreateMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
  forceInvite: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type JobOfferCreateMutation = {
  __typename: 'Mutation';
  jobOfferCreate: {
    __typename: 'JobOfferCreatePayload';
    jobOffer: {
      __typename: 'JobOffer';
      id: string;
      acceptedAt: string | null;
      costRate: number;
      createdAt: string;
      payRate: number;
      rejectedAt: string | null;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        city: string | null;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        jobsWorked: number;
        shiftsWorked: number;
        jobsDropped: number;
        noShow: number | null;
        taxType: TaxTypeEnum;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
        }>;
        workerSkills: Array<{
          __typename: 'WorkerSkill';
          jobsWorked: number | null;
          skill: {
            __typename: 'Skill';
            id: string;
            imageUrl: string | null;
            name: string;
          };
        }>;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    };
  };
};

export type JobHireWorkerMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  payRate: InputMaybe<Scalars['PositiveInt']['input']>;
  workerId: Scalars['ID']['input'];
  forceHire: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type JobHireWorkerMutation = {
  __typename: 'Mutation';
  jobHireWorker: {
    __typename: 'JobHireWorkerPayload';
    job: {
      __typename: 'Job';
      id: string;
      hiredWorkersCount: number;
      jobWorkers: Array<{
        __typename: 'JobWorker';
        id: string;
        costRate: number;
        payRate: number;
        dropReason: string | null;
        droppedAt: string | null;
        dismissReason: string | null;
        dismissedAt: string | null;
        noShowMarkedAt: string | null;
        showed: boolean | null;
        status: JobWorkerStatusEnum;
        noShowMarkedBy: {
          __typename: 'User';
          firstName: string;
          middleName: string | null;
          lastName: string;
        } | null;
        dismissedBy: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          city: string | null;
          state: string | null;
          averageRating: number;
          jobsRatingCount: number;
          jobsWorked: number;
          shiftsWorked: number;
          jobsDropped: number;
          noShow: number | null;
          taxType: TaxTypeEnum;
          activeCertificates: Array<{
            __typename: 'Certificate';
            id: string;
            name: string;
            imageUrl: string | null;
          }>;
          workerSkills: Array<{
            __typename: 'WorkerSkill';
            jobsWorked: number | null;
            skill: {
              __typename: 'Skill';
              id: string;
              imageUrl: string | null;
              name: string;
            };
          }>;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      }>;
    };
  };
  workerAssignJobWorkplace: {
    __typename: 'WorkerAssignJobWorkplacePayload';
    worker: {
      __typename: 'Worker';
      onboardingStatus: OnboardingStatusEnum | null;
      id: string;
      active: boolean | null;
      gender: GenderEnum;
      heardFrom: string | null;
      ownsCar: boolean;
      user: {
        __typename: 'User';
        firstName: string;
        lastName: string;
        language: LanguageEnum;
        secondaryLanguage: LanguageEnum | null;
      };
    };
  };
};

export type BulkEditTimesheetMutationVariables = Exact<{
  jobId: Scalars['ID']['input'];
  jobWorkerIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  action: TimesheetsBulkEditActionEnum;
  editInfo: InputMaybe<BulkEditTimesheetsInput>;
}>;

export type BulkEditTimesheetMutation = {
  __typename: 'Mutation';
  timesheetsBulkEdit: {
    __typename: 'TimesheetsBulkEditPayload';
    message: string;
  };
};

export type GetWorkerProfileQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type GetWorkerProfileQuery = {
  __typename: 'Query';
  worker: {
    __typename: 'Worker';
    id: string;
    avatarUrl: string | null;
    city: string | null;
    state: string | null;
    averageRating: number;
    jobsRatingCount: number;
    jobsWorked: number;
    shiftsWorked: number;
    jobsDropped: number;
    noShow: number | null;
    taxType: TaxTypeEnum;
    activeCertificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
    }>;
    workerSkills: Array<{
      __typename: 'WorkerSkill';
      jobsWorked: number | null;
      skill: {
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      };
    }>;
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      middleName: string | null;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    };
  };
};

export type GetJobWorkersQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type GetJobWorkersQuery = {
  __typename: 'Query';
  job: {
    __typename: 'Job';
    id: string;
    jobWorkers: Array<{
      __typename: 'JobWorker';
      status: JobWorkerStatusEnum;
      checkInStatus: JobWorkerCheckInStatusEnum;
      timesheets: Array<{
        __typename: 'Timesheet';
        checkinAt: string | null;
        checkoutAt: string | null;
        reportedCheckinAt: string | null;
        reportedCheckoutAt: string | null;
        approvedCheckinAt: string | null;
        approvedCheckoutAt: string | null;
      }>;
      worker: {
        __typename: 'Worker';
        avatarUrl: string | null;
        id: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
  };
};

export type ListJobsForMonitorQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  startDate: Scalars['ISO8601Date']['input'];
  filters: InputMaybe<JobFilterSetInput>;
  timeZone: InputMaybe<Scalars['String']['input']>;
}>;

export type ListJobsForMonitorQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    shiftsByDay: Array<{
      __typename: 'Shift';
      id: string;
      endAt: string;
      startAt: string;
      job: {
        __typename: 'Job';
        id: string;
        hiredWorkersCount: number;
        quantity: number;
        published: boolean;
        lastShiftEndAt: string;
        firstShiftStartAt: string;
        status: JobStatusEnum;
        checkinWorkerCount: number | null;
        hasNoShow: boolean;
        account: {
          __typename: 'Account';
          id: string;
          name: string;
          customer: { __typename: 'Customer'; id: string; name: string };
        };
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          city: string | null;
          state: string | null;
          zip: string | null;
          timezone: string;
        };
        order: { __typename: 'Order'; id: string };
        skill: { __typename: 'Skill'; id: string; name: string };
      };
    }>;
  };
};

export type GetJobSubscribersQueryVariables = Exact<{
  jobId: Scalars['Int']['input'];
}>;

export type GetJobSubscribersQuery = {
  __typename: 'Query';
  jobSubscriber: Array<{
    __typename: 'JobSubscriber';
    id: string;
    jobId: string;
    subscriberEmail: string;
  }>;
};

export type JobAddSubscriberMutationVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  shiftId: Scalars['ID']['input'];
  subscriberEmail: Scalars['String']['input'];
}>;

export type JobAddSubscriberMutation = {
  __typename: 'Mutation';
  jobAddSubscriber: {
    __typename: 'JobAddSubscriberPayload';
    job: { __typename: 'Job'; id: string };
  };
};

export type JobRemoveSubscriberMutationVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  jobSubscriberId: Scalars['Int']['input'];
  tenantId: Scalars['ID']['input'];
}>;

export type JobRemoveSubscriberMutation = {
  __typename: 'Mutation';
  jobRemoveSubscriber: {
    __typename: 'JobRemoveSubscriberPayload';
    agency: { __typename: 'Agency'; id: string };
  };
};

export type GetJobOldQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type GetJobOldQuery = {
  __typename: 'Query';
  job: {
    __typename: 'Job';
    id: string;
    jobType: JobTypeEnum;
    taxType: TaxTypeEnum | null;
    addressInstructions: string | null;
    cancelledAt: string | null;
    contactInstructions: string | null;
    costRate: number;
    firstShiftStartAt: string;
    hiredWorkersCount: number;
    instructions: string | null;
    lastShiftEndAt: string;
    payRate: number;
    postedAt: string | null;
    createdAt: string;
    markupPercent: number | null;
    isRateLock: boolean | null;
    minPayRate: number;
    published: boolean;
    quantity: number;
    isHoliday: boolean;
    mandatoryBreakTime: any | null;
    originalCostRate: number;
    originalPayRate: number;
    status: JobStatusEnum;
    uniformInstructions: string | null;
    account: {
      __typename: 'Account';
      id: string;
      name: string;
      phoneNumber: string | null;
      rateType: AccountRateTypeEnum;
      markupPercent: number | null;
      customer: {
        __typename: 'Customer';
        id: string;
        name: string;
        ltaAllowed: boolean | null;
        jobTaxType: string | null;
        workerRelationships: Array<{
          __typename: 'WorkerRelationship';
          id: string;
          kind: RelationshipKindEnum;
          worker: {
            __typename: 'Worker';
            id: string;
            avatarUrl: string | null;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              middleName: string | null;
              lastName: string;
              email: string;
              phoneNumber: string | null;
            };
          };
        }>;
        addresses: Array<{
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
        }>;
      };
    };
    address: {
      __typename: 'Address';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      timezone: string;
      city: string | null;
      state: string | null;
      zip: string | null;
      coords: { __typename: 'Point'; latitude: number; longitude: number };
    };
    contact: {
      __typename: 'CustomerAdmin';
      id: string;
      avatarUrl: string | null;
      user: {
        __typename: 'User';
        id: string;
        email: string;
        firstName: string;
        middleName: string | null;
        lastName: string;
        phoneNumber: string | null;
      };
    };
    jobOffers: Array<{
      __typename: 'JobOffer';
      id: string;
      acceptedAt: string | null;
      costRate: number;
      createdAt: string;
      payRate: number;
      rejectedAt: string | null;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        city: string | null;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        jobsWorked: number;
        shiftsWorked: number;
        jobsDropped: number;
        noShow: number | null;
        taxType: TaxTypeEnum;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
        }>;
        workerSkills: Array<{
          __typename: 'WorkerSkill';
          jobsWorked: number | null;
          skill: {
            __typename: 'Skill';
            id: string;
            imageUrl: string | null;
            name: string;
          };
        }>;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
    jobRequests: Array<{
      __typename: 'JobRequest';
      id: string;
      createdAt: string;
      status: JobRequestStatusEnum;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
        };
      };
    }>;
    jobWorkers: Array<{
      __typename: 'JobWorker';
      id: string;
      costRate: number;
      payRate: number;
      dropReason: string | null;
      droppedAt: string | null;
      dismissReason: string | null;
      dismissedAt: string | null;
      noShowMarkedAt: string | null;
      showed: boolean | null;
      status: JobWorkerStatusEnum;
      noShowMarkedBy: {
        __typename: 'User';
        firstName: string;
        middleName: string | null;
        lastName: string;
      } | null;
      dismissedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      worker: {
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        city: string | null;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        jobsWorked: number;
        shiftsWorked: number;
        jobsDropped: number;
        noShow: number | null;
        taxType: TaxTypeEnum;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
          imageUrl: string | null;
        }>;
        workerSkills: Array<{
          __typename: 'WorkerSkill';
          jobsWorked: number | null;
          skill: {
            __typename: 'Skill';
            id: string;
            imageUrl: string | null;
            name: string;
          };
        }>;
        user: {
          __typename: 'User';
          id: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      };
    }>;
    order: { __typename: 'Order'; id: string; markupPercent: number | null };
    shifts: Array<{
      __typename: 'Shift';
      id: string;
      endAt: string;
      startAt: string;
    }>;
    skill: {
      __typename: 'Skill';
      id: string;
      name: string;
      imageUrl: string | null;
      descriptionEn: string | null;
    };
    timesheets: Array<{
      __typename: 'Timesheet';
      id: string;
      tipAmount: any | null;
      approvedAt: string | null;
      rejectedAt: string | null;
      approvedBreakMinutes: any;
      approvedCheckinAt: string | null;
      approvedCheckoutAt: string | null;
      approvedMinutes: any;
      netApprovedTime: any;
      checkinAt: string | null;
      createdAt: string;
      rating: number | null;
      updatedAt: string;
      updatedByType: string | null;
      checkoutAt: string | null;
      payRate: number;
      reportedCheckinAt: string | null;
      reportedCheckoutAt: string | null;
      reportedBreakMinutes: any;
      reportComment: string | null;
      status: TimesheetStatusEnum;
      timesheetComments: Array<{
        __typename: 'TimesheetComment';
        actorType: string | null;
        body: string | null;
        createdAt: string | null;
        actor: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }>;
      updatedBy: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      job: { __typename: 'Job'; id: string };
      checkinCoords: {
        __typename: 'Point';
        latitude: number;
        longitude: number;
      } | null;
      checkoutCoords: {
        __typename: 'Point';
        latitude: number;
        longitude: number;
      } | null;
      jobWorker: {
        __typename: 'JobWorker';
        id: string;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          user: {
            __typename: 'User';
            id: string;
            email: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        };
      };
      shift: {
        __typename: 'Shift';
        id: string;
        endAt: string;
        startAt: string;
      };
    }>;
    uniform: {
      __typename: 'Uniform';
      id: string;
      name: string;
      imageUrl: string | null;
      defaultInstructions: string;
    };
  };
};

export type ListJobsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type ListJobsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    jobs: {
      __typename: 'JobPage';
      items: Array<{
        __typename: 'Job';
        id: string;
        quantity: number;
        hiredWorkersCount: number;
        firstShiftStartAt: string;
        lastShiftEndAt: string;
        skill: { __typename: 'Skill'; id: string; name: string };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type GetMyTeamQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  filters: InputMaybe<CustomerAdminFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type GetMyTeamQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    admins: {
      __typename: 'CustomerAdminPage';
      items: Array<{
        __typename: 'CustomerAdmin';
        id: string;
        acceptedAt: string | null;
        avatarUrl: string | null;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string | null;
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type CreateNotificationMutationVariables = Exact<{
  link: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  notificationType: NotificationTypeEnum;
  publishAt: Scalars['ISO8601DateTime']['input'];
  title: Scalars['String']['input'];
}>;

export type CreateNotificationMutation = {
  __typename: 'Mutation';
  notificationCreate: {
    __typename: 'NotificationCreatePayload';
    notification: {
      __typename: 'Notification';
      id: string;
      link: string | null;
      message: string;
      notificationType: NotificationTypeEnum;
      publishAt: string;
      title: string;
    };
  };
};

export type UpdateNotificationMutationVariables = Exact<{
  link: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  notificationType: NotificationTypeEnum;
  publishAt: Scalars['ISO8601DateTime']['input'];
  title: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}>;

export type UpdateNotificationMutation = {
  __typename: 'Mutation';
  notificationUpdate: {
    __typename: 'NotificationUpdatePayload';
    notification: {
      __typename: 'Notification';
      id: string;
      link: string | null;
      message: string;
      notificationType: NotificationTypeEnum;
      publishAt: string;
      title: string;
    };
  };
};

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteNotificationMutation = {
  __typename: 'Mutation';
  notificationDelete: void | null;
};

export type GetNotificationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage?: InputMaybe<Scalars['PositiveInt']['input']>;
}>;

export type GetNotificationsQuery = {
  __typename: 'Query';
  notifications: {
    __typename: 'NotificationPage';
    items: Array<{
      __typename: 'Notification';
      id: string;
      link: string | null;
      message: string;
      notificationType: NotificationTypeEnum;
      publishAt: string;
      title: string;
      status: NotificationStatusEnum;
    }>;
    pageInfo: {
      __typename: 'PageInfo';
      totalItems: number;
      totalPages: number;
    };
  };
};

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type GetOrderQuery = {
  __typename: 'Query';
  order: {
    __typename: 'Order';
    id: string;
    createdAt: string;
    cancelledAt: string | null;
    startAt: string;
    endAt: string;
    jobsCount: number;
    account: {
      __typename: 'Account';
      id: string;
      name: string;
      customer: {
        __typename: 'Customer';
        id: string;
        logoUrl: string | null;
        name: string;
        jobTaxType: string | null;
        ltaAllowed: boolean | null;
      };
    };
    jobs: Array<{
      __typename: 'Job';
      id: string;
      approvalStatus: JobApprovalStatusEnum;
      costRate: number;
      mandatoryBreakTime: any | null;
      payRate: number;
      quantity: number;
      taxType: TaxTypeEnum | null;
      hiredWorkersCount: number;
      firstShiftStartAt: string;
      lastShiftEndAt: string;
      cancelledAt: string | null;
      published: boolean;
      reason: string | null;
      jobType: JobTypeEnum;
      needsApproval: boolean;
      jobWorkers: Array<{
        __typename: 'JobWorker';
        showed: boolean | null;
        status: JobWorkerStatusEnum;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          city: string | null;
          state: string | null;
          averageRating: number;
          jobsRatingCount: number;
          jobsWorked: number;
          shiftsWorked: number;
          jobsDropped: number;
          noShow: number | null;
          taxType: TaxTypeEnum;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      }>;
      address: {
        __typename: 'Address';
        id: string;
        state: string | null;
        timezone: string;
      };
      shifts: Array<{
        __typename: 'Shift';
        id: string;
        startAt: string;
        endAt: string;
      }>;
      jobOffers: Array<{
        __typename: 'JobOffer';
        id: string;
        acceptedAt: string | null;
        worker: {
          __typename: 'Worker';
          id: string;
          avatarUrl: string | null;
          city: string | null;
          state: string | null;
          averageRating: number;
          jobsRatingCount: number;
          jobsWorked: number;
          shiftsWorked: number;
          jobsDropped: number;
          noShow: number | null;
          taxType: TaxTypeEnum;
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
            email: string;
            phoneNumber: string | null;
          };
        };
      }>;
      timesheets: Array<{
        __typename: 'Timesheet';
        id: string;
        status: TimesheetStatusEnum;
      }>;
      skill: {
        __typename: 'Skill';
        id: string;
        name: string;
        imageUrl: string | null;
        gravyTrained: boolean | null;
      };
      certificates: Array<{
        __typename: 'Certificate';
        id: string;
        name: string;
        imageUrl: string | null;
      }>;
      cancelUser:
        | {
            __typename: 'CustomerAdmin';
            id: string;
            avatarUrl: string | null;
            role: number;
            customer: { __typename: 'Customer'; id: string };
            user: {
              __typename: 'User';
              id: string;
              email: string;
              firstName: string;
              lastName: string;
            };
          }
        | {
            __typename: 'TenantAdmin';
            id: string;
            avatarUrl: string | null;
            role: number;
            user: {
              __typename: 'User';
              id: string;
              email: string;
              firstName: string;
              lastName: string;
            };
          }
        | null;
    }>;
  };
};

export type OrderWorkerItemFragment = {
  __typename: 'Worker';
  id: string;
  avatarUrl: string | null;
  city: string | null;
  state: string | null;
  averageRating: number;
  jobsRatingCount: number;
  jobsWorked: number;
  shiftsWorked: number;
  jobsDropped: number;
  noShow: number | null;
  taxType: TaxTypeEnum;
  user: {
    __typename: 'User';
    id: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    email: string;
    phoneNumber: string | null;
  };
};

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
}>;

export type CancelOrderMutation = {
  __typename: 'Mutation';
  jobCancelBatch: {
    __typename: 'JobCancelBatchPayload';
    order: {
      __typename: 'Order';
      id: string;
      jobs: Array<{
        __typename: 'Job';
        id: string;
        status: JobStatusEnum;
        reason: string | null;
        cancelledAt: string | null;
        cancelled: boolean;
      }>;
    };
  };
};

export type ListOrdersQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<OrderFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
  sortKey: InputMaybe<OrderSortKeyEnum>;
  reverse: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListOrdersQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    orders: {
      __typename: 'OrderPage';
      items: Array<{
        __typename: 'Order';
        id: string;
        uuid: string | null;
        startAt: string;
        endAt: string;
        jobsCount: number;
        status: OrderStatusEnum;
        jobs: Array<{
          __typename: 'Job';
          id: string;
          filled: boolean;
          cancelled: boolean;
          taxType: TaxTypeEnum | null;
          approvalStatus: JobApprovalStatusEnum;
          timesheets: Array<{
            __typename: 'Timesheet';
            id: string;
            status: TimesheetStatusEnum;
          }>;
          jobWorkers: Array<{
            __typename: 'JobWorker';
            showed: boolean | null;
            status: JobWorkerStatusEnum;
          }>;
          skill: { __typename: 'Skill'; id: string; imageUrl: string | null };
        }>;
        account: {
          __typename: 'Account';
          id: string;
          name: string;
          customer: {
            __typename: 'Customer';
            id: string;
            logoUrl: string | null;
            name: string;
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type ListPayrollsQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  page: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage: InputMaybe<Scalars['PositiveInt']['input']>;
}>;

export type ListPayrollsQuery = {
  __typename: 'Query';
  allPayrolls: {
    __typename: 'PayrollPage';
    items: Array<{
      __typename: 'Payroll';
      id: string;
      status: PayrollStatusEnum;
      approvedAt: string | null;
      approvalDeadline: string;
      payday: string;
      periodStart: string;
      periodEnd: string;
    }>;
    pageInfo: {
      __typename: 'PageInfo';
      totalItems: number;
      totalPages: number;
    };
  };
};

export type CreateNewPayrollMutationVariables = Exact<{
  weekDay: Scalars['ISO8601Date']['input'];
  payday: Scalars['ISO8601Date']['input'];
}>;

export type CreateNewPayrollMutation = {
  __typename: 'Mutation';
  payrollCreate: {
    __typename: 'PayrollCreatePayload';
    payroll: {
      __typename: 'Payroll';
      id: string;
      payday: string;
      periodEnd: string;
      periodStart: string;
      approvalDeadline: string;
      approvedAt: string | null;
    };
  };
};

export type ApprovePreviewPayrollMutationVariables = Exact<{
  payrollId: Scalars['ID']['input'];
}>;

export type ApprovePreviewPayrollMutation = {
  __typename: 'Mutation';
  payrollApprove: {
    __typename: 'PayrollApprovePayload';
    payroll: {
      __typename: 'Payroll';
      id: string;
      approvalDeadline: string;
      approvedAt: string | null;
      payday: string;
      periodEnd: string;
      periodStart: string;
      status: PayrollStatusEnum;
      payrollItems: {
        __typename: 'PayrollItemPage';
        items: Array<{
          __typename: 'PayrollItem';
          id: string;
          netPay: string | null;
          paymentMethod: string;
          status: PayrollItemStatusEnum;
          earnings: Array<{
            __typename: 'PayrollItemEarning';
            amount: string;
            description: string | null;
            hours: number | null;
            earningType: PayrollItemEarningEnum;
            address: {
              __typename: 'Address';
              id: string;
              addressLine1: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
            } | null;
            timesheet: {
              __typename: 'Timesheet';
              id: string;
              updatedByType: string | null;
              payRate: number;
              tipAmount: any | null;
              updatedBy: {
                __typename: 'User';
                id: string;
                firstName: string;
                lastName: string;
              } | null;
              job: {
                __typename: 'Job';
                id: string;
                customer: { __typename: 'Customer'; id: string; name: string };
                account: { __typename: 'Account'; id: string; name: string };
                order: { __typename: 'Order'; id: string };
              };
            } | null;
          }>;
          worker: {
            __typename: 'Worker';
            user: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
            };
          } | null;
          taxes: Array<{
            __typename: 'PayrollItemTax';
            amount: string;
            description: string;
            payer: string;
            checkTaxId: string;
          }>;
        }>;
      } | null;
      totals: {
        __typename: 'PayrollTotals';
        companyTaxes: string;
        companyBenefits: string;
        postTaxDeductions: string;
        liability: string;
        cashRequirement: string;
        employeeGross: string;
        employeeTaxes: string;
        employeeBenefits: string;
        employeeNet: string;
        employeeReimbursements: string;
      };
    };
  };
};

export type DeletePayrollMutationVariables = Exact<{
  payrollId: Scalars['ID']['input'];
}>;

export type DeletePayrollMutation = {
  __typename: 'Mutation';
  payrollDelete: void | null;
};

export type GetPayrollDetailQueryVariables = Exact<{
  payrollId: Scalars['ID']['input'];
}>;

export type GetPayrollDetailQuery = {
  __typename: 'Query';
  payroll: {
    __typename: 'Payroll';
    id: string;
    approvalDeadline: string;
    approvedAt: string | null;
    payday: string;
    periodEnd: string;
    periodStart: string;
    status: PayrollStatusEnum;
    payrollItems: {
      __typename: 'PayrollItemPage';
      items: Array<{
        __typename: 'PayrollItem';
        id: string;
        netPay: string | null;
        paymentMethod: string;
        status: PayrollItemStatusEnum;
        earnings: Array<{
          __typename: 'PayrollItemEarning';
          amount: string;
          description: string | null;
          hours: number | null;
          earningType: PayrollItemEarningEnum;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
          } | null;
          timesheet: {
            __typename: 'Timesheet';
            id: string;
            updatedByType: string | null;
            payRate: number;
            tipAmount: any | null;
            updatedBy: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
            } | null;
            job: {
              __typename: 'Job';
              id: string;
              customer: { __typename: 'Customer'; id: string; name: string };
              account: { __typename: 'Account'; id: string; name: string };
              order: { __typename: 'Order'; id: string };
            };
          } | null;
        }>;
        worker: {
          __typename: 'Worker';
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        } | null;
        taxes: Array<{
          __typename: 'PayrollItemTax';
          amount: string;
          description: string;
          payer: string;
          checkTaxId: string;
        }>;
        deductions: Array<{
          __typename: 'PostTaxDeduction';
          amount: string;
          checkPtdId: string;
          description: string;
          ptdType: string;
        }> | null;
      }>;
    } | null;
    contractorPayments: {
      __typename: 'ContractorPaymentPage';
      items: Array<{
        __typename: 'ContractorPayment';
        id: string;
        netPay: string | null;
        paymentMethod: string;
        status: PayrollItemStatusEnum;
        contractorPaymentItems: Array<{
          __typename: 'ContractorPaymentItem';
          amount: string;
          description: string | null;
          hours: number | null;
          earningType: PayrollItemEarningEnum;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
          } | null;
          timesheet: {
            __typename: 'Timesheet';
            id: string;
            updatedByType: string | null;
            payRate: number;
            tipAmount: any | null;
            updatedBy: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
            } | null;
            job: {
              __typename: 'Job';
              id: string;
              customer: { __typename: 'Customer'; id: string; name: string };
              account: { __typename: 'Account'; id: string; name: string };
              order: { __typename: 'Order'; id: string };
            };
          } | null;
        }>;
        worker: {
          __typename: 'Worker';
          user: {
            __typename: 'User';
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        } | null;
      }>;
    } | null;
    totals: {
      __typename: 'PayrollTotals';
      companyTaxes: string;
      companyBenefits: string;
      postTaxDeductions: string;
      liability: string;
      cashRequirement: string;
      employeeGross: string;
      employeeTaxes: string;
      employeeBenefits: string;
      employeeNet: string;
      employeeReimbursements: string;
    };
  };
};

export type GetPreviewPayrollQueryVariables = Exact<{
  payrollId: Scalars['ID']['input'];
}>;

export type GetPreviewPayrollQuery = {
  __typename: 'Query';
  payrollPreview: {
    __typename: 'Payroll';
    id: string;
    approvalDeadline: string;
    approvedAt: string | null;
    payday: string;
    periodEnd: string;
    periodStart: string;
    status: PayrollStatusEnum;
    payrollItems: {
      __typename: 'PayrollItemPage';
      items: Array<{
        __typename: 'PayrollItem';
        id: string;
        netPay: string | null;
        paymentMethod: string;
        status: PayrollItemStatusEnum;
        earnings: Array<{
          __typename: 'PayrollItemEarning';
          amount: string;
          description: string | null;
          hours: number | null;
          earningType: PayrollItemEarningEnum;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
          } | null;
          timesheet: {
            __typename: 'Timesheet';
            id: string;
            updatedByType: string | null;
            payRate: number;
            tipAmount: any | null;
            updatedBy: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
            } | null;
            job: {
              __typename: 'Job';
              id: string;
              customer: { __typename: 'Customer'; id: string; name: string };
              account: { __typename: 'Account'; id: string; name: string };
              order: { __typename: 'Order'; id: string };
            };
          } | null;
        }>;
        worker: {
          __typename: 'Worker';
          user: {
            __typename: 'User';
            id: string;
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        } | null;
        taxes: Array<{
          __typename: 'PayrollItemTax';
          amount: string;
          description: string;
          payer: string;
          checkTaxId: string;
        }>;
        deductions: Array<{
          __typename: 'PostTaxDeduction';
          amount: string;
          checkPtdId: string;
          description: string;
          ptdType: string;
        }> | null;
      }>;
    } | null;
    contractorPayments: {
      __typename: 'ContractorPaymentPage';
      items: Array<{
        __typename: 'ContractorPayment';
        id: string;
        netPay: string | null;
        paymentMethod: string;
        status: PayrollItemStatusEnum;
        contractorPaymentItems: Array<{
          __typename: 'ContractorPaymentItem';
          amount: string;
          description: string | null;
          hours: number | null;
          earningType: PayrollItemEarningEnum;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
          } | null;
          timesheet: {
            __typename: 'Timesheet';
            id: string;
            updatedByType: string | null;
            payRate: number;
            tipAmount: any | null;
            updatedBy: {
              __typename: 'User';
              id: string;
              firstName: string;
              lastName: string;
            } | null;
            job: {
              __typename: 'Job';
              id: string;
              customer: { __typename: 'Customer'; id: string; name: string };
              account: { __typename: 'Account'; id: string; name: string };
              order: { __typename: 'Order'; id: string };
            };
          } | null;
        }>;
        worker: {
          __typename: 'Worker';
          user: {
            __typename: 'User';
            firstName: string;
            middleName: string | null;
            lastName: string;
          };
        } | null;
      }>;
    } | null;
    totals: {
      __typename: 'PayrollTotals';
      companyTaxes: string;
      companyBenefits: string;
      postTaxDeductions: string;
      liability: string;
      cashRequirement: string;
      employeeGross: string;
      employeeTaxes: string;
      employeeBenefits: string;
      employeeNet: string;
      employeeReimbursements: string;
    };
  };
};

export type ListPayrollJournalReportQueryVariables = Exact<{
  payrollId: Scalars['ID']['input'];
}>;

export type ListPayrollJournalReportQuery = {
  __typename: 'Query';
  payrollJournalReport: boolean;
};

export type ListActiveUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListActiveUsersQuery = {
  __typename: 'Query';
  activeUserListReport: boolean;
};

export type ListActiveUsersBySkilQueryVariables = Exact<{
  skills: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type ListActiveUsersBySkilQuery = {
  __typename: 'Query';
  activeUsersBySkillReport: Array<{
    __typename: 'ActiveUsersBySkillReport';
    email: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    jobsWorked: number;
    phone: string;
    skill: string;
    city: string;
    state: string;
  }>;
};

export type ListCustomersReportQueryVariables = Exact<{ [key: string]: never }>;

export type ListCustomersReportQuery = {
  __typename: 'Query';
  customerListReport: Array<{
    __typename: 'CustomerListReport';
    accountAgeDays: number;
    accountId: string;
    accountName: string;
    accountRepEmail: string;
    accountRepName: string;
    accountRepPhone: string;
    city: string;
    clientUrl: string;
    customerId: string;
    customerName: string;
    latestOrder: string | null;
    ordersFiveMonthsAgo: number | null;
    ordersFourMonthsAgo: number | null;
    ordersLastMonth: number | null;
    ordersSixMonthsAgo: number | null;
    ordersThisMonth: number | null;
    ordersThreeMonthsAgo: number | null;
    ordersTwoMonthsAgo: number | null;
    state: string;
    totalJobs: number;
    zip: string;
  }> | null;
};

export type ListInvoicesReportQueryVariables = Exact<{
  from: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  to: InputMaybe<Scalars['ISO8601DateTime']['input']>;
}>;

export type ListInvoicesReportQuery = {
  __typename: 'Query';
  invoicingReport: Array<{
    __typename: 'InvoicingReport';
    companyNumber: string;
    companyName: string;
    orderNumber: string;
    invoiceNumber: string | null;
    invoiceDate: string | null;
    invoiceStatus: string | null;
    jobDate: string;
    unapprovedHours: number;
    invoiced: string;
    invoicePaid: string | null;
    openInvoice: string | null;
  }> | null;
};

export type RevenueByRangeQueryVariables = Exact<{
  from: Scalars['ISO8601DateTime']['input'];
  to: Scalars['ISO8601DateTime']['input'];
}>;

export type RevenueByRangeQuery = {
  __typename: 'Query';
  revenueReport: Array<{
    __typename: 'RevenueReport';
    company: string;
    region: string;
    totalHours: number;
    totalRevenue: number;
    totalPayrollExpense: number;
    netProfit: number;
  }> | null;
};

export type TimesheetsByPayPeriodQueryVariables = Exact<{
  payPeriod: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type TimesheetsByPayPeriodQuery = {
  __typename: 'Query';
  timesheetReport: string;
};

export type WorkerJobWorkersFragment = {
  __typename: 'JobWorkerPage';
  items: Array<{
    __typename: 'JobWorker';
    id: string;
    costRate: number;
    payRate: number;
    order: { __typename: 'Order'; id: string };
    job: {
      __typename: 'Job';
      id: string;
      cancelled: boolean;
      firstShiftStartAt: string;
      lastShiftEndAt: string;
      address: {
        __typename: 'Address';
        id: string;
        addressLine1: string | null;
        addressLine2: string | null;
        timezone: string;
        city: string | null;
        state: string | null;
        zip: string | null;
      };
      customer: {
        __typename: 'Customer';
        id: string;
        name: string;
        logoUrl: string | null;
      };
      skill: {
        __typename: 'Skill';
        id: string;
        name: string;
        imageUrl: string | null;
      };
    };
  }>;
  pageInfo: { __typename: 'PageInfo'; totalItems: number; totalPages: number };
};

export type WorkerSkillFragment = {
  __typename: 'Skill';
  id: string;
  imageUrl: string | null;
  name: string;
};

export type WorkerCoordsFragment = {
  __typename: 'Point';
  latitude: number;
  longitude: number;
};

export type GetWorkerQueryVariables = Exact<{
  filterDate: Scalars['ISO8601DateTime']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type GetWorkerQuery = {
  __typename: 'Query';
  worker: {
    __typename: 'Worker';
    id: string;
    active: boolean | null;
    hasSsn: boolean;
    reenableDate: string | null;
    disableReason: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    timezone: string;
    avatarUrl: string | null;
    city: string | null;
    interviewStatus: InterviewStatusEnum | null;
    checkEmployeeId: string | null;
    candidateStatus: CandidateStatusEnum;
    onboardingStatus: OnboardingStatusEnum | null;
    backgroundStatus: BackgroundStatusEnum | null;
    i9Status: I9StatusEnum | null;
    onboardingDocsStatus: SignatureRequestStatusEnum | null;
    gender: GenderEnum;
    heardFrom: string | null;
    ownsCar: boolean;
    state: string | null;
    status: WorkerStatusEnum;
    zip: string | null;
    taxType: TaxTypeEnum;
    averageRating: number;
    jobsDropped: number;
    jobsWorked: number;
    shiftsWorked: number;
    jobsRatingCount: number;
    ssnVerificationRetryCount: number | null;
    verificationCode: string | null;
    verificationRequiredAt: string | null;
    verificationStatus: SsnVerificationStatusEnum;
    verifiedAt: string | null;
    deactivatedByUser: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      middleName: string | null;
    } | null;
    comments: Array<{
      __typename: 'Comment';
      id: string;
      body: string | null;
      createdAt: string;
      actor:
        | {
            __typename: 'CustomerAdmin';
            id: string;
            acceptedAt: string | null;
            avatarUrl: string | null;
            active: boolean;
            user: {
              __typename: 'User';
              id: string;
              active: boolean;
              email: string;
              firstName: string;
              language: LanguageEnum;
              lastName: string;
              emailConfirmed: boolean | null;
              channelId: string;
            };
          }
        | {
            __typename: 'TenantAdmin';
            id: string;
            avatarUrl: string | null;
            tenant: { __typename: 'Tenant'; id: string };
            user: {
              __typename: 'User';
              id: string;
              active: boolean;
              email: string;
              firstName: string;
              lastName: string;
            };
          };
      commentable:
        | { __typename: 'Account' }
        | { __typename: 'Customer' }
        | { __typename: 'CustomerAdmin' }
        | { __typename: 'Job' }
        | { __typename: 'Order' }
        | { __typename: 'Worker' };
    }>;
    coords: { __typename: 'Point'; latitude: number; longitude: number } | null;
    skillRequests: Array<{
      __typename: 'SkillRequest';
      id: string;
      status: SkillRequestStatusEnum;
      skill: {
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        displayNameEn: string | null;
        name: string;
      };
    }>;
    jobWorkers: {
      __typename: 'JobWorkerPage';
      items: Array<{
        __typename: 'JobWorker';
        id: string;
        costRate: number;
        payRate: number;
        order: { __typename: 'Order'; id: string };
        job: {
          __typename: 'Job';
          id: string;
          cancelled: boolean;
          firstShiftStartAt: string;
          lastShiftEndAt: string;
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            addressLine2: string | null;
            timezone: string;
            city: string | null;
            state: string | null;
            zip: string | null;
          };
          customer: {
            __typename: 'Customer';
            id: string;
            name: string;
            logoUrl: string | null;
          };
          skill: {
            __typename: 'Skill';
            id: string;
            name: string;
            imageUrl: string | null;
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
    pendingJobOffers: Array<{
      __typename: 'JobOffer';
      id: string;
      costRate: number;
      createdAt: string;
      payRate: number;
      job: {
        __typename: 'Job';
        id: string;
        lastShiftEndAt: string;
        firstShiftStartAt: string;
        cancelled: boolean;
        order: { __typename: 'Order'; id: string };
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          addressLine2: string | null;
          timezone: string;
          city: string | null;
          state: string | null;
          zip: string | null;
        };
        customer: {
          __typename: 'Customer';
          id: string;
          name: string;
          logoUrl: string | null;
        };
        skill: {
          __typename: 'Skill';
          id: string;
          name: string;
          imageUrl: string | null;
        };
      };
    }>;
    pendingJobRequests: Array<{
      __typename: 'JobRequest';
      id: string;
      createdAt: string;
      job: {
        __typename: 'Job';
        id: string;
        lastShiftEndAt: string;
        firstShiftStartAt: string;
        cancelled: boolean;
        order: { __typename: 'Order'; id: string };
        address: {
          __typename: 'Address';
          id: string;
          addressLine1: string | null;
          addressLine2: string | null;
          timezone: string;
          city: string | null;
          state: string | null;
          zip: string | null;
        };
        customer: {
          __typename: 'Customer';
          id: string;
          name: string;
          logoUrl: string | null;
        };
        skill: {
          __typename: 'Skill';
          id: string;
          name: string;
          imageUrl: string | null;
        };
      };
    }>;
    references: Array<{
      __typename: 'WorkerReference';
      id: string;
      city: string | null;
      duration: string | null;
      employerName: string | null;
      name: string | null;
      phoneNumber: string | null;
      summary: string | null;
      title: string | null;
    }>;
    skills: Array<{
      __typename: 'Skill';
      id: string;
      imageUrl: string | null;
      name: string;
    }>;
    activeCertificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
    }>;
    user: {
      __typename: 'User';
      id: string;
      email: string;
      firstName: string;
      middleName: string | null;
      language: LanguageEnum;
      lastName: string;
      phoneNumber: string | null;
      secondaryLanguage: LanguageEnum | null;
      smartphoneType: SmartphoneTypeEnum | null;
      dateOfBirth: string | null;
    };
  };
};

export type GetWorkerJobsQueryVariables = Exact<{
  filters: InputMaybe<JobWorkerFilterSetInput>;
  workerId: Scalars['ID']['input'];
  page: InputMaybe<Scalars['PositiveInt']['input']>;
  perPage: InputMaybe<Scalars['PositiveInt']['input']>;
}>;

export type GetWorkerJobsQuery = {
  __typename: 'Query';
  worker: {
    __typename: 'Worker';
    id: string;
    jobWorkers: {
      __typename: 'JobWorkerPage';
      items: Array<{
        __typename: 'JobWorker';
        showed: boolean | null;
        id: string;
        status: JobWorkerStatusEnum;
        job: {
          __typename: 'Job';
          status: JobStatusEnum;
          id: string;
          cancelled: boolean;
          firstShiftStartAt: string;
          lastShiftEndAt: string;
          order: { __typename: 'Order'; id: string };
          address: {
            __typename: 'Address';
            id: string;
            addressLine1: string | null;
            addressLine2: string | null;
            timezone: string;
            city: string | null;
            state: string | null;
            zip: string | null;
          };
          customer: {
            __typename: 'Customer';
            id: string;
            name: string;
            logoUrl: string | null;
          };
          skill: {
            __typename: 'Skill';
            id: string;
            name: string;
            imageUrl: string | null;
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type ResetWorkerPasswordMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type ResetWorkerPasswordMutation = {
  __typename: 'Mutation';
  adminResetWorkerPassword: void | null;
};

export type GetShowcaseLinkQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  typeInterview: WilloWorkerInterviewStatusEnum;
}>;

export type GetShowcaseLinkQuery = {
  __typename: 'Query';
  willoWorkerInterview: {
    __typename: 'WilloWorkerInterview';
    id: string;
    showcaseLink: string | null;
  } | null;
};

export type ChangeWorkerAddressMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
  coords: PointInput;
}>;

export type ChangeWorkerAddressMutation = {
  __typename: 'Mutation';
  workerChangeAddress: {
    __typename: 'WorkerChangeAddressPayload';
    worker: {
      __typename: 'Worker';
      id: string;
      addressLine1: string | null;
      addressLine2: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      coords: {
        __typename: 'Point';
        latitude: number;
        longitude: number;
      } | null;
    };
  };
};

export type UpdateBackgroundStatusMutationVariables = Exact<{
  backgroundStatus: BackgroundStatusEnum;
  workerId: Scalars['ID']['input'];
}>;

export type UpdateBackgroundStatusMutation = {
  __typename: 'Mutation';
  workerUpdateBackgroundCheck: {
    __typename: 'WorkerUpdateBackgroundCheckPayload';
    worker: {
      __typename: 'Worker';
      id: string;
      backgroundStatus: BackgroundStatusEnum | null;
    };
  };
};

export type AddWorkerCertificationMutationVariables = Exact<{
  certificateId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type AddWorkerCertificationMutation = {
  __typename: 'Mutation';
  workerAddCertificate: {
    __typename: 'WorkerAddCertificatePayload';
    worker: {
      __typename: 'Worker';
      id: string;
      activeCertificates: Array<{
        __typename: 'Certificate';
        id: string;
        name: string;
      }>;
    };
  };
};

export type ListAgencyCertificateQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type ListAgencyCertificateQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      name: string;
      imageUrl: string | null;
      certificateType: CertificateTypeEnum;
    }>;
  };
};

export type RemoveWorkerCertificateMutationVariables = Exact<{
  certificateId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type RemoveWorkerCertificateMutation = {
  __typename: 'Mutation';
  workerRemoveCertificate: {
    __typename: 'WorkerRemoveCertificatePayload';
    worker: {
      __typename: 'Worker';
      id: string;
      activeCertificates: Array<{
        __typename: 'Certificate';
        id: string;
        name: string;
      }>;
    };
  };
};

export type AddWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type AddWorkerSkillMutation = {
  __typename: 'Mutation';
  workerAddSkill: {
    __typename: 'WorkerAddSkillPayload';
    worker: {
      __typename: 'Worker';
      id: string;
      skills: Array<{
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      }>;
    };
  };
};

export type ListAgencySkillsQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
}>;

export type ListAgencySkillsQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    skillCategories: Array<{
      __typename: 'SkillCategory';
      id: string;
      name: string | null;
      skills: Array<{
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      }>;
    }>;
  };
};

export type RemoveWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type RemoveWorkerSkillMutation = {
  __typename: 'Mutation';
  workerRemoveSkill: {
    __typename: 'WorkerRemoveSkillPayload';
    worker: {
      __typename: 'Worker';
      id: string;
      skills: Array<{
        __typename: 'Skill';
        id: string;
        imageUrl: string | null;
        name: string;
      }>;
    };
  };
};

export type WorkerSignatureRequestsQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  type?: InputMaybe<SignatureRequestTypeEnum>;
}>;

export type WorkerSignatureRequestsQuery = {
  __typename: 'Query';
  workerSignatureRequestsTenant: Array<{
    __typename: 'WorkerSignatureRequest';
    id: string;
    documentUrl: string | null;
  }>;
};

export type WorkerResetVerificationMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type WorkerResetVerificationMutation = {
  __typename: 'Mutation';
  workerResetVerification: {
    __typename: 'WorkerResetVerificationPayload';
    worker: {
      __typename: 'Worker';
      active: boolean | null;
      addressLine1: string | null;
      addressLine2: string | null;
      approvedAt: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      averageRating: number;
      backgroundStatus: BackgroundStatusEnum | null;
      candidateStatus: CandidateStatusEnum;
      candidateStatusUpdatedAt: string | null;
      chatUid: string;
      checkEmployeeId: string | null;
      checkWorkplaceId: string | null;
      city: string | null;
      complianceId: string | null;
      createdAt: string;
      deactivatedAt: string | null;
      deactivatedBy: WorkerDeactivatedTypeEnum | null;
      defaultDistance: SearchRadiusEnum;
      deniedAt: string | null;
      disableReason: string | null;
      formi9Restartable: boolean;
      gender: GenderEnum;
      hasSsn: boolean;
      heardFrom: string | null;
      i9Status: I9StatusEnum | null;
      id: string;
      interviewStatus: InterviewStatusEnum | null;
      jobsDropped: number;
      jobsFailed: number;
      jobsRatingCount: number;
      jobsWorked: number;
      lastFourSsn: string | null;
      migrated: boolean | null;
      noShow: number | null;
      onboardingDocsStatus: SignatureRequestStatusEnum | null;
      onboardingStatus: OnboardingStatusEnum | null;
      ownsCar: boolean;
      readyToWork: boolean;
      reenableDate: string | null;
      shiftsWorked: number;
      ssnVerificationRetryCount: number | null;
      state: string | null;
      status: WorkerStatusEnum;
      taxType: TaxTypeEnum;
      tcAcceptedAt: string | null;
      timezone: string;
      updatedAt: string;
      usCitizenOrWorkPermit: boolean;
      verificationCode: string | null;
      verificationRequiredAt: string | null;
      verificationStatus: SsnVerificationStatusEnum;
      verifiedAt: string | null;
      zip: string | null;
    };
  };
};

export type UpdateTaxTypeMutationVariables = Exact<{
  taxType: TaxTypeEnum;
  workerId: Scalars['ID']['input'];
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateTaxTypeMutation = {
  __typename: 'Mutation';
  workerUpdateTaxType: {
    __typename: 'WorkerUpdateTaxTypePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type WorkerDisableMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
  reenableDate: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type WorkerDisableMutation = {
  __typename: 'Mutation';
  workerDisable: {
    __typename: 'WorkerDisablePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type WorkerEnableMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type WorkerEnableMutation = {
  __typename: 'Mutation';
  workerEnable: {
    __typename: 'WorkerEnablePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type WorkerUpdateProfileMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  dateOfBirth: Scalars['ISO8601DateTime']['input'];
  email: InputMaybe<Scalars['Email']['input']>;
  gender: InputMaybe<GenderEnum>;
  heardFrom: InputMaybe<Scalars['String']['input']>;
  language: InputMaybe<LanguageEnum>;
  ownsCar: InputMaybe<Scalars['Boolean']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  smartphoneType: InputMaybe<SmartphoneTypeEnum>;
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type WorkerUpdateProfileMutation = {
  __typename: 'Mutation';
  workerUpdateProfile: {
    __typename: 'WorkerUpdateProfilePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type UpdateInterviewStatusMutationVariables = Exact<{
  status: InterviewStatusEnum;
  workerInterviewId: Scalars['ID']['input'];
}>;

export type UpdateInterviewStatusMutation = {
  __typename: 'Mutation';
  workerInterviewUpdateV2: {
    __typename: 'WorkerInterviewUpdateV2Payload';
    willoWorkerInterview: { __typename: 'WilloWorkerInterview'; id: string };
  };
};

export type ChangeWorkerAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type ChangeWorkerAvatarMutation = {
  __typename: 'Mutation';
  workerChangeAvatar: {
    __typename: 'WorkerChangeAvatarPayload';
    worker: { __typename: 'Worker'; id: string; avatarUrl: string | null };
  };
};

export type BlockWorkerMutationVariables = Exact<{
  active: Scalars['Boolean']['input'];
  workerId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
}>;

export type BlockWorkerMutation = {
  __typename: 'Mutation';
  workerChangeActive: {
    __typename: 'WorkerChangeActivePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type AddWorkerReferenceMutationVariables = Exact<{
  city: Scalars['String']['input'];
  duration: DurationEnum;
  email: InputMaybe<Scalars['Email']['input']>;
  employerName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  summary: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
}>;

export type AddWorkerReferenceMutation = {
  __typename: 'Mutation';
  workerAddReference: {
    __typename: 'WorkerAddReferencePayload';
    reference: { __typename: 'WorkerReference'; id: string };
  };
};

export type CreateWorkerMutationVariables = Exact<{
  addressLine1: Scalars['String']['input'];
  addressLine2: InputMaybe<Scalars['String']['input']>;
  agencyId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  coords: PointInput;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  gender: GenderEnum;
  language: LanguageEnum;
  lastName: Scalars['String']['input'];
  ownsCar: Scalars['Boolean']['input'];
  phoneNumber: Scalars['String']['input'];
  secondaryLanguage: LanguageEnum;
  skillIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
}>;

export type CreateWorkerMutation = {
  __typename: 'Mutation';
  workerCreate: {
    __typename: 'WorkerCreatePayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type UpdateApplicantMutationVariables = Exact<{
  addressLine1: InputMaybe<Scalars['String']['input']>;
  addressLine2: InputMaybe<Scalars['String']['input']>;
  candidateStatus: CandidateStatusEnum;
  city: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  language: LanguageEnum;
  lastName: InputMaybe<Scalars['String']['input']>;
  ownsCar: Scalars['Boolean']['input'];
  dateOfBirth: InputMaybe<Scalars['DateOrEmptyString']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  skillIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  smartphoneType: SmartphoneTypeEnum;
  state: InputMaybe<Scalars['String']['input']>;
  taxType: TaxTypeEnum;
  workerId: Scalars['ID']['input'];
  zip: InputMaybe<Scalars['String']['input']>;
  hasSsn: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateApplicantMutation = {
  __typename: 'Mutation';
  workerUpdateApplicant: {
    __typename: 'WorkerUpdateApplicantPayload';
    worker: { __typename: 'Worker'; id: string };
  };
};

export type UpdateCandidateMutationVariables = Exact<{
  addressLine1: InputMaybe<Scalars['String']['input']>;
  addressLine2: InputMaybe<Scalars['String']['input']>;
  candidateStatus: CandidateStatusEnum;
  city: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  middleName: InputMaybe<Scalars['String']['input']>;
  language: InputMaybe<LanguageEnum>;
  dateOfBirth: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryLanguage: InputMaybe<LanguageEnum>;
  smartphoneType: InputMaybe<SmartphoneTypeEnum>;
  state: InputMaybe<Scalars['String']['input']>;
  workerId: Scalars['ID']['input'];
  zip: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCandidateMutation = {
  __typename: 'Mutation';
  workerUpdateCandidate: {
    __typename: 'WorkerUpdateCandidatePayload';
    worker: {
      __typename: 'Worker';
      id: string;
      candidateStatus: CandidateStatusEnum;
      user: {
        __typename: 'User';
        firstName: string;
        middleName: string | null;
        lastName: string;
      };
    };
  };
};

export type ListCandidatesQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<CandidateFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type ListCandidatesQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    candidates: {
      __typename: 'WorkerPage';
      items: Array<{
        __typename: 'Worker';
        id: string;
        avatarUrl: string | null;
        active: boolean | null;
        deactivatedAt: string | null;
        deactivatedBy: WorkerDeactivatedTypeEnum | null;
        disableReason: string | null;
        candidateStatus: CandidateStatusEnum;
        backgroundStatus: BackgroundStatusEnum | null;
        onboardingStatus: OnboardingStatusEnum | null;
        interviewStatus: InterviewStatusEnum | null;
        addressLine1: string | null;
        addressLine2: string | null;
        timezone: string;
        city: string | null;
        state: string | null;
        zip: string | null;
        status: WorkerStatusEnum;
        hasSsn: boolean;
        ownsCar: boolean;
        taxType: TaxTypeEnum;
        createdAt: string;
        updatedAt: string;
        deactivatedByUser: {
          __typename: 'User';
          firstName: string;
          id: string;
          lastName: string;
          middleName: string | null;
        } | null;
        coords: {
          __typename: 'Point';
          latitude: number;
          longitude: number;
        } | null;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          dateOfBirth: string | null;
          language: LanguageEnum;
          phoneNumber: string | null;
          secondaryLanguage: LanguageEnum | null;
          smartphoneType: SmartphoneTypeEnum | null;
        };
        references: Array<{
          __typename: 'WorkerReference';
          id: string;
          email: string | null;
          name: string | null;
          city: string | null;
          employerName: string | null;
          phoneNumber: string | null;
          summary: string | null;
          duration: string | null;
          title: string | null;
        }>;
        skills: Array<{
          __typename: 'Skill';
          id: string;
          imageUrl: string | null;
          name: string;
        }>;
        comments: Array<{ __typename: 'Comment'; body: string | null }>;
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type ListWorkersQueryVariables = Exact<{
  agencyId: Scalars['ID']['input'];
  filters: InputMaybe<WorkerFilterSetInput>;
  page: Scalars['PositiveInt']['input'];
  perPage: Scalars['PositiveInt']['input'];
}>;

export type ListWorkersQuery = {
  __typename: 'Query';
  agency: {
    __typename: 'Agency';
    id: string;
    workers: {
      __typename: 'WorkerPage';
      items: Array<{
        __typename: 'Worker';
        id: string;
        candidateStatus: CandidateStatusEnum;
        active: boolean | null;
        deactivatedAt: string | null;
        deactivatedBy: WorkerDeactivatedTypeEnum | null;
        disableReason: string | null;
        interviewStatus: InterviewStatusEnum | null;
        status: WorkerStatusEnum;
        avatarUrl: string | null;
        city: string | null;
        addressLine1: string | null;
        gender: GenderEnum;
        state: string | null;
        averageRating: number;
        jobsRatingCount: number;
        createdAt: string;
        updatedAt: string;
        deactivatedByUser: {
          __typename: 'User';
          firstName: string;
          id: string;
          lastName: string;
          middleName: string | null;
        } | null;
        skills: Array<{ __typename: 'Skill'; id: string; name: string }>;
        activeCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          name: string;
        }>;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          middleName: string | null;
          lastName: string;
          language: LanguageEnum;
          phoneNumber: string | null;
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        totalItems: number;
        totalPages: number;
      };
    };
  };
};

export type GetCurrentActorQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentActorQuery = {
  __typename: 'Query';
  currentActor:
    | {
        __typename: 'CustomerAdmin';
        id: string;
        avatarUrl: string | null;
        role: number;
        user: {
          __typename: 'User';
          id: string;
          email: string;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          channelId: string;
          roleId: UserRoleEnum;
        };
        customer: {
          __typename: 'Customer';
          id: string;
          name: string;
          status: number;
          onboardingStatus: CustomerOnboardingStatusEnum;
          ltaAllowed: boolean | null;
        };
      }
    | {
        __typename: 'TenantAdmin';
        id: string;
        avatarUrl: string | null;
        role: number;
        pendingCustomers: number;
        user: {
          __typename: 'User';
          id: string;
          active: boolean;
          firstName: string;
          language: LanguageEnum;
          lastName: string;
          email: string;
          emailConfirmed: boolean | null;
          phoneNumber: string | null;
          allowedPlatform: number;
          channelId: string;
          roleId: UserRoleEnum;
        };
      }
    | { __typename: 'Worker' };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['Email']['input'];
  app: TypeAppEnum;
}>;

export type ForgotPasswordMutation = {
  __typename: 'Mutation';
  userForgotPassword: void | null;
};

export type AdminLoginMutationVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
}>;

export type AdminLoginMutation = {
  __typename: 'Mutation';
  adminLogin: {
    __typename: 'AdminLoginPayload';
    accessToken: string;
    refreshToken: string;
    resetPassword: boolean;
    disabled: boolean | null;
    tenantAdmin: {
      __typename: 'TenantAdmin';
      id: string;
      avatarUrl: string | null;
      role: number;
      pendingCustomers: number;
      user: {
        __typename: 'User';
        id: string;
        active: boolean;
        firstName: string;
        language: LanguageEnum;
        lastName: string;
        email: string;
        emailConfirmed: boolean | null;
        phoneNumber: string | null;
        allowedPlatform: number;
        channelId: string;
        roleId: UserRoleEnum;
      };
    } | null;
    customerAdmin: {
      __typename: 'CustomerAdmin';
      id: string;
      avatarUrl: string | null;
      role: number;
      user: {
        __typename: 'User';
        id: string;
        email: string;
        firstName: string;
        language: LanguageEnum;
        lastName: string;
        channelId: string;
        roleId: UserRoleEnum;
      };
      customer: {
        __typename: 'Customer';
        id: string;
        name: string;
        status: number;
        onboardingStatus: CustomerOnboardingStatusEnum;
        ltaAllowed: boolean | null;
      };
    } | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename: 'Mutation';
  userResetPassword: void | null;
};

export type UserSetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  repeatedPassword: Scalars['String']['input'];
}>;

export type UserSetPasswordMutation = {
  __typename: 'Mutation';
  userAuthResetPassword: {
    __typename: 'UserAuthResetPasswordPayload';
    user: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      resetPassword: boolean | null;
    };
  };
};

export type GetActiveRegionStatesQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;

export type GetActiveRegionStatesQuery = {
  __typename: 'Query';
  activeRegionState: Array<string>;
};

export type GetTenantByKeyQueryVariables = Exact<{
  key: Scalars['Key']['input'];
}>;

export type GetTenantByKeyQuery = {
  __typename: 'Query';
  tenantByKey: {
    __typename: 'Tenant';
    id: string;
    key: string;
    name: string;
    logoUrl: string | null;
  } | null;
};

export type CustomerAdminSignUpMutationVariables = Exact<{
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  agencyId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  coords: PointInput;
  customerName: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  referral?: InputMaybe<ReferralEnum>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
}>;

export type CustomerAdminSignUpMutation = {
  __typename: 'Mutation';
  customerSignup: {
    __typename: 'CustomerSignupPayload';
    success: boolean;
    customer: { __typename: 'Customer'; id: string } | null;
  };
};

export type CheckDuplicateCustomerNameQueryVariables = Exact<{
  clientName: Scalars['String']['input'];
}>;

export type CheckDuplicateCustomerNameQuery = {
  __typename: 'Query';
  customerNameDuplicate: boolean;
};

export type CheckExistingEmailQueryVariables = Exact<{
  email: Scalars['Email']['input'];
}>;

export type CheckExistingEmailQuery = {
  __typename: 'Query';
  existingUser: boolean;
};

export type UserResendTokenMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserResendTokenMutation = {
  __typename: 'Mutation';
  userResendToken: void | null;
};
