import { NotificationStatusEnum, NotificationTypeEnum } from '@/types/graphql';

export interface NotificationType {
  message: string;
  link: string;
  notificationType: NotificationTypeEnum;
  publishAt: string;
  title: string;
  id?: number;
  status?: NotificationStatusEnum;
}

export const HEADER_FIELDS = [
  {
    name: 'TYPE',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    name: 'DETAILS',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
      width: '30%',
    },
  },
  {
    name: 'PUBLISH DATE',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  {
    name: 'LINK',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
      width: '20%',
    },
  },
  {
    name: 'STATUS',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  {
    name: 'ACTION',
    style: {
      color: '#262626',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
];
