import Badge from '@/components/Badge';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { GAEvent } from '@/constants/gaevents';
import Icon from '@/elements/Icon';
import { TableCell, TableRow } from '@/elements/Table';
import {
  useDeleteNotificationMutation,
  useGetNotificationsQuery,
} from '@/graphql';
import { jobColors } from '@/styles/colors';
import useAnalytics from '@/util/analytics';
import { formatISO } from '@/util/date';
import useModal from '@area2k/use-modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CreateNotificationModal from './CreateNotificationModal';
import { IconContainer, TableBold, TableDesc, TableLink } from './styles';
import { HEADER_FIELDS, NotificationType } from './constants';
import { NotificationStatusEnum } from '@/types/graphql';

const NotificationHub = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [deleteNotificationMutation] = useDeleteNotificationMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const query = useGetNotificationsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      perPage: itemsPerPage,
      page: currentPage,
    },
  });

  const items =
    !query.loading && query.data ? query.data.notifications.items : [];

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
  const handleNumberItemsChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  const getStatusChip = (status: NotificationStatusEnum) => {
    switch (status) {
      case NotificationStatusEnum.PENDING:
        return <Badge label="Pending" status="neutral" />;
      case NotificationStatusEnum.FAILED:
        return <Badge label="Failed" status="danger" />;
      case NotificationStatusEnum.PUBLISHED:
        return <Badge label="Published" status="successGreen" />;
    }
  };

  const { logEvent } = useAnalytics();

  const notificationType = (type) => {
    switch (type) {
      case 'INFORMATION':
        return 'Information';
      case 'RELEASE_NOTES':
        return 'Release Notes';
      case 'SYSTEM_UPDATE':
        return 'System Update';
      default:
        return '';
    }
  };

  const updateList = async () => {
    await query.refetch();
  };

  const [showCreateModal, hideCreateModal] = useModal(
    (notification) => {
      return (
        <CreateNotificationModal
          hideModal={hideCreateModal}
          updateList={updateList}
          notificationToEdit={notification}
        />
      );
    },
    [updateList],
  );

  const editNotification = (notification: NotificationType) => {
    showCreateModal(notification);
  };

  const deleteNotification = async (notification: NotificationType) => {
    deleteNotificationMutation({
      variables: {
        id: notification.id,
      },
      onCompleted: () => {
        updateList();
        logEvent(GAEvent.ReleaseNoteDeleted);
      },
    });
  };

  const TableBody = ({ items }) => {
    return (
      <tbody>
        {items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell style={{ paddingLeft: 10 }}>
                  <TableBold>
                    {notificationType(item.notificationType)}
                  </TableBold>
                </TableCell>
                <TableCell style={{ paddingLeft: 10 }}>
                  <TableBold>{item.title}</TableBold>
                  <TableDesc>{item.message}</TableDesc>
                </TableCell>
                <TableCell style={{ paddingLeft: 10 }}>
                  <TableBold>
                    {formatISO(item.publishAt, 'MM/dd/yyyy')}
                  </TableBold>
                  <TableBold>
                    {formatISO(item.publishAt, 'hh:mm a', 'America/New_York')}
                  </TableBold>
                </TableCell>
                <TableCell style={{ paddingLeft: 10 }}>
                  <TableLink href={item.link}>{item.link}</TableLink>
                </TableCell>
                <TableCell style={{ paddingLeft: 10 }}>
                  {getStatusChip(item.status)}
                </TableCell>
                <TableCell style={{ paddingLeft: 10 }}>
                  <IconContainer>
                    <CreateOutlinedIcon
                      onClick={() => {
                        editNotification(item);
                      }}
                    />
                    <Icon
                      icon={faTrashAlt}
                      size={'1x'}
                      onClick={() => deleteNotification(item)}
                    />
                  </IconContainer>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
        {query.loading && (
          <TableRow>
            <TableCell align="center" colSpan={100}>
              <LoadingState overlayColor="dark" />
            </TableCell>
          </TableRow>
        )}
      </tbody>
    );
  };

  return (
    <Page
      headerPadding={!!phoneOnly}
      noPadding={!!phoneOnly}
      primaryAction={{
        a11yLabel: 'Create a New Notification',
        label: 'New Entry',
        id: 'new-notification-btn',
        onAction: () => {
          showCreateModal();
        },
      }}
      size={phoneOnly ? 'full' : 'lg'}
      title="Notification Hub"
    >
      <Card>
        <Table>
          <TableHeader
            fields={HEADER_FIELDS}
            headerBgColor={jobColors.headerLight}
          />
          {items.length ? <TableBody items={items} /> : null}
        </Table>
      </Card>
      <Paginator
        currentPage={currentPage}
        handleNumberItemsChange={handleNumberItemsChange}
        handlePageChange={handlePageChange}
        itemsLength={query.data?.notifications.pageInfo.totalItems}
        itemsPerPage={itemsPerPage}
      />
    </Page>
  );
};

export default NotificationHub;
